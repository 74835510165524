$redLogoColor: rgb(183, 8, 8);

.navigation {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  box-shadow: 0px 1px 10px #999;
  z-index: 999;
}
.nav-info-bar {
  width: 100%;
  height: 30px;
  border-bottom: 1.0px solid #707070;
}
.sm-icon {
  width: 20px;
  height: 20px;
  float: right;
  margin-top: -1px;
}
.facebook-icon {
  margin-right: 20px;
}

.linked-in-icon {
  margin-right: 5px;
}

.nav-link-container {
  width: 100%;
  height: 120px;
}

.contact-icon {

  float: right;
  margin-right: 30px;

  label {
    font-size: 13px;
    margin-left: 4px;
    color: #302F2F;
    line-height: 30px;
    margin-bottom: 0;
    font-family: PoppinsMedium, sans-serif, serif;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.verticallyCentered {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fa-bars {
  display: none;
  color: rgb(183, 8, 8);
  font-size: 2rem;
}

.navigationItem {
  color: black;
  font-family: PoppinsSemiBold, serif, sans-serif;
  font-size: 19px;
  padding: 0 30px;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  text-transform: uppercase;

  &:hover {
    color: rgb(183, 8, 8);
    cursor: pointer;
  }

  img {
    height: 100%;
    width: auto;
  }
}

div.nav-tag {
  width: auto;
  display: inline-block;
}

.nav-tag-mobile-only {
  display: none;
}

.subNavigationItem {
  color: black;
  font-family: Poppins, serif, sans-serif;
  font-size: 16px;
  width: 100%;
  //text-align: center;
  display: inline-block;
  text-indent: 10px;
  line-height: 40px;
  text-transform: uppercase;

  &:hover {
    color: rgb(183, 8, 8);
    cursor: pointer;
  }

}

.navigation-free-inspection-button {
  height: 50px;
  width: 270px;
  background-color: rgb(183, 8, 8);
  color: white;
  font: 20px PoppinsSemiBold, serif, sans-serif;
  text-transform: uppercase;
  border-radius: 12px;
  margin-left: 20px;

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}
.navigation-logo-container {
  height: 80px;
  margin-left: 20px;
  display: inline-block;
}
.navigation-items-container {
  height: 40px;
  margin-top: 40px;
  position: relative;
  margin-right: 20px;
  display: inline-block;
  float: right;
}

.navigation-links-container  {
  display: inline-block;
}

.navigation-items-container-mobile {
  display: none;
}

.nav-tag.active {
  color: $redLogoColor;
}

.navigationLogo {
  width: auto;
  height: 100%;
  margin-top: 20px;
}

// Hacking the Navigation

// About
.about-expanded {
  //width:
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 170px;
  left: 25px;
  top: calc(100% + 5px);
  height: 120px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.about-expanded.showSub {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}
#aboutTag:hover .about-expanded {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

// Work
.work-expanded {
  position: absolute;
  width: 130px;
  left: 195px;
  top: calc(100% + 5px);
  height: 120px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
.work-expanded.showSub {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}
#workTag:hover .work-expanded {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

// Service Areas
#serviceAreasTag {
  width: 100%;
}

.service-areas-expanded {
  position: absolute;
  width: 200px;
  //box-shadow: 0 0 2px #00000076;
  left: 170px;
  top: 125px;
  height: 160px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
.service-areas-expanded.showSub {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

#serviceAreasTag:hover {
  color: rgb(183, 8, 8);
}

#serviceAreasTag:hover .service-areas-expanded {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

// Services

#servicesTag {
  width: 100%;
}

.services-expanded {
  position: absolute;
  width: 200px;
  //box-shadow: 0 0 2px #00000076;
  left: 130px;
  top: 45px;
  height: 160px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
.services-expanded.showSub {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}

#servicesTag:hover {
  color: rgb(183, 8, 8);
}

#servicesTag:hover .services-expanded {
  visibility: visible;
  -webkit-transition: all 1.3s ease-in-out;
  -moz-transition: all 1.3s ease-in-out;
  -o-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}


/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .fa-bars {
    display: none;
  }

  .about-expanded {
    //width:
    position: absolute;
    left: 0;
    top: calc(100% - 5px);
  }

  .work-expanded {
    left: 125px;
    top: calc(100% - 6px);
  }

  .navigationItem {
    color: black;
    font-family: PoppinsSemiBold, serif, sans-serif;
    font-size: 19px;
    padding: 0 8px;
    text-align: center;
    display: inline-block;
    line-height: 30px;

    img {
      padding: 0 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navigation-free-inspection-button {

    height: 40px;
    width: 200px;
    font: 14px PoppinsSemiBold, serif, sans-serif;
    margin-left: 10px;

    &:focus {
      border: none;
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media all and (max-width: 1150px) {

    .navigation-free-inspection-button {
      position: fixed;
      top: 5px;
      left: 10px;
      box-shadow: 0 0 10px #00000076;
    }

    .navigationItem {
      font-size: 18px;
      padding: 0 6px;

      &:hover {
        cursor: pointer;
      }

    }

    .about-expanded {
      top: calc(100% - 10px);
    }

    .work-expanded {
      top: calc(100% - 10px);
    }
  }
}



@media all and (max-width: 849px) {

  .navigation-logo-container {
    margin-left: 0;
  }

  .navigation-free-inspection-button {
    display: none;
  }


  .fa-bars {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .navigation-links-container {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    margin-top: 50px;
    max-height: 0;
    //-moz-transition-duration: 1s;
    //-webkit-transition-duration: 1s;
    //-o-transition-duration: 1s;
    //transition-duration: 1s;
    //-moz-transition-timing-function: ease-in;
    //-webkit-transition-timing-function: ease-in;
    //-o-transition-timing-function: ease-in;
    //transition-timing-function: ease-in;

    div.nav-tag {
      display: none;
    }

    .nav-tag-mobile-only {
      display: inline-block;

      .subNavigationItem {
        color: white !important;
        border-bottom: 1px solid white;
        width: 100%;
        display: inline-block;
        line-height: 40px;
        text-transform: uppercase;
        font-family: PoppinsSemiBold, serif, sans-serif;
        font-size: 19px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

      }
    }

    .navigationItem {
      color: white !important;
      border-bottom: 1px solid white;
      width: 100%;
      display: inline-block;
      line-height: 40px;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }
    }

    &.is-expanded {
      width: 100%;
      max-height: 500px; /* approximate max height */
      background-color: rgb(183, 8, 8);
      color: white;
      overflow: visible;
      //
      //-moz-transition-duration: 1s;
      //-webkit-transition-duration: 1s;
      //-o-transition-duration: 1s;
      //transition-duration: 1s;
      //-moz-transition-timing-function: ease-in;
      //-webkit-transition-timing-function: ease-in;
      //-o-transition-timing-function: ease-in;
      //transition-timing-function: ease-in;

      img {
        display: none;
      }
    }

  }
}

@media all and (max-width: 499px) {

  .nav-info-bar {
    overflow: hidden;

    .sm-icon {
      display: none;
    }
  }

  .navigationItem {
    .navigationLogo {
      padding: 0 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navigation-logo-container {
    margin-left: 0;
    img {
      margin-top: 20px;
      width: 80px;

      height: auto;
    }
  }

}