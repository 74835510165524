.inspection-page-container { }

#estimateFormContainer {
    width: 70%;
    margin: 0 auto;
    height: calc(100% - 125px);
}

.estimateControlLabel {
    font-family: Bebas, serif;
    font-size: 15px;
    margin-bottom: 0 !important;
    word-spacing: 5px;
    width: 100%;
}

.estimateFooter {
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;

}

label.container {
    font-size: 15px;
}

.estimateFooterMainText {
    float: right;
    font-family: FranklinGothicHeavy, serif;
    font-size: 64px;
    color: rgb(182,8,7);
    text-transform: uppercase;
    margin-right: 30px;
    word-spacing: 10px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: right;
    width: 100%;

}

.estimateFooterSmallText {
    float: right;
    font-family: Bebas, serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
    margin-right: 30px;
    word-spacing: 5px;
    margin-top: -15px;
    text-align: right;
    margin-bottom: 5px;
}

.estimateImportant {
    color: rgb(182,8,7);
    font-size: 24px;
    margin-left: 5px;
    margin-right: 5px;
}

.formGroupText {
    width: 80%;
    display: inline-block;
    margin-left: 10%;
}

.formGroupCustom {
    width: 80%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 0 !important;
}

.descriptionTextArea {
    height: 125px;
}

.checkbox label {
    font-family: AgencyFB, serif;
    font-size: 18px;
}

.submitButton {
    background-color: rgb(182, 8, 7);
    color: white;
    font-family: Bebas, serif;
    font-size: 12px;
    width: 200px;
    margin-top: 20px;
    margin-left: calc(50% - 100px);
    word-spacing: 5px;
}

.formGroupInput {
    width: 95%;
}

/* Submitted Estimate */
.submittedEstimateContainer {
    width: 60%;
    margin: 20px auto;
    display: block;
    padding-bottom: 50px;
}

.submittedHeader {
    font-family: Bebas, serif;
    font-size: 24px;
    color: rgb(182, 8, 7);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.submittedInfo {
    font-size: 20px;
    text-align: center;
    color: black;
}

.hideEstimate {
    display: none;
}

.showEstimate {
    display: block;
}

.thankYouMessage {
    width: 90%;
    margin: 50px auto 50px;
    padding-top: 25px;
    text-align: center;
    font: 30px PoppinsMedium, serif, sans-serif;
    color: rgb(183, 8, 8);
}

.thankYouRepImage {
    width: 50%;
    display: block;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-width: 600px;
    min-width: 250px;
    height: auto;

    margin: 20px auto;
}

.free-estimate-recapatcha-container {
    margin: 5px 10px 20px 10px;
    width: 50%;
}


@media screen and (max-width: 1000px){

    .freeEstimate {
       height: auto;
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    #estimateFormContainer {
        width: 80%;
        margin: 0 auto;
    }

    .estimateControlLabel {
        font-family: Bebas, serif;
        font-size: 18px;
        word-spacing: 5px;
        width: 100%;
    }

    .estimateFooter {
        display: none;

    }

    .formGroupText {
        width: 100%;
        display: inline-block;
    }

    .formGroupCustom {
        width: 80%;
        display: inline-block;
    }

    .descriptionTextArea {
        height: 125px;
    }

    .checkbox label {
        font-family: AgencyFB, serif;
        font-size: 18px;
    }

    .submitButton {
        background-color: rgb(182, 8, 7);
        color: white;
        font-family: Bebas, serif;
        font-size: 12px;
        width: 200px;
        margin-top: 20px;
        margin-left: calc(50% - 100px);
        word-spacing: 5px;
    }

    .formGroupInput {
        width: 95%
    }

    /* Submitted Estimate */
    .submittedEstimateContainer {
        width: 60%;
        margin: 20px auto;
        display: block;
        padding-bottom: 50px;
    }

    .submittedHeader {
        font-family: Bebas, serif;
        font-size: 24px;
        color: rgb(182, 8, 7);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .submittedInfo {
        font-size: 20px;
        text-align: center;
        color: black;
    }

    .hideEstimate {
        display: none;
    }

    .showEstimate {
        display: block;
    }

    .thankYouMessage {
        width: 90%;
        margin: 50px auto 50px;
        padding-top: 25px;
        text-align: center;
        font: 22px PoppinsMedium, serif, sans-serif;
        color: rgb(183, 8, 8);
    }

}