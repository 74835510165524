$redLogoColor: rgb(182, 8, 7);

.work-banner {
  background: transparent url('../../resources/images/misc/background_banner_red.png') center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px;

  img {
    width: 100%;
    height: 100%;
  }
  .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
  }
}

.work-banner-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000;
  }
  .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000;
  }
}

.gallery-container {

  width: 100%;
  margin: 100px auto;

  .main-text {
    font: 62px PoppinsSemiBold, serif, sans-serif;
    color: rgb(183, 8, 8);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
  }

  .small-text {
    font: 28px Poppins, serif, sans-serif;
    text-align: center;
    margin: 0 auto 30px auto;
  }

  .gallery-sort-container {
    width: 80%;
    margin: 20px auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      font: 18px PoppinsSemiBold, sans-serif, serif;
      text-transform: uppercase;

      &:hover {
        color: rgb(183, 8, 8);
      }

    }
  }

  .gallery-images-container {
    width: 90%;
    margin: 0 auto 20px auto;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-evenly;


    .work-display-image-container {
      width: 27%;

      display: flex;
      margin-bottom: 30px;
      background-color: white;
      box-shadow: 0 0 10px #00000052;
      border: 10px solid #FFFFFF;

      img {
        width: 100%;
        height: 100%;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .gallery-show-photos-button {

    background-color: #b70808;
    color: #fff;
    text-transform: uppercase;
    border-radius: 12px;
    font: 24px PoppinsSemiBold,serif,sans-serif;
    height: 52px;
    width: 200px;
    max-width: 90vw;
    margin-left: calc(50% - 100px);


    &:focus {
      border: none;
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background-color: #B70808;
      color: white;
      border: 2px solid #302F2F;
    }
  }
}

.gray-banner {
  width: 100%;
  height: 140px;
  background-color: #E6E6E6;
}

.work-process-container {

  .work-process-banner {
    height: 250px;
    width: 100%;
    border-bottom: 2px solid #E6E6E6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .banner-icon {
      position: absolute;
      top: 0;
    }

    .main-text {
      font: 48px PoppinsSemiBold, serif, sans-serif;
      color: rgb(183, 8, 8);
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 0;
    }

    .small-text {
      font: 25px Poppins, serif, sans-serif;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .work-process-content {
    width: 80%;
    margin: 50px auto;

    .work-item-container {
      width: 100%;
      display: flex;
      margin-top: 50px;

      .work-item-icon-container {
        width: 150px;

        img {
          width: 140px;
          height: auto;
        }
      }

      .work-item-info-container {
        flex-grow: 1;

        .step-header {
          width: 100%;
          font: 48px PoppinsSemiBold, serif, sans-serif;
          color: rgb(183, 8, 8);
          border-bottom: 1px solid red;
          padding-left: 10px;

        }

        .step-info {
          width: 100%;
          font: 20px Poppins, serif, sans-serif;
          padding-left: 10px;
        }

        .step-info-bold {

          font: 20px PoppinsSemiBold, serif, sans-serif;

        }
      }
    }
  }
}

.view-work-modal-content {
  height: 625px;
  width: 900px;
  margin-top: calc(50vh - 313px);
  margin-left: calc(50vw - 450px);
  background-color: white;
  border-radius: 23px;
  position: relative;

  .view-work-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 95%;
      max-height: 90%;
      border-radius: 23px;
    }

  }

  .view-work-icon-container {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .work-banner-text-container {
    .banner-text-large {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }
  }

  .gallery-container {
    margin: 50px auto;
  }

  .view-work-modal-content {
    height: 500px;
    width: 750px;
    margin-top: calc(50vh - 313px);
    margin-left: calc(50vw - 375px);
  }
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .work-banner-text-container {
    .banner-text-large {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }
  }

  .gallery-container {
    margin: 50px auto;
  }

  .view-work-modal-content {
    height: 500px;
    width: 750px;
    margin-top: calc(50vh - 313px);
    margin-left: calc(50vw - 375px);
  }
}

/****************** TABLETS ****************/
@media all and (max-width: 849px) and (min-width: 500px) {

  .work-banner-text-container {
    .banner-text-large {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }
  }

  .gallery-container {
    margin: 50px auto;
  }

  .view-work-modal-content {
    height: 500px;
    width: 90%;
    margin-top: calc(50vh - 200px);
    margin-left: calc(50vw - 45%);
  }

  .gallery-container {
    width: 100%;
    margin: 30px auto;

    .main-text {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }

    .small-text {
      text-align: center;
      margin: 0 auto 20px auto;
    }

    .gallery-images-container {
      width: 95%;
      margin: 0 auto 20px auto;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      justify-content: space-evenly;


      .work-display-image-container {
        width: 40%;
        display: flex;
        margin-bottom: 30px;
        background-color: white;
        box-shadow: 0 0 10px #00000052;
        border: 10px solid #FFFFFF;
      }
    }
  }

  .work-process-container {

    .work-process-banner {

      .banner-icon {
        position: absolute;
        top: 0;
      }

      .main-text {
        font: 36px PoppinsSemiBold, serif, sans-serif;
      }

      .small-text {
        font: 22px Poppins, serif, sans-serif;
      }
    }

    .work-process-content {
      .work-item-container {
        .work-item-info-container {
          .step-header {
            font: 42px PoppinsSemiBold, serif, sans-serif;
          }
        }
      }
    }
  }
}

@media all and (max-width: 499px) {

  .work-banner-text-container {
    .banner-text-large {
      font: 36px PoppinsSemiBold, serif, sans-serif;
    }
  }

  .gallery-container {
    margin: 50px auto;
  }

  .view-work-modal-content {
    height: 500px;
    width: 90%;
    margin-top: calc(50vh - 313px);
    margin-left: calc(50vw - 45%);
  }

  .gallery-container {
    width: 100%;
    margin: 30px auto;

    .main-text {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }

    .small-text {
      text-align: center;
      margin: 0 auto 20px auto;
    }

    .gallery-images-container {
      width: 95%;
      margin: 10px auto 20px auto;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      justify-content: space-evenly;


      .work-display-image-container {
        width: 90%;
        display: flex;
        margin-bottom: 30px;
        background-color: white;
        box-shadow: 0 0 10px #00000052;
        border: 10px solid #FFFFFF;
      }
    }
  }

  .work-process-container {

    .work-process-banner {

      .banner-icon {
        position: absolute;
        top: 0;
      }

      .main-text {
        font: 24px PoppinsSemiBold, serif, sans-serif;
      }

      .small-text {
        font: 18px Poppins, serif, sans-serif;
      }
    }

    .work-process-content {
      width: 90%;
      margin: 25px auto;
      .work-item-container {
        flex-direction: column;
        .work-item-info-container {
          .step-header {
            font: 42px PoppinsSemiBold, serif, sans-serif;
          }

          .step-container {
            width: 100%;
            height: 50px;
            background-color: red;
          }


        }
      }
    }
  }
}

/** Edge Doesn't Support Space Evently **/
@supports (-ms-accelerator:true) or (-ms-ime-align:auto)  {
  .gallery-container {
    .gallery-images-container {
      justify-content: space-around;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .gallery-container {
    .gallery-images-container {
      justify-content: space-around;
    }
  }

  .work-banner-text-container {
    .banner-text-large {
      font: 36px PoppinsSemiBold, serif, sans-serif;
      max-width: 96vw;
    }
  }
}

@media all and (max-width: 300px) {

  .work-banner-text-container {
    .banner-text-large {
      font: 36px PoppinsSemiBold, serif, sans-serif;
      max-width: 96vw;
    }
  }
}