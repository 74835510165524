.image-input-container {
  width: 100%;
  border-bottom: 3px solid #707070;
  display: flex;
  height: 60px;
  margin-bottom: 25px;

  .places-search-container {
    width: 100%;
  }

  .suggestion-item--active {
    width: 100%;
    min-height: 30px;
    text-indent: 2px;
    font: 16px Poppins, serif, sans-serif;
    background-color: #6a6a6a;
    text-overflow: ellipsis;
  }

  .suggestion-item {
    width: 100%;
    min-height: 30px;
    text-indent: 2px;
    font: 16px Poppins, serif, sans-serif;
    text-overflow: ellipsis;
  }

  .autocomplete-dropdown-container {
    width: 100%;
    z-index: 9999;
    background-color: lightgreen;
    position: relative;
    box-shadow:
            0 1px 1px rgba(0, 0, 0, 0.1),
            0 2px 2px rgba(0, 0, 0, 0.1),
            0 4px 4px rgba(0, 0, 0, 0.1),
            0 8px 8px rgba(0, 0, 0, 0.1),
            0 16px 16px rgba(0, 0, 0, 0.1);
  }

  &.error {
    border: 2px solid red;
  }

  .phone {
    width: 20px;
    height: 18px;
    margin-top: 24px;
    margin-left: 5px;
  }

  .email {
    width: 20px;
    height: 15px;
    margin-top: 26px;
    margin-left: 5px;
  }

  .firstName, .lastName, .fullName {
    width: 20px;
    height: 25px;
    margin-top: 20px;
    margin-left: 5px;
  }

  .code {
    width: 25px;
    margin-top: 21px;
    height: 25px;
  }

  .address, .city, .state, .zipCode {
    width: 20px;
    height: 27px;
    margin-top: 20px;
    margin-left: 5px;
  }

  input {
    border: none;
    height: 50px;
    margin-top: 6px;
    width: 100%;
    text-indent: 10px;
    font: 18px Poppins, serif, sans-serif;
  }

  input:focus {
    outline: none;
  }
}