$redLogoColor: rgb(183, 8, 8);
$headerFont: 3em OswaldBold, serif, sans-sefif;
$sectionFont: 1.3em Roboto, serif, sans-serif;
$subheaderFont: 2.5em OswaldBold, serif, sans-serif;

html {
  overflow: -moz-scrollbars-none;
}

.covid-19-message {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(1.0, 1.0, 1.0, 0.8);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  p {
    color: white;
    padding: 20px 0;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font: 20px Poppins, serif, sans-serif;
  }

  button {
    position: absolute;
    right: 30px;
    top: calc(50% - 30px);
    height: 60px;
    border: none;
    background: none;
    color: white;
    font: 36px PoppinsSemiBold, sans-serif, serif;

    &:hover {
      color: rgb(183, 8, 8);
    }

    &:focus {
      border: none;
      outline: none;
    }

  }



}

.home {

  .video-container {
    width: 100vw;
  }

  .fullscreen-video {
    width: 100%;
  }

}

.intro {
  //background: transparent url('../../resources/images/home/background.png') center center no-repeat padding-box;
  //background: transparent url('../../resources/images/Homepage.jpg') center center no-repeat padding-box;
  opacity: 1;
  height: 800px;
  //margin-top: -150px;
  width: 100%;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: none;
}

.intro-basics {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 165px);
  left: 250px;

  .intro-header {
    font: 22px PoppinsBold, sans-serif, serif;
    color: white;
    text-shadow: 0 0 10px #000000;
    text-transform: uppercase;
  }

  .intro-description {
    font: 48px PoppinsSemiBold, sans-serif, serif;
    color: white;
    text-shadow: 0 0 10px #000000;
  }

  .free-inspection-button-container {
    width: 92%;
    height: 65px;
    background-color: white;
    box-shadow: 0 0 10px #00000076;
    border-radius: 9px;
    border: 2px solid rgba(183, 8, 8, 0);


    &:hover {
      cursor: pointer;
      border: 2px solid $redLogoColor;
    }

    .home-inspection-label {
      text-transform: uppercase;
      color: $redLogoColor;
      font: 22px PoppinsSemiBold, Serif, sans-serif;
      display: inline-block;
      line-height: 63px;
      margin-left: 15px;
    }

    .home-inspection-img {
      float: right;
      margin-right: 2px;
      margin-top: 1px;
      display: inline-block;
    }
  }
}

.whatWeDo {

  .content {
    width: 80%;
    max-width: 1200px;
    margin: 100px auto;

    .smallText {
      font: 22px PoppinsBold, serif, sans-serif;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .largeText {
      font: 44px PoppinsSemiBold, serif, sans-serif;
      color: $redLogoColor;
    }

    .services-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .single-service-container {
        max-width: 350px;
        height: 100%;
        flex-grow: 1;
        position: relative;

        .main-service-image {
          box-shadow: 0 0 5px #00000076;
        }

        img {
          width: 100%;
          height: auto;
        }

        .service-mini-image {
          position: absolute;
          width: 80px;
          height: 80px;
          right: 30px;
          top: 180px;
        }

        .service-header {
          font: 24px PoppinsSemiBold, serif, sans-serif;
          color: $redLogoColor;
          margin-top: 10px;
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        .service-description {
          font: 18px Poppins, serif, sans-serif;
          color: #414040;
          margin-bottom: 10px;
        }

        .learn-more-link {
          font: 14px PoppinsSemiBold, serif, sans-serif;
          color: $redLogoColor;
          text-transform: uppercase;
        }
      }
    }
  }
}

/***
PROCESS CONTAINER
 */
.home-process {
  width: 100%;
  height: 600px;
  background-color: #E6E6E6;
}

.process-image-container {

}

.process-info-container {

}

.empty-space {
  width: 100%;
  height: 150px;
  background-color: lightcoral;
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .whatWeDo {

    .content {
      width: 95%;
      max-width: 1200px;
      margin: 50px auto;

      .largeText {
        font: 56px PoppinsSemiBold, serif, sans-serif;
        color: $redLogoColor;
      }
    }
  }

  @media all and (max-width: 1150px) {

    .intro-basics {
      width: 600px;
      left: 100px;
      top: calc(50% - 255px);

      .intro-description {
        font-size: 36px;
      }
    }

    .whatWeDo {
      .content {
        .services-container {
          .single-service-container {
            max-width: initial;
            width: 30%;
            height: 100%;
            flex-grow: initial;
            position: relative;

            .service-mini-image {
              display: none;
            }

            .service-header {
              font-size: 24px;
              margin-top: 5px;
              margin-bottom: 0;
            }

            .service-description {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

/****************** TABLETS ****************/
@media all and (max-width: 849px) {

  .covid-19-message {

    p {
      font: 16px Poppins, serif, sans-serif;
    }

    button {
      right: 15px;
      top: calc(50% - 25px);
      font: 28px PoppinsSemiBold, sans-serif, serif;
    }
  }

  .intro {
    background: transparent url('../../resources/images/home/background.png') center center repeat padding-box;
    height: 700px;
  }

  .intro-basics {
    width: 90%;
    //width: 360px;
    position: absolute;
    top: calc(50% - 300px);
    left: 5%;

    .intro-description {
      font: 28px PoppinsSemiBold, sans-serif, serif;
    }

    .free-inspection-button-container {
      width: 350px;
      height: 45px;

      .home-inspection-label {
        font: 18px PoppinsSemiBold, Serif, sans-serif;
        display: inline-block;
        line-height: 43px;
        margin-left: 5px;
      }

      .home-inspection-img {
        width: 39px;
        height: 39px;
      }
    }
  }

  .whatWeDo {

    .content {
      width: 90%;
      margin: 30px auto 20px auto;

      .smallText {
        text-align: center;
        margin-bottom: 5px;
      }

      .largeText {
        font: 42px PoppinsSemiBold, serif, sans-serif;
        color: $redLogoColor;
        text-align: center;
      }

      .services-container {
        width: 100%;
        display: block;
        justify-content: space-between;

        .single-service-container {
          max-width: inherit;
          width: 100%;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid rgb(183, 8, 8);
          margin-bottom: 10px;
          align-items: center;

          .service-mini-image {
            display: none;
          }

          img {
            max-width: 450px;
          }

          .service-header {
            font: 24px PoppinsSemiBold, serif, sans-serif;
            color: $redLogoColor;
            margin-top: 5px;
          }

          .service-description {
            font: 18px Poppins, serif, sans-serif;
            color: #414040;
            margin-bottom: 5px;
            text-align: center;
          }

          .learn-more-link {
            font: 14px PoppinsSemiBold, serif, sans-serif;
            color: $redLogoColor;
            text-transform: uppercase;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 550px) {

  .intro-basics {
    top: 150px;
    transform: translate(1%, 5%);

    .intro-header {
      font-size: 13px;
      margin-bottom: 5px;
    }

    .intro-description {
      font-size: 16px;
    }


    .free-inspection-button-container {
      width: 300px;

      .home-inspection-label {
        font-size: 15px;
      }
    }
  }
}

