.modalContainer {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
}

.hiddenModal {
    display: none;
}

.modalContent {
    width: 500px;
    margin-top: 200px;
    margin-left: calc(50vw - 250px);
    background-color: white;
    border-radius: 23px;
}

.modalHeader {
    width: 90%;
    margin: auto auto 30px;
    padding-top: 25px;
    text-align: center;
    font: 24px PoppinsMedium, serif, sans-serif;
    color: rgb(183, 8, 8);
}

.errorItem {
    color: black;
    font: 28px Poppins, serif;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
}


.errorOkButton {
    background-color: rgb(182, 8, 7);
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    font: 20px Poppins, serif;
    width: 150px;
    margin-left: calc(50% - 75px);
    height: 50px;
    word-spacing: 5px;
    border-radius: 12px;


}

.errorOkButton:hover {
    cursor: pointer;
}

.errorOkButton:focus {
    border: none;
    outline: none;
}

.modalContentWorkMobile {
    width: 90%;
    margin-top: 50px;
    margin-left:  5%;
    background-color: white;
}



.workHeaderDisplay {
    width: 100%;
    padding-top: 25px;
    text-align: center;
    word-spacing: 5px;
    font-family: Bebas, serif;
    font-size: 24px;
    color: rgb(182, 8, 7);
}

.workItemHeader {
    text-align: center;
    font-family: Bebas, serif;
    font-size: 20px;
    margin-bottom: 0;
}

.workItemModalDescription {
    width: 90%;
    margin: 5px auto;
    text-align: center;

}

.workMobileImage {
    margin-left: calc(50% - 75px);
    width: 150px;
    height: auto;
}

.workMobileCloseButton {
    background-color: rgb(182, 8, 7);
    color: white;
    margin-top: 10px;
    margin-bottom: 40px;
    font-family: Bebas, serif;
    width: 150px;
    margin-left: calc(50% - 75px);
    height: 50px;
    word-spacing: 5px;
    font-size: 20px;
}

@media all and (max-width: 600px) {

    .modalContent {
        width: 90%;
        margin-left: 5%;
    }

    .modalHeader {
        width: 90%;
        margin: auto auto 20px;
        padding-top: 15px;
        text-align: center;
        font: 20px PoppinsMedium, serif, sans-serif;
        color: rgb(183, 8, 8);
    }

    .program-schedule-time-container {
        width: 95%;
        margin: 10px auto;
    }

}

