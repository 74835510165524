.work-step-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  //align-items: center;
  margin-bottom: 20px;

  img {
    height: 20px;
    width: auto;
  }

  p {
    font: 16px Poppins, sans-serif, serif;
    padding-left: 10px;
    margin-bottom: 0;
    //padding-top: 5px;
    padding-bottom: 5px;
  }

}