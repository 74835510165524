.stormDamage {
    margin-top: 50px;
    position: relative;
    min-height: calc(100vh - 280px);

}

.stormDamageContent {
    width: 100%;

}

.stormDamageInfo {
    width: 65%;
    height: 100%;
    float: left;
    margin-top: 3%;
}

.stormDamageInfo img {
    width: 80%;
    height: auto;
    margin-left: 10%;
    max-width: 900px;
    -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0,0.5);
    -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
    padding: 10px;
    background: white;

    border: 1px solid #8a4419;
    /*border-style: inset;*/
}

.stormDamageInfo img:after {
    content: '';
    position: absolute;
    z-index: -1; /* hide shadow behind image */
    -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    width: 70%;
    left: 15%; /* one half of the remaining 30% */
    height: 100px;
    bottom: 0;
}



#stormDamageDescription {
    text-align: center;
    width: 80%;
    margin: 60px auto;
    font-size: 18px;
}

.stormDamageImportant {
    color: rgb(182, 8, 7);
    word-spacing: 5px;
    font-size: 20px;
}

.stormReport {
    width: 35%;
    height: 100%;
    float: right;
}

.estimateButton {
    width: 250px;
    height: 40px;
    background-color: rgb(182, 8,7);
    color: white;
    font-family: Bebas, serif;
    word-spacing: 5px;
    margin-top: 20px;
    margin-left: calc(50% - 125px);
    border-radius: 5px;
}

.estimateButton:hover {
    cursor: pointer;
}

.stormDamageFooter {
    width: 100%;
    height: 80px;
}

.stormDamageFooterText {
    float: right;
    font-family: FranklinGothicHeavy, serif;
    font-size: 60px;
    color: rgb(182,8,7);
    text-transform: uppercase;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 5px;
}

.damageLink {
    color: rgb(182, 8, 7);
    margin-right: 20px;
}

.damageLink:hover {
    color: rgb(182, 8, 7);
}

@media screen and (min-width: 601px) and (max-width: 1000px){

    .stormDamage {
        height: auto;
    }

    .stormDamageInfo {
        width: 100%;
        height: 100%;
    }

    .stormDamageInfo img {
        width: 70%;
        margin-left: 15%;
        height: auto;
        -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0,0.5);
        -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        padding: 10px;
        background: white;
        border: 1px solid #8a4419;
    }

    .stormReport {
        width: 100%;
    }

    #stormDamageDescription {
        margin: 30px auto;
    }

    .estimateButton {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .stormDamageFooter {
        position: inherit;
    }

    .stormDamageFooterText {
        font-size: 34px;
    }

}

@media screen and (max-width: 600px){

    .stormDamage {
        margin-top: 20px;
        height: auto;
    }

    .stormDamageContent {
        width: 100%;

    }

    .stormDamageInfo {
        width: 100%;
        height: 100%;
        float: none;
    }

    .stormDamageInfo img {
        width: 90%;
        height: auto;
        margin-left: 5%;
        -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0,0.5);
        -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        padding: 10px;
        background: white;
        border: 1px solid #8a4419;
    }

    .stormDamageInfo img:after {
        content: '';
        position: absolute;
        z-index: -1; /* hide shadow behind image */
        -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        width: 70%;
        left: 15%; /* one half of the remaining 30% */
        height: 100px;
        bottom: 0;
    }

    #stormDamageDescription {
        text-align: center;
        width: 90%;
        margin: 30px auto;
        font-size: 16px;
    }

    .stormDamageImportant {
        color: rgb(182, 8, 7);
        word-spacing: 5px;
        font-size: 20px;
    }

    .stormReport {
        width: 100%;
        float: none;
        padding-bottom: 20px;
    }

    .estimateButton {
        width: 250px;
        height: 40px;
        background-color: rgb(182, 8,7);
        color: white;
        font-family: Bebas, serif;
        word-spacing: 5px;
        margin-top: 20px;
        margin-left: calc(50% - 125px);
        border-radius: 5px;
    }

    .estimateButton:hover {
        cursor: pointer;
    }

    .stormDamageFooter {
       display: none;
    }

    .stormDamageFooterText {
        float: right;
        font-family: FranklinGothicHeavy, serif;
        font-size: 64px;
        color: rgb(182,8,7);
        text-transform: uppercase;
        margin-right: 10px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .damageLink {
        color: rgb(182, 8, 7);
        margin-right: 20px;
    }

    .damageLink:hover {
        color: rgb(182, 8, 7);
    }

}