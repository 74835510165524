.blog-container {
  padding-bottom: 20px;

  .blog-banner {
    background: transparent url('../../../resources/images/misc/background_banner_red.png') center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px;

    .blog-banner-text-container {
      position: absolute;
      top: 50%;
      width: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      .banner-text-small {
        text-transform: uppercase;
        color: white;
        font: 24px PoppinsBold, serif, sans-serif;
        text-align: center;
        margin-bottom: 0;
        text-shadow: 0 0 10px #000000;
      }

      .banner-text-large {
        color: white;
        font: 56px PoppinsSemiBold, serif, sans-serif;
        text-align: center;
        text-shadow: 0 0 10px #000000;
      }
    }
    .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px);
    }
  }
}

.blog-container { }
.blog-content {
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}


.blog-post-card {
  width: 275px;
  height: 400px;
  border-radius: 8px;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1),
  0 2px 2px rgba(0,0,0,0.1),
  0 4px 4px rgba(0,0,0,0.1),
  0 8px 8px rgba(0,0,0,0.1),
  0 16px 16px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;

  &:hover {
    background-color: #D3D3D3;
    cursor: pointer;

  }

  .title {

    font: 28px PoppinsMedium, serif, sans-serif;
    color: rgb(183, 8, 8);
    text-align: center;
    width: 80%;
  }
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .blog-post-card {
    width: 355px;
    height: 250px;

    &:hover {
      background-color: #D3D3D3;
      cursor: pointer;

    }

    .title {

      font: 28px PoppinsMedium, serif, sans-serif;
      color: rgb(183, 8, 8);
      text-align: center;
      width: 80%;
    }
  }

  .blog-container {
    .blog-banner {

      .blog-banner-text-container {

        .banner-text-large {
          font: 42px PoppinsSemiBold, serif, sans-serif;
        }
      }
    }
  }
}


/***************** TABLETS AND SMALLER **********/
@media all and (max-width: 849px) {
  .blog-container {
    .blog-banner {
      .blog-banner-text-container {
        width: 100%;
        .banner-text-small {
          font-size: 20px;
        }
        .banner-text-large {
          font-size: 34px;
        }
      }
    }
  }
}