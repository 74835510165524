@font-face {
  font-family: 'LatoBold';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Lato-Bold.eb953203.ttf);
}

@font-face {
  font-family: 'ReneBiederExtraBold';
  font-style: normal;
  font-weight: 100;
  src: url("/static/media/Rene Bieder  Milliard ExtraBold.cd89d671.otf");
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  x-height: 1.3;
  font-weight: 100;
  src: url(/static/media/Lato-Regular.3b9b9903.ttf);
}



@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/bebas.3ef73ff4.ttf);
}

@font-face {
  font-family: 'AgencyFB';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/AgencyFB.f9b9a4fc.ttf);
}

@font-face {
  font-family: 'FranklinGothicHeavy';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/FranklinGothicHeavy.59e78317.ttf);
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Oswald.e605f2f8.ttf);
}

@font-face {
  font-family: 'OswaldBold';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/OswaldBold.92837a20.ttf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Roboto.3e1af3ef.ttf);
}

@font-face {
  font-family: 'RobotoMedium';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/RobotoMedium.d0884059.ttf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Poppins-Regular.de2dd933.otf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsMedium';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Poppins-Medium.f88c443f.otf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsBlack';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Poppins-Black.d5c1d5b2.otf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsSemiBold';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Poppins-SemiBold.b0b3d360.otf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsBold';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Poppins-Bold.e47421f9.otf); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsLight';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Poppins-Light.02c5a7af.otf); /* IE9 Compat Modes */
}

.nameEnding {
  color: rgb(182, 8, 7);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pageHeader {
  font-family: Bebas, serif;
  font-size: 30px;
  color: rgb(182, 8, 7);
  text-align: center;
  word-spacing: 5px;
}

a:hover  {
  text-decoration: none !important;
}

.navigation {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  box-shadow: 0px 1px 10px #999;
  z-index: 999; }

.nav-info-bar {
  width: 100%;
  height: 30px;
  border-bottom: 1.0px solid #707070; }

.sm-icon {
  width: 20px;
  height: 20px;
  float: right;
  margin-top: -1px; }

.facebook-icon {
  margin-right: 20px; }

.linked-in-icon {
  margin-right: 5px; }

.nav-link-container {
  width: 100%;
  height: 120px; }

.contact-icon {
  float: right;
  margin-right: 30px; }
  .contact-icon label {
    font-size: 13px;
    margin-left: 4px;
    color: #302F2F;
    line-height: 30px;
    margin-bottom: 0;
    font-family: PoppinsMedium, sans-serif, serif; }
    .contact-icon label:hover {
      cursor: pointer;
      text-decoration: underline; }

.verticallyCentered {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.fa-bars {
  display: none;
  color: #b70808;
  font-size: 2rem; }

.navigationItem {
  color: black;
  font-family: PoppinsSemiBold, serif, sans-serif;
  font-size: 19px;
  padding: 0 30px;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  text-transform: uppercase; }
  .navigationItem:hover {
    color: #b70808;
    cursor: pointer; }
  .navigationItem img {
    height: 100%;
    width: auto; }

div.nav-tag {
  width: auto;
  display: inline-block; }

.nav-tag-mobile-only {
  display: none; }

.subNavigationItem {
  color: black;
  font-family: Poppins, serif, sans-serif;
  font-size: 16px;
  width: 100%;
  display: inline-block;
  text-indent: 10px;
  line-height: 40px;
  text-transform: uppercase; }
  .subNavigationItem:hover {
    color: #b70808;
    cursor: pointer; }

.navigation-free-inspection-button {
  height: 50px;
  width: 270px;
  background-color: #b70808;
  color: white;
  font: 20px PoppinsSemiBold, serif, sans-serif;
  text-transform: uppercase;
  border-radius: 12px;
  margin-left: 20px; }
  .navigation-free-inspection-button:focus {
    border: none;
    outline: none; }
  .navigation-free-inspection-button:hover {
    cursor: pointer; }

.navigation-logo-container {
  height: 80px;
  margin-left: 20px;
  display: inline-block; }

.navigation-items-container {
  height: 40px;
  margin-top: 40px;
  position: relative;
  margin-right: 20px;
  display: inline-block;
  float: right; }

.navigation-links-container {
  display: inline-block; }

.navigation-items-container-mobile {
  display: none; }

.nav-tag.active {
  color: #b70808; }

.navigationLogo {
  width: auto;
  height: 100%;
  margin-top: 20px; }

.about-expanded {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 170px;
  left: 25px;
  top: calc(100% + 5px);
  height: 120px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.about-expanded.showSub {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

#aboutTag:hover .about-expanded {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

.work-expanded {
  position: absolute;
  width: 130px;
  left: 195px;
  top: calc(100% + 5px);
  height: 120px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); }

.work-expanded.showSub {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

#workTag:hover .work-expanded {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

#serviceAreasTag {
  width: 100%; }

.service-areas-expanded {
  position: absolute;
  width: 200px;
  left: 170px;
  top: 125px;
  height: 160px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); }

.service-areas-expanded.showSub {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

#serviceAreasTag:hover {
  color: #b70808; }

#serviceAreasTag:hover .service-areas-expanded {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

#servicesTag {
  width: 100%; }

.services-expanded {
  position: absolute;
  width: 200px;
  left: 130px;
  top: 45px;
  height: 160px;
  background-color: white;
  visibility: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); }

.services-expanded.showSub {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

#servicesTag:hover {
  color: #b70808; }

#servicesTag:hover .services-expanded {
  visibility: visible;
  transition: all 1.3s ease-in-out; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .fa-bars {
    display: none; }
  .about-expanded {
    position: absolute;
    left: 0;
    top: calc(100% - 5px); }
  .work-expanded {
    left: 125px;
    top: calc(100% - 6px); }
  .navigationItem {
    color: black;
    font-family: PoppinsSemiBold, serif, sans-serif;
    font-size: 19px;
    padding: 0 8px;
    text-align: center;
    display: inline-block;
    line-height: 30px; }
    .navigationItem img {
      padding: 0 15px; }
    .navigationItem:hover {
      cursor: pointer; }
  .navigation-free-inspection-button {
    height: 40px;
    width: 200px;
    font: 14px PoppinsSemiBold, serif, sans-serif;
    margin-left: 10px; }
    .navigation-free-inspection-button:focus {
      border: none;
      outline: none; }
    .navigation-free-inspection-button:hover {
      cursor: pointer; } }

@media all and (max-width: 1500px) and (min-width: 850px) and (max-width: 1150px) {
  .navigation-free-inspection-button {
    position: fixed;
    top: 5px;
    left: 10px;
    box-shadow: 0 0 10px #00000076; }
  .navigationItem {
    font-size: 18px;
    padding: 0 6px; }
    .navigationItem:hover {
      cursor: pointer; }
  .about-expanded {
    top: calc(100% - 10px); }
  .work-expanded {
    top: calc(100% - 10px); } }

@media all and (max-width: 849px) {
  .navigation-logo-container {
    margin-left: 0; }
  .navigation-free-inspection-button {
    display: none; }
  .fa-bars {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer; }
  .navigation-links-container {
    width: 100px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    overflow: hidden;
    margin-top: 50px;
    max-height: 0; }
    .navigation-links-container div.nav-tag {
      display: none; }
    .navigation-links-container .nav-tag-mobile-only {
      display: inline-block; }
      .navigation-links-container .nav-tag-mobile-only .subNavigationItem {
        color: white !important;
        border-bottom: 1px solid white;
        width: 100%;
        display: inline-block;
        line-height: 40px;
        text-transform: uppercase;
        font-family: PoppinsSemiBold, serif, sans-serif;
        font-size: 19px;
        text-align: center; }
        .navigation-links-container .nav-tag-mobile-only .subNavigationItem:hover {
          cursor: pointer; }
    .navigation-links-container .navigationItem {
      color: white !important;
      border-bottom: 1px solid white;
      width: 100%;
      display: inline-block;
      line-height: 40px;
      text-transform: uppercase; }
      .navigation-links-container .navigationItem:hover {
        cursor: pointer; }
    .navigation-links-container.is-expanded {
      width: 100%;
      max-height: 500px;
      /* approximate max height */
      background-color: #b70808;
      color: white;
      overflow: visible; }
      .navigation-links-container.is-expanded img {
        display: none; } }

@media all and (max-width: 499px) {
  .nav-info-bar {
    overflow: hidden; }
    .nav-info-bar .sm-icon {
      display: none; }
  .navigationItem .navigationLogo {
    padding: 0 15px; }
  .navigationItem:hover {
    cursor: pointer; }
  .navigation-logo-container {
    margin-left: 0; }
    .navigation-logo-container img {
      margin-top: 20px;
      width: 80px;
      height: auto; } }

.google-reviews-background {
  width: 100%;
  margin-bottom: 100px; }
  .google-reviews-background .reviews-content {
    width: 80%;
    height: 100%;
    margin: 0 auto; }
    .google-reviews-background .reviews-content .success-text {
      text-transform: uppercase;
      text-align: center;
      margin-top: 100px;
      margin-bottom: 0;
      font: 48px PoppinsSemiBold, serif, sans-serif;
      color: #b70808; }
    .google-reviews-background .reviews-content .success-subheader {
      text-align: center;
      font: 24px Poppins, serif, sans-serif;
      color: #302F2F; }

.review-star-rating-container {
  max-width: 205px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 5px auto; }

.review-comment-text-container {
  background-color: #E6E6E6;
  height: 335px;
  border-radius: 15px;
  margin-top: 10px;
  width: 96%;
  margin-left: 2%; }
  .review-comment-text-container p {
    padding-top: 10px;
    width: 95%;
    margin: auto;
    font: 17px Poppins, sans-serif, serif; }

.reviews-holder {
  height: 350px;
  margin-top: 50px; }

.reviews-arrow-container {
  width: 10%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%; }
  .reviews-arrow-container.left {
    float: left; }
  .reviews-arrow-container.right {
    float: right; }
  .reviews-arrow-container img {
    width: 50%;
    margin: 0 auto;
    height: auto; }

.google-reviews-list {
  width: 80%;
  display: -ms-flexbox;
  display: flex;
  float: left;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly; }
  .google-reviews-list .review {
    width: 40%;
    height: 100%; }

/** REVIEW ITEM */
.review-user-info {
  width: 100%;
  height: 90px;
  display: -ms-flexbox;
  display: flex; }
  .review-user-info .review-photo-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
    .review-user-info .review-photo-container img {
      width: 60px;
      height: 60px; }

.review-name-rating-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .review-name-rating-container .review-name {
    font: 22px PoppinsMedium, sans-serif, serif;
    margin-bottom: 0;
    text-indent: 15px; }

.review-comment-container {
  width: calc(100% - 75px);
  float: right;
  max-height: 325px;
  margin: auto;
  overflow: hidden; }
  .review-comment-container p {
    font: 16px Poppins, sans-serif, serif; }
  .review-comment-container .LinesEllipsis {
    font: 16px Poppins, sans-serif, serif;
    color: #302F2F; }
  .review-comment-container .LinesEllipsis-ellipsis {
    font: 14px PoppinsSemiBold, serif, sans-serif;
    color: #b70808; }
    .review-comment-container .LinesEllipsis-ellipsis:hover {
      cursor: pointer; }

.star-rating-container {
  width: 100%;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding-left: 5px; }

/** STAR **/
.star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .9em;
  margin-right: .9em;
  margin-bottom: 1.2em;
  border-right: .3em solid transparent;
  border-bottom: .7em  solid #FC0;
  border-left: .3em solid transparent;
  /* Controls the size of the stars. */
  font-size: 17px; }
  .star:before, .star:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: .6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: .7em  solid #FC0;
    border-left: 1em solid transparent;
    -webkit-transform: rotate(-35deg);
        -ms-transform: rotate(-35deg);
            transform: rotate(-35deg); }
  .star:after {
    -webkit-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
            transform: rotate(35deg); }

.social-review-icons {
  width: 80%;
  max-width: 500px;
  height: 90px;
  margin: 0 auto 50px auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .social-review-icons img {
    height: 100%;
    width: auto; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .google-reviews-background {
    width: 100%;
    margin-bottom: 20px;
    /** REVIEW ITEM */ }
    .google-reviews-background .reviews-content {
      width: 90%;
      margin: 0 auto; }
      .google-reviews-background .reviews-content .success-text {
        margin-top: 25px;
        font-size: 56px; }
      .google-reviews-background .reviews-content .success-subheader {
        font-size: 24px; }
    .google-reviews-background .reviews-holder {
      height: 380px;
      margin-top: 40px; }
    .google-reviews-background .reviews-arrow-container {
      width: 5%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      height: 100%; }
      .google-reviews-background .reviews-arrow-container.left {
        float: left; }
      .google-reviews-background .reviews-arrow-container.right {
        float: right; }
      .google-reviews-background .reviews-arrow-container img {
        width: 80%;
        margin: 0 auto;
        height: auto; }
    .google-reviews-background .google-reviews-list {
      width: 90%;
      display: -ms-flexbox;
      display: flex;
      float: left;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
      .google-reviews-background .google-reviews-list .review {
        width: 50%;
        height: 100%; }
    .google-reviews-background .review-user-info {
      height: 90px;
      display: -ms-flexbox;
      display: flex; }
    .google-reviews-background .review-comment-container {
      float: inherit; }
  .review-comment-container .LinesEllipsis {
    font: 16px Poppins, sans-serif, serif; } }

/****************** TABLETS & MOBILE ****************/
@media all and (max-width: 849px) {
  .google-reviews-background {
    width: 100%;
    margin-bottom: 20px; }
    .google-reviews-background .reviews-content {
      width: 90%;
      height: 100%;
      margin: 0 auto; }
      .google-reviews-background .reviews-content .success-text {
        margin-top: 20px;
        font: 36px PoppinsSemiBold, serif, sans-serif; }
      .google-reviews-background .reviews-content .success-subheader {
        font: 22px Poppins, serif, sans-serif;
        margin-bottom: 0; }
  .reviews-holder {
    max-height: 300px;
    margin-top: 10px; }
  .google-reviews-list {
    width: 80%;
    display: -ms-flexbox;
    display: flex;
    float: left;
    -ms-flex-direction: column;
        flex-direction: column; }
    .google-reviews-list .review {
      width: 100%;
      height: 100%; }
    .google-reviews-list #review2 {
      display: none; }
  /** REVIEW ITEM */
  .review-user-info {
    width: 100%;
    height: 90px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    .review-user-info .review-photo-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
      .review-user-info .review-photo-container img {
        width: 60px;
        height: 60px; }
  .review-name-rating-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
    .review-name-rating-container .review-name {
      font: 25px PoppinsMedium, sans-serif, serif;
      margin-bottom: 0;
      text-indent: 15px; }
  .review-comment-container {
    width: calc(100% - 75px);
    float: inherit;
    max-height: 325px;
    margin: auto;
    overflow: hidden; }
    .review-comment-container p {
      text-align: center;
      font: 16px Poppins, sans-serif, serif; }
    .review-comment-container .LinesEllipsis {
      text-align: center;
      font: 16px Poppins, sans-serif, serif;
      color: #302F2F; }
    .review-comment-container .LinesEllipsis-ellipsis {
      font: 14px PoppinsSemiBold, serif, sans-serif;
      color: #b70808; }
      .review-comment-container .LinesEllipsis-ellipsis:hover {
        cursor: pointer; } }

@media all and (max-width: 849px) and (max-width: 500px) {
  .google-reviews-background {
    display: none; } }

/** Edge Doesn't Support Space Evently **/
@supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
  .google-reviews-list {
    -ms-flex-pack: distribute;
        justify-content: space-around; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .google-reviews-list {
    -ms-flex-pack: distribute;
        justify-content: space-around; } }

.home-process {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .home-process .process-arrow-container {
    width: 10%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    height: 100%; }
    .home-process .process-arrow-container.left {
      float: left; }
    .home-process .process-arrow-container.right {
      float: right; }
    .home-process .process-arrow-container img {
      width: 50%;
      margin: 0 auto;
      height: auto; }
  .home-process .process-image-container {
    height: 70%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 200px; }
    .home-process .process-image-container img {
      height: 90%; }
  .home-process .process-info-container {
    width: 300px;
    height: 400px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
  .home-process .process-header {
    text-transform: uppercase;
    font: 22px PoppinsBold, serif, sans-serif; }
  .home-process .process-step-name {
    color: #B70808;
    font: 42px PoppinsSemiBold, sans-serif, serif; }
  .home-process .process-description {
    font: 20px Poppins, sans-serif, serif;
    color: #302F2F; }
  .home-process .process-slides-container {
    margin-top: auto;
    height: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    -ms-flex-align: center;
        align-items: center; }
    .home-process .process-slides-container img {
      width: 40px;
      height: auto; }
      .home-process .process-slides-container img:hover {
        cursor: pointer; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .home-process .process-arrow-container {
    width: 5%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    height: 100%; }
    .home-process .process-arrow-container.left {
      float: left; }
    .home-process .process-arrow-container.right {
      float: right; }
    .home-process .process-arrow-container img {
      width: 80%;
      margin: 0 auto;
      height: auto; }
  .home-process .process-image-container {
    margin-right: 100px; }
    .home-process .process-image-container img {
      height: 75%; } }

/************************ TABLETS ***********************/
@media all and (max-width: 849px) {
  .home-process {
    display: block;
    height: inherit !important; }
    .home-process .process-arrow-container {
      display: none; }
    .home-process .process-image-container {
      width: 80%;
      padding-top: 20px;
      margin: 0 auto; }
      .home-process .process-image-container img {
        /* width: auto; */
        margin: 0 auto 10px;
        height: auto;
        max-width: 250px; }
    .home-process .process-info-container {
      width: 90%;
      height: inherit;
      margin: 0 auto;
      padding-bottom: 20px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center; }
    .home-process .process-header {
      margin-bottom: 5px; }
    .home-process .process-step-name {
      color: #B70808;
      margin-bottom: 5px;
      font: 36px PoppinsSemiBold, sans-serif, serif; }
    .home-process .process-description {
      text-align: center;
      font: 18px Poppins, sans-serif, serif;
      color: #302F2F; }
    .home-process .process-slides-container {
      height: 40px;
      width: 300px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
      -ms-flex-align: center;
          align-items: center; }
      .home-process .process-slides-container img {
        width: 40px;
        height: auto; }
        .home-process .process-slides-container img:hover {
          cursor: pointer; } }

.products-background {
  width: 100%;
  height: 140px;
  background-color: white;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070; }

.products-container {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .products-container img {
    width: auto;
    height: 100px;
    margin-top: 15px; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .products-container {
    width: 90%; } }

/****************** TABLETS & MOBILE ****************/
@media all and (max-width: 849px) {
  .products-background {
    height: auto; }
  .products-container {
    width: 90%;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-bottom: 15px; }
    .products-container .image-container {
      width: 30%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; }
    .products-container img {
      width: 80%;
      max-width: 100px;
      float: right;
      height: auto;
      margin: 15px auto 0 auto; } }

html {
  overflow: -moz-scrollbars-none; }

.covid-19-message {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(1, 1, 1, 0.8);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px; }
  .covid-19-message p {
    color: white;
    padding: 20px 0;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font: 20px Poppins, serif, sans-serif; }
  .covid-19-message button {
    position: absolute;
    right: 30px;
    top: calc(50% - 30px);
    height: 60px;
    border: none;
    background: none;
    color: white;
    font: 36px PoppinsSemiBold, sans-serif, serif; }
    .covid-19-message button:hover {
      color: #b70808; }
    .covid-19-message button:focus {
      border: none;
      outline: none; }

.home .video-container {
  width: 100vw; }

.home .fullscreen-video {
  width: 100%; }

.intro {
  opacity: 1;
  height: 800px;
  width: 100%;
  position: relative;
  background-size: cover;
  display: none; }

.intro-basics {
  width: 600px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: absolute;
  top: calc(50% - 165px);
  left: 250px; }
  .intro-basics .intro-header {
    font: 22px PoppinsBold, sans-serif, serif;
    color: white;
    text-shadow: 0 0 10px #000000;
    text-transform: uppercase; }
  .intro-basics .intro-description {
    font: 48px PoppinsSemiBold, sans-serif, serif;
    color: white;
    text-shadow: 0 0 10px #000000; }
  .intro-basics .free-inspection-button-container {
    width: 92%;
    height: 65px;
    background-color: white;
    box-shadow: 0 0 10px #00000076;
    border-radius: 9px;
    border: 2px solid rgba(183, 8, 8, 0); }
    .intro-basics .free-inspection-button-container:hover {
      cursor: pointer;
      border: 2px solid #b70808; }
    .intro-basics .free-inspection-button-container .home-inspection-label {
      text-transform: uppercase;
      color: #b70808;
      font: 22px PoppinsSemiBold, Serif, sans-serif;
      display: inline-block;
      line-height: 63px;
      margin-left: 15px; }
    .intro-basics .free-inspection-button-container .home-inspection-img {
      float: right;
      margin-right: 2px;
      margin-top: 1px;
      display: inline-block; }

.whatWeDo .content {
  width: 80%;
  max-width: 1200px;
  margin: 100px auto; }
  .whatWeDo .content .smallText {
    font: 22px PoppinsBold, serif, sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .whatWeDo .content .largeText {
    font: 44px PoppinsSemiBold, serif, sans-serif;
    color: #b70808; }
  .whatWeDo .content .services-container {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .whatWeDo .content .services-container .single-service-container {
      max-width: 350px;
      height: 100%;
      -ms-flex-positive: 1;
          flex-grow: 1;
      position: relative; }
      .whatWeDo .content .services-container .single-service-container .main-service-image {
        box-shadow: 0 0 5px #00000076; }
      .whatWeDo .content .services-container .single-service-container img {
        width: 100%;
        height: auto; }
      .whatWeDo .content .services-container .single-service-container .service-mini-image {
        position: absolute;
        width: 80px;
        height: 80px;
        right: 30px;
        top: 180px; }
      .whatWeDo .content .services-container .single-service-container .service-header {
        font: 24px PoppinsSemiBold, serif, sans-serif;
        color: #b70808;
        margin-top: 10px;
        margin-bottom: 5px;
        text-transform: uppercase; }
      .whatWeDo .content .services-container .single-service-container .service-description {
        font: 18px Poppins, serif, sans-serif;
        color: #414040;
        margin-bottom: 10px; }
      .whatWeDo .content .services-container .single-service-container .learn-more-link {
        font: 14px PoppinsSemiBold, serif, sans-serif;
        color: #b70808;
        text-transform: uppercase; }

/***
PROCESS CONTAINER
 */
.home-process {
  width: 100%;
  height: 600px;
  background-color: #E6E6E6; }

.empty-space {
  width: 100%;
  height: 150px;
  background-color: lightcoral; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .whatWeDo .content {
    width: 95%;
    max-width: 1200px;
    margin: 50px auto; }
    .whatWeDo .content .largeText {
      font: 56px PoppinsSemiBold, serif, sans-serif;
      color: #b70808; } }

@media all and (max-width: 1500px) and (min-width: 850px) and (max-width: 1150px) {
  .intro-basics {
    width: 600px;
    left: 100px;
    top: calc(50% - 255px); }
    .intro-basics .intro-description {
      font-size: 36px; }
  .whatWeDo .content .services-container .single-service-container {
    max-width: none;
    max-width: initial;
    width: 30%;
    height: 100%;
    -ms-flex-positive: initial;
        flex-grow: initial;
    position: relative; }
    .whatWeDo .content .services-container .single-service-container .service-mini-image {
      display: none; }
    .whatWeDo .content .services-container .single-service-container .service-header {
      font-size: 24px;
      margin-top: 5px;
      margin-bottom: 0; }
    .whatWeDo .content .services-container .single-service-container .service-description {
      font-size: 17px; } }

/****************** TABLETS ****************/
@media all and (max-width: 849px) {
  .covid-19-message p {
    font: 16px Poppins, serif, sans-serif; }
  .covid-19-message button {
    right: 15px;
    top: calc(50% - 25px);
    font: 28px PoppinsSemiBold, sans-serif, serif; }
  .intro {
    background: transparent url(/static/media/background.a5f77550.png) center center repeat padding-box;
    height: 700px; }
  .intro-basics {
    width: 90%;
    position: absolute;
    top: calc(50% - 300px);
    left: 5%; }
    .intro-basics .intro-description {
      font: 28px PoppinsSemiBold, sans-serif, serif; }
    .intro-basics .free-inspection-button-container {
      width: 350px;
      height: 45px; }
      .intro-basics .free-inspection-button-container .home-inspection-label {
        font: 18px PoppinsSemiBold, Serif, sans-serif;
        display: inline-block;
        line-height: 43px;
        margin-left: 5px; }
      .intro-basics .free-inspection-button-container .home-inspection-img {
        width: 39px;
        height: 39px; }
  .whatWeDo .content {
    width: 90%;
    margin: 30px auto 20px auto; }
    .whatWeDo .content .smallText {
      text-align: center;
      margin-bottom: 5px; }
    .whatWeDo .content .largeText {
      font: 42px PoppinsSemiBold, serif, sans-serif;
      color: #b70808;
      text-align: center; }
    .whatWeDo .content .services-container {
      width: 100%;
      display: block;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .whatWeDo .content .services-container .single-service-container {
        max-width: inherit;
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        border-bottom: 1px solid #b70808;
        margin-bottom: 10px;
        -ms-flex-align: center;
            align-items: center; }
        .whatWeDo .content .services-container .single-service-container .service-mini-image {
          display: none; }
        .whatWeDo .content .services-container .single-service-container img {
          max-width: 450px; }
        .whatWeDo .content .services-container .single-service-container .service-header {
          font: 24px PoppinsSemiBold, serif, sans-serif;
          color: #b70808;
          margin-top: 5px; }
        .whatWeDo .content .services-container .single-service-container .service-description {
          font: 18px Poppins, serif, sans-serif;
          color: #414040;
          margin-bottom: 5px;
          text-align: center; }
        .whatWeDo .content .services-container .single-service-container .learn-more-link {
          font: 14px PoppinsSemiBold, serif, sans-serif;
          color: #b70808;
          text-transform: uppercase;
          margin-bottom: 5px; } }

@media all and (max-width: 550px) {
  .intro-basics {
    top: 150px;
    -webkit-transform: translate(1%, 5%);
        -ms-transform: translate(1%, 5%);
            transform: translate(1%, 5%); }
    .intro-basics .intro-header {
      font-size: 13px;
      margin-bottom: 5px; }
    .intro-basics .intro-description {
      font-size: 16px; }
    .intro-basics .free-inspection-button-container {
      width: 300px; }
      .intro-basics .free-inspection-button-container .home-inspection-label {
        font-size: 15px; } }

.work-banner-container {
  width: 100%;
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around; }
  .work-banner-container .who-we-are {
    width: 50%;
    display: inline-block;
    position: relative; }
    .work-banner-container .who-we-are .banner-display-image {
      min-width: 300px;
      width: 100%;
      height: auto; }
    .work-banner-container .who-we-are .work-info-container {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 80%;
      margin: 50px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .work-banner-container .who-we-are .work-info-container .info-title {
        font: 36px PoppinsSemiBold, serif, sans-serif;
        color: #b70808; }
      .work-banner-container .who-we-are .work-info-container .small-header {
        font: 19px PoppinsSemiBold, serif, sans-serif;
        color: white;
        margin-top: 10px;
        margin-bottom: 0;
        text-transform: uppercase; }
      .work-banner-container .who-we-are .work-info-container .large-header {
        color: white;
        font: 36px PoppinsSemiBold, sans-serif, serif; }
      .work-banner-container .who-we-are .work-info-container .work-banner-description {
        color: white;
        font: 20px Poppins, sans-serif, serif;
        margin-bottom: 25px; }
  .work-banner-container .how-we-do-it {
    width: 50%;
    display: inline-block;
    overflow: hidden;
    position: relative; }
    .work-banner-container .how-we-do-it .banner-display-image {
      min-width: 300px;
      width: 100%;
      height: auto; }
    .work-banner-container .how-we-do-it .work-info-container {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 80%;
      margin: 50px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .work-banner-container .how-we-do-it .work-info-container .info-title {
        font: 36px PoppinsSemiBold, serif, sans-serif;
        color: white; }
      .work-banner-container .how-we-do-it .work-info-container .small-header {
        font: 19px PoppinsSemiBold, serif, sans-serif;
        color: white;
        margin-top: 10px;
        margin-bottom: 0;
        text-transform: uppercase; }
      .work-banner-container .how-we-do-it .work-info-container .large-header {
        color: white;
        font: 36px PoppinsSemiBold, sans-serif, serif; }
      .work-banner-container .how-we-do-it .work-info-container .work-banner-description {
        color: white;
        font: 20px Poppins, sans-serif, serif;
        margin-bottom: 25px; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .arrow-button-container {
    width: 100%; } }

/************************ TABLETS ***********************/
@media all and (max-width: 849px) {
  .work-banner-container {
    width: 100%;
    -ms-flex-direction: column;
        flex-direction: column; }
    .work-banner-container .who-we-are {
      width: 100%;
      display: block; }
      .work-banner-container .who-we-are .work-info-container {
        left: 5%;
        width: 90%; }
        .work-banner-container .who-we-are .work-info-container .small-header {
          margin: 10px 0;
          font: 28px PoppinsBold, sans-serif, serif; }
        .work-banner-container .who-we-are .work-info-container .large-header {
          font: 22px PoppinsSemiBold, sans-serif, serif; }
        .work-banner-container .who-we-are .work-info-container .work-banner-description {
          color: white;
          font: 20px Poppins, sans-serif, serif;
          margin-bottom: 50px; }
    .work-banner-container .how-we-do-it {
      width: 100%;
      display: block; }
      .work-banner-container .how-we-do-it .work-info-container {
        left: 5%;
        width: 90%; }
        .work-banner-container .how-we-do-it .work-info-container .small-header {
          margin: 10px 0;
          font: 28px PoppinsBold, sans-serif, serif; }
        .work-banner-container .how-we-do-it .work-info-container .large-header {
          font: 22px PoppinsSemiBold, sans-serif, serif; }
        .work-banner-container .how-we-do-it .work-info-container .work-banner-description {
          color: white;
          font: 20px Poppins, sans-serif, serif;
          margin-bottom: 50px; } }

@media all and (max-width: 500px) {
  .work-banner-container {
    width: 100%;
    -ms-flex-direction: column;
        flex-direction: column; }
    .work-banner-container .who-we-are {
      width: 100%;
      display: block; }
      .work-banner-container .who-we-are .work-info-container {
        left: 5%;
        width: 90%; }
        .work-banner-container .who-we-are .work-info-container .small-header {
          font: 28px PoppinsBold, sans-serif, serif; }
        .work-banner-container .who-we-are .work-info-container .large-header {
          font: 22px PoppinsSemiBold, sans-serif, serif; }
        .work-banner-container .who-we-are .work-info-container .work-banner-description {
          color: white;
          font: 18px Poppins, sans-serif, serif;
          margin-bottom: 50px; }
    .work-banner-container .how-we-do-it {
      width: 100%;
      display: block; }
      .work-banner-container .how-we-do-it .work-info-container {
        left: 5%;
        width: 90%; }
        .work-banner-container .how-we-do-it .work-info-container .small-header {
          font: 28px PoppinsBold, sans-serif, serif; }
        .work-banner-container .how-we-do-it .work-info-container .large-header {
          font: 22px PoppinsSemiBold, sans-serif, serif; }
        .work-banner-container .how-we-do-it .work-info-container .work-banner-description {
          color: white;
          font: 18px Poppins, sans-serif, serif;
          margin-bottom: 50px; }
  .arrow-button-container {
    min-width: 275px; } }

.arrow-button-container {
  width: 75%;
  height: 65px;
  background-color: white;
  box-shadow: 0 0 10px #00000076;
  border-radius: 9px;
  border: 2px solid rgba(183, 8, 8, 0); }
  .arrow-button-container:hover {
    border: 2px solid #b70808; }
  .arrow-button-container .arrow-button-label {
    text-transform: uppercase;
    color: #b70808;
    font: 22px PoppinsSemiBold, Serif, sans-serif;
    display: inline-block;
    line-height: 63px;
    margin-left: 15px; }
  .arrow-button-container .arrow-button-img {
    float: right;
    margin-right: 2px;
    margin-top: 1px;
    display: inline-block; }

.free-quote-container {
  width: 100%;
  height: 130px;
  background-color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.free-quote-button {
  background-color: #b70808;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;
  font: 20px PoppinsSemiBold,serif,sans-serif;
  height: auto;
  min-height: 52px;
  max-width: 90vw;
  width: 350px; }
  .free-quote-button:hover {
    background-color: #B70808;
    color: white;
    border: 2px solid #302F2F;
    cursor: pointer; }

.free-quote-text {
  color: #B70808;
  font: 28px PoppinsSemiBold, serif, sans-serif;
  margin-bottom: 0;
  margin-right: 20px; }

/******************* TABLETS & SMALLER **************************/
@media all and (max-width: 849px) {
  .free-quote-container {
    height: auto;
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .free-quote-button {
    background-color: #b70808;
    color: white;
    text-transform: uppercase;
    border-radius: 12px;
    font: 20px PoppinsSemiBold, serif, sans-serif;
    height: auto;
    min-height: 52px;
    max-width: 90vw;
    width: 350px;
    margin-bottom: 20px; }
    .free-quote-button:hover {
      color: #b70808;
      background-color: white;
      border: 3px solid #b70808;
      cursor: pointer; }
  .free-quote-text {
    color: #B70808;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    margin: 20px auto 10px auto; } }

/**
RED BACKGROUND
 */
.white-padding {
  width: 100%;
  height: 50px; }

.free-quote-container-inverse {
  width: 100%;
  height: 150px;
  background-color: #B70808;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.free-quote-button-inverse {
  background-color: white;
  color: #B70808;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;
  font: 20px PoppinsSemiBold,serif,sans-serif;
  height: auto;
  min-height: 52px;
  max-width: 90vw;
  width: 350px; }
  .free-quote-button-inverse:hover {
    background-color: #B70808;
    color: white;
    border: 2px solid #302F2F;
    cursor: pointer; }

.free-quote-text-inverse {
  color: white;
  font: 28px PoppinsSemiBold, serif, sans-serif;
  margin-bottom: 0;
  margin-right: 20px; }

/******************* TABLETS & SMALLER **************************/
@media all and (max-width: 849px) {
  .free-quote-container {
    height: auto;
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .free-quote-button {
    background-color: #b70808;
    color: white;
    text-transform: uppercase;
    border-radius: 12px;
    font: 20px PoppinsSemiBold, serif, sans-serif;
    height: auto;
    min-height: 52px;
    max-width: 90vw;
    width: 350px;
    margin-bottom: 20px; }
    .free-quote-button:hover {
      color: #b70808;
      background-color: white;
      border: 3px solid #b70808;
      cursor: pointer; }
  .free-quote-text {
    color: #B70808;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    margin: 20px auto 10px auto; }
  .free-quote-container-inverse {
    height: auto;
    background-color: #B70808;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .free-quote-button-inverse {
    background-color: white;
    color: #B70808;
    text-transform: uppercase;
    border-radius: 12px;
    font: 20px PoppinsSemiBold, serif, sans-serif;
    height: auto;
    min-height: 52px;
    max-width: 90vw;
    width: 350px;
    margin-bottom: 20px; }
    .free-quote-button-inverse:hover {
      color: #b70808;
      background-color: white;
      border: 3px solid #b70808;
      cursor: pointer; }
  .free-quote-text-inverse {
    color: white;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    margin: 20px auto 10px auto; } }

.work-banner {
  background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px; }
  .work-banner img {
    width: 100%;
    height: 100%; }
  .work-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px); }

.work-banner-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%; }
  .work-banner-text-container .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000; }
  .work-banner-text-container .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000; }

.gallery-container {
  width: 100%;
  margin: 100px auto; }
  .gallery-container .main-text {
    font: 62px PoppinsSemiBold, serif, sans-serif;
    color: #b70808;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0; }
  .gallery-container .small-text {
    font: 28px Poppins, serif, sans-serif;
    text-align: center;
    margin: 0 auto 30px auto; }
  .gallery-container .gallery-sort-container {
    width: 80%;
    margin: 20px auto;
    height: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .gallery-container .gallery-sort-container p {
      font: 18px PoppinsSemiBold, sans-serif, serif;
      text-transform: uppercase; }
      .gallery-container .gallery-sort-container p:hover {
        color: #b70808; }
  .gallery-container .gallery-images-container {
    width: 90%;
    margin: 0 auto 20px auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
    .gallery-container .gallery-images-container .work-display-image-container {
      width: 27%;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 30px;
      background-color: white;
      box-shadow: 0 0 10px #00000052;
      border: 10px solid #FFFFFF; }
      .gallery-container .gallery-images-container .work-display-image-container img {
        width: 100%;
        height: 100%; }
        .gallery-container .gallery-images-container .work-display-image-container img:hover {
          cursor: pointer; }
  .gallery-container .gallery-show-photos-button {
    background-color: #b70808;
    color: #fff;
    text-transform: uppercase;
    border-radius: 12px;
    font: 24px PoppinsSemiBold,serif,sans-serif;
    height: 52px;
    width: 200px;
    max-width: 90vw;
    margin-left: calc(50% - 100px); }
    .gallery-container .gallery-show-photos-button:focus {
      border: none;
      outline: none; }
    .gallery-container .gallery-show-photos-button:hover {
      cursor: pointer;
      background-color: #B70808;
      color: white;
      border: 2px solid #302F2F; }

.gray-banner {
  width: 100%;
  height: 140px;
  background-color: #E6E6E6; }

.work-process-container .work-process-banner {
  height: 250px;
  width: 100%;
  border-bottom: 2px solid #E6E6E6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative; }
  .work-process-container .work-process-banner .banner-icon {
    position: absolute;
    top: 0; }
  .work-process-container .work-process-banner .main-text {
    font: 48px PoppinsSemiBold, serif, sans-serif;
    color: #b70808;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0; }
  .work-process-container .work-process-banner .small-text {
    font: 25px Poppins, serif, sans-serif;
    text-align: center;
    margin-bottom: 0; }

.work-process-container .work-process-content {
  width: 80%;
  margin: 50px auto; }
  .work-process-container .work-process-content .work-item-container {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px; }
    .work-process-container .work-process-content .work-item-container .work-item-icon-container {
      width: 150px; }
      .work-process-container .work-process-content .work-item-container .work-item-icon-container img {
        width: 140px;
        height: auto; }
    .work-process-container .work-process-content .work-item-container .work-item-info-container {
      -ms-flex-positive: 1;
          flex-grow: 1; }
      .work-process-container .work-process-content .work-item-container .work-item-info-container .step-header {
        width: 100%;
        font: 48px PoppinsSemiBold, serif, sans-serif;
        color: #b70808;
        border-bottom: 1px solid red;
        padding-left: 10px; }
      .work-process-container .work-process-content .work-item-container .work-item-info-container .step-info {
        width: 100%;
        font: 20px Poppins, serif, sans-serif;
        padding-left: 10px; }
      .work-process-container .work-process-content .work-item-container .work-item-info-container .step-info-bold {
        font: 20px PoppinsSemiBold, serif, sans-serif; }

.view-work-modal-content {
  height: 625px;
  width: 900px;
  margin-top: calc(50vh - 313px);
  margin-left: calc(50vw - 450px);
  background-color: white;
  border-radius: 23px;
  position: relative; }
  .view-work-modal-content .view-work-image-container {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .view-work-modal-content .view-work-image-container img {
      max-width: 95%;
      max-height: 90%;
      border-radius: 23px; }
  .view-work-modal-content .view-work-icon-container {
    position: absolute;
    top: 0;
    right: 5px; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .work-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; }
  .gallery-container {
    margin: 50px auto; }
  .view-work-modal-content {
    height: 500px;
    width: 750px;
    margin-top: calc(50vh - 313px);
    margin-left: calc(50vw - 375px); } }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .work-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; }
  .gallery-container {
    margin: 50px auto; }
  .view-work-modal-content {
    height: 500px;
    width: 750px;
    margin-top: calc(50vh - 313px);
    margin-left: calc(50vw - 375px); } }

/****************** TABLETS ****************/
@media all and (max-width: 849px) and (min-width: 500px) {
  .work-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; }
  .gallery-container {
    margin: 50px auto; }
  .view-work-modal-content {
    height: 500px;
    width: 90%;
    margin-top: calc(50vh - 200px);
    margin-left: calc(50vw - 45%); }
  .gallery-container {
    width: 100%;
    margin: 30px auto; }
    .gallery-container .main-text {
      font: 42px PoppinsSemiBold, serif, sans-serif; }
    .gallery-container .small-text {
      text-align: center;
      margin: 0 auto 20px auto; }
    .gallery-container .gallery-images-container {
      width: 95%;
      margin: 0 auto 20px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
      .gallery-container .gallery-images-container .work-display-image-container {
        width: 40%;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 30px;
        background-color: white;
        box-shadow: 0 0 10px #00000052;
        border: 10px solid #FFFFFF; }
  .work-process-container .work-process-banner .banner-icon {
    position: absolute;
    top: 0; }
  .work-process-container .work-process-banner .main-text {
    font: 36px PoppinsSemiBold, serif, sans-serif; }
  .work-process-container .work-process-banner .small-text {
    font: 22px Poppins, serif, sans-serif; }
  .work-process-container .work-process-content .work-item-container .work-item-info-container .step-header {
    font: 42px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 499px) {
  .work-banner-text-container .banner-text-large {
    font: 36px PoppinsSemiBold, serif, sans-serif; }
  .gallery-container {
    margin: 50px auto; }
  .view-work-modal-content {
    height: 500px;
    width: 90%;
    margin-top: calc(50vh - 313px);
    margin-left: calc(50vw - 45%); }
  .gallery-container {
    width: 100%;
    margin: 30px auto; }
    .gallery-container .main-text {
      font: 42px PoppinsSemiBold, serif, sans-serif; }
    .gallery-container .small-text {
      text-align: center;
      margin: 0 auto 20px auto; }
    .gallery-container .gallery-images-container {
      width: 95%;
      margin: 10px auto 20px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
      .gallery-container .gallery-images-container .work-display-image-container {
        width: 90%;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 30px;
        background-color: white;
        box-shadow: 0 0 10px #00000052;
        border: 10px solid #FFFFFF; }
  .work-process-container .work-process-banner .banner-icon {
    position: absolute;
    top: 0; }
  .work-process-container .work-process-banner .main-text {
    font: 24px PoppinsSemiBold, serif, sans-serif; }
  .work-process-container .work-process-banner .small-text {
    font: 18px Poppins, serif, sans-serif; }
  .work-process-container .work-process-content {
    width: 90%;
    margin: 25px auto; }
    .work-process-container .work-process-content .work-item-container {
      -ms-flex-direction: column;
          flex-direction: column; }
      .work-process-container .work-process-content .work-item-container .work-item-info-container .step-header {
        font: 42px PoppinsSemiBold, serif, sans-serif; }
      .work-process-container .work-process-content .work-item-container .work-item-info-container .step-container {
        width: 100%;
        height: 50px;
        background-color: red; } }

/** Edge Doesn't Support Space Evently **/
@supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
  .gallery-container .gallery-images-container {
    -ms-flex-pack: distribute;
        justify-content: space-around; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .gallery-container .gallery-images-container {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .work-banner-text-container .banner-text-large {
    font: 36px PoppinsSemiBold, serif, sans-serif;
    max-width: 96vw; } }

@media all and (max-width: 300px) {
  .work-banner-text-container .banner-text-large {
    font: 36px PoppinsSemiBold, serif, sans-serif;
    max-width: 96vw; } }

.modalContainer {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
}

.hiddenModal {
    display: none;
}

.modalContent {
    width: 500px;
    margin-top: 200px;
    margin-left: calc(50vw - 250px);
    background-color: white;
    border-radius: 23px;
}

.modalHeader {
    width: 90%;
    margin: auto auto 30px;
    padding-top: 25px;
    text-align: center;
    font: 24px PoppinsMedium, serif, sans-serif;
    color: rgb(183, 8, 8);
}

.errorItem {
    color: black;
    font: 28px Poppins, serif;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
}


.errorOkButton {
    background-color: rgb(182, 8, 7);
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    font: 20px Poppins, serif;
    width: 150px;
    margin-left: calc(50% - 75px);
    height: 50px;
    word-spacing: 5px;
    border-radius: 12px;


}

.errorOkButton:hover {
    cursor: pointer;
}

.errorOkButton:focus {
    border: none;
    outline: none;
}

.modalContentWorkMobile {
    width: 90%;
    margin-top: 50px;
    margin-left:  5%;
    background-color: white;
}



.workHeaderDisplay {
    width: 100%;
    padding-top: 25px;
    text-align: center;
    word-spacing: 5px;
    font-family: Bebas, serif;
    font-size: 24px;
    color: rgb(182, 8, 7);
}

.workItemHeader {
    text-align: center;
    font-family: Bebas, serif;
    font-size: 20px;
    margin-bottom: 0;
}

.workItemModalDescription {
    width: 90%;
    margin: 5px auto;
    text-align: center;

}

.workMobileImage {
    margin-left: calc(50% - 75px);
    width: 150px;
    height: auto;
}

.workMobileCloseButton {
    background-color: rgb(182, 8, 7);
    color: white;
    margin-top: 10px;
    margin-bottom: 40px;
    font-family: Bebas, serif;
    width: 150px;
    margin-left: calc(50% - 75px);
    height: 50px;
    word-spacing: 5px;
    font-size: 20px;
}

@media all and (max-width: 600px) {

    .modalContent {
        width: 90%;
        margin-left: 5%;
    }

    .modalHeader {
        width: 90%;
        margin: auto auto 20px;
        padding-top: 15px;
        text-align: center;
        font: 20px PoppinsMedium, serif, sans-serif;
        color: rgb(183, 8, 8);
    }

    .program-schedule-time-container {
        width: 95%;
        margin: 10px auto;
    }

}


.work-step-item-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-bottom: 20px; }
  .work-step-item-container img {
    height: 20px;
    width: auto; }
  .work-step-item-container p {
    font: 16px Poppins, sans-serif, serif;
    padding-left: 10px;
    margin-bottom: 0;
    padding-bottom: 5px; }

.meet-the-docs-container .docs-banner {
  background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px; }
  .meet-the-docs-container .docs-banner img {
    width: 100%;
    height: 100%; }
  .meet-the-docs-container .docs-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px); }

.meet-the-docs-container .docs-banner-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .meet-the-docs-container .docs-banner-text-container .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000; }
  .meet-the-docs-container .docs-banner-text-container .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000; }

.meet-the-docs-container .docs-oath {
  width: 80%;
  margin: 50px auto 0 auto;
  padding-left: 15px;
  border-left: 4px solid #b70808;
  font: 28px PoppinsSemiBold, serif, sans-serif; }

.meet-the-docs-container .docs-list-container {
  width: 80%;
  margin: 100px auto 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center; }

/******* DOCS CONTAINER */
.doc-container {
  width: 30%;
  min-width: 350px;
  height: 450px;
  background-color: white;
  position: relative; }
  .doc-container .doc-image-container {
    width: 350px;
    height: 350px;
    background-color: rgba(0, 0, 0, 0.9);
    margin: 0 auto;
    border-radius: 50%;
    position: relative;
    overflow: hidden; }
    .doc-container .doc-image-container img {
      width: 350px;
      height: auto; }
  .doc-container .doc-icon-container {
    position: absolute;
    top: 0;
    right: 10%;
    width: 100px;
    height: 100px; }
    .doc-container .doc-icon-container img {
      width: 100px;
      height: 100px; }
  .doc-container .doc-name {
    text-transform: uppercase;
    text-align: center;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    color: #b70808;
    margin-bottom: 0;
    margin-top: 10px; }
  .doc-container .doc-position {
    text-transform: uppercase;
    margin: 15px auto;
    text-align: center;
    font: 20px PoppinsMedium, serif, sans-serif; }

/* VIEW DOC MODAL */
.view-doc-modal-content {
  height: 800px;
  width: 600px;
  margin-top: calc(50vh - 400px);
  margin-left: calc(50vw - 300px);
  background-color: white;
  border-radius: 23px; }
  .view-doc-modal-content .doc-container-modal {
    border-radius: 23px;
    padding-top: 40px;
    height: 500px;
    background-color: white;
    position: relative; }
    .view-doc-modal-content .doc-container-modal .doc-image-container-modal {
      width: 300px;
      height: 300px;
      background-color: rgba(0, 0, 0, 0.9);
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      overflow: hidden; }
      .view-doc-modal-content .doc-container-modal .doc-image-container-modal img {
        width: 300px;
        height: auto; }
    .view-doc-modal-content .doc-container-modal .doc-icon-container-modal {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100px; }
      .view-doc-modal-content .doc-container-modal .doc-icon-container-modal img {
        width: 100px;
        height: 100px; }
    .view-doc-modal-content .doc-container-modal .doc-name {
      text-transform: uppercase;
      text-align: center;
      font: 28px PoppinsSemiBold, serif, sans-serif;
      color: #b70808;
      margin-bottom: 0;
      padding-top: 20px; }
    .view-doc-modal-content .doc-container-modal .doc-position {
      text-transform: uppercase;
      margin: 15px auto;
      text-align: center;
      font: 20px PoppinsMedium, serif, sans-serif; }
    .view-doc-modal-content .doc-container-modal .doc-bio-modal-content {
      background-color: #E6E6E6;
      height: 335px;
      border-radius: 15px;
      margin-top: 50px;
      width: 96%;
      margin-left: 2%;
      overflow: auto; }
      .view-doc-modal-content .doc-container-modal .doc-bio-modal-content p {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 95%;
        margin: auto;
        font: 17px Poppins, sans-serif, serif; }

/*************** SMALL LAPTOPS ***************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .meet-the-docs-container .docs-banner-text-container .banner-text-large {
    font: 35px PoppinsSemiBold, serif, sans-serif; }
  .meet-the-docs-container .docs-list-container {
    width: 90%;
    -ms-flex-pack: distribute;
        justify-content: space-around; } }

/*************** TABLETS ***************/
@media all and (max-width: 849px) and (min-width: 700px) {
  .meet-the-docs-container .docs-oath {
    font: 20px PoppinsSemiBold, serif, sans-serif; }
  .meet-the-docs-container .docs-banner-text-container {
    width: 100%; }
    .meet-the-docs-container .docs-banner-text-container .banner-text-large {
      font-size: 36px; }
  .meet-the-docs-container .docs-list-container {
    width: 90%;
    margin: 50px auto 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-align: center;
        align-items: center; }
  .doc-container {
    width: 40%;
    min-width: 250px;
    height: 350px;
    background-color: white;
    position: relative; }
    .doc-container .doc-image-container {
      width: 250px;
      height: 250px;
      background-color: rgba(0, 0, 0, 0.9);
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      overflow: hidden; }
      .doc-container .doc-image-container img {
        width: 250px;
        height: auto; }
    .doc-container .doc-icon-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      height: 70px; }
      .doc-container .doc-icon-container img {
        width: 70px;
        height: 70px; }
    .doc-container .doc-name {
      text-transform: uppercase;
      text-align: center;
      font: 28px PoppinsSemiBold, serif, sans-serif;
      color: #b70808;
      margin-bottom: 0;
      margin-top: 10px; }
    .doc-container .doc-position {
      text-transform: uppercase;
      margin: 15px auto;
      text-align: center;
      font: 20px PoppinsMedium, serif, sans-serif; } }

/*************** MOBILE ***************/
@media all and (max-width: 699px) {
  .meet-the-docs-container .docs-oath {
    font: 17px PoppinsSemiBold, serif, sans-serif; }
  .meet-the-docs-container .docs-banner-text-container {
    width: 100%; }
    .meet-the-docs-container .docs-banner-text-container .banner-text-small {
      font-size: 20px; }
    .meet-the-docs-container .docs-banner-text-container .banner-text-large {
      font-size: 30px; }
  .meet-the-docs-container .docs-list-container {
    width: 90%;
    margin: 50px auto 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-align: center;
        align-items: center; }
  .doc-container {
    width: 100%;
    height: 375px; }
    .doc-container .doc-image-container {
      width: 300px;
      height: 300px; }
      .doc-container .doc-image-container img {
        width: 300px;
        height: auto; }
    .doc-container .doc-icon-container {
      display: none; }
    .doc-container .doc-name {
      text-transform: uppercase;
      text-align: center;
      font: 28px PoppinsSemiBold, serif, sans-serif;
      color: #b70808;
      margin-bottom: 0;
      margin-top: 10px; }
    .doc-container .doc-position {
      text-transform: uppercase;
      margin: 15px auto;
      text-align: center;
      font: 20px PoppinsMedium, serif, sans-serif; } }

.stormDamage {
    margin-top: 50px;
    position: relative;
    min-height: calc(100vh - 280px);

}

.stormDamageContent {
    width: 100%;

}

.stormDamageInfo {
    width: 65%;
    height: 100%;
    float: left;
    margin-top: 3%;
}

.stormDamageInfo img {
    width: 80%;
    height: auto;
    margin-left: 10%;
    max-width: 900px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
    padding: 10px;
    background: white;

    border: 1px solid #8a4419;
    /*border-style: inset;*/
}

.stormDamageInfo img:after {
    content: '';
    position: absolute;
    z-index: -1; /* hide shadow behind image */
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    width: 70%;
    left: 15%; /* one half of the remaining 30% */
    height: 100px;
    bottom: 0;
}



#stormDamageDescription {
    text-align: center;
    width: 80%;
    margin: 60px auto;
    font-size: 18px;
}

.stormDamageImportant {
    color: rgb(182, 8, 7);
    word-spacing: 5px;
    font-size: 20px;
}

.stormReport {
    width: 35%;
    height: 100%;
    float: right;
}

.estimateButton {
    width: 250px;
    height: 40px;
    background-color: rgb(182, 8,7);
    color: white;
    font-family: Bebas, serif;
    word-spacing: 5px;
    margin-top: 20px;
    margin-left: calc(50% - 125px);
    border-radius: 5px;
}

.estimateButton:hover {
    cursor: pointer;
}

.stormDamageFooter {
    width: 100%;
    height: 80px;
}

.stormDamageFooterText {
    float: right;
    font-family: FranklinGothicHeavy, serif;
    font-size: 60px;
    color: rgb(182,8,7);
    text-transform: uppercase;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 5px;
}

.damageLink {
    color: rgb(182, 8, 7);
    margin-right: 20px;
}

.damageLink:hover {
    color: rgb(182, 8, 7);
}

@media screen and (min-width: 601px) and (max-width: 1000px){

    .stormDamage {
        height: auto;
    }

    .stormDamageInfo {
        width: 100%;
        height: 100%;
    }

    .stormDamageInfo img {
        width: 70%;
        margin-left: 15%;
        height: auto;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        padding: 10px;
        background: white;
        border: 1px solid #8a4419;
    }

    .stormReport {
        width: 100%;
    }

    #stormDamageDescription {
        margin: 30px auto;
    }

    .estimateButton {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .stormDamageFooter {
        position: inherit;
    }

    .stormDamageFooterText {
        font-size: 34px;
    }

}

@media screen and (max-width: 600px){

    .stormDamage {
        margin-top: 20px;
        height: auto;
    }

    .stormDamageContent {
        width: 100%;

    }

    .stormDamageInfo {
        width: 100%;
        height: 100%;
        float: none;
    }

    .stormDamageInfo img {
        width: 90%;
        height: auto;
        margin-left: 5%;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        padding: 10px;
        background: white;
        border: 1px solid #8a4419;
    }

    .stormDamageInfo img:after {
        content: '';
        position: absolute;
        z-index: -1; /* hide shadow behind image */
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        width: 70%;
        left: 15%; /* one half of the remaining 30% */
        height: 100px;
        bottom: 0;
    }

    #stormDamageDescription {
        text-align: center;
        width: 90%;
        margin: 30px auto;
        font-size: 16px;
    }

    .stormDamageImportant {
        color: rgb(182, 8, 7);
        word-spacing: 5px;
        font-size: 20px;
    }

    .stormReport {
        width: 100%;
        float: none;
        padding-bottom: 20px;
    }

    .estimateButton {
        width: 250px;
        height: 40px;
        background-color: rgb(182, 8,7);
        color: white;
        font-family: Bebas, serif;
        word-spacing: 5px;
        margin-top: 20px;
        margin-left: calc(50% - 125px);
        border-radius: 5px;
    }

    .estimateButton:hover {
        cursor: pointer;
    }

    .stormDamageFooter {
       display: none;
    }

    .stormDamageFooterText {
        float: right;
        font-family: FranklinGothicHeavy, serif;
        font-size: 64px;
        color: rgb(182,8,7);
        text-transform: uppercase;
        margin-right: 10px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .damageLink {
        color: rgb(182, 8, 7);
        margin-right: 20px;
    }

    .damageLink:hover {
        color: rgb(182, 8, 7);
    }

}
.image-input-container {
  width: 100%;
  border-bottom: 3px solid #707070;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-bottom: 25px; }
  .image-input-container .places-search-container {
    width: 100%; }
  .image-input-container .suggestion-item--active {
    width: 100%;
    min-height: 30px;
    text-indent: 2px;
    font: 16px Poppins, serif, sans-serif;
    background-color: #6a6a6a;
    text-overflow: ellipsis; }
  .image-input-container .suggestion-item {
    width: 100%;
    min-height: 30px;
    text-indent: 2px;
    font: 16px Poppins, serif, sans-serif;
    text-overflow: ellipsis; }
  .image-input-container .autocomplete-dropdown-container {
    width: 100%;
    z-index: 9999;
    background-color: lightgreen;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); }
  .image-input-container.error {
    border: 2px solid red; }
  .image-input-container .phone {
    width: 20px;
    height: 18px;
    margin-top: 24px;
    margin-left: 5px; }
  .image-input-container .email {
    width: 20px;
    height: 15px;
    margin-top: 26px;
    margin-left: 5px; }
  .image-input-container .firstName, .image-input-container .lastName, .image-input-container .fullName {
    width: 20px;
    height: 25px;
    margin-top: 20px;
    margin-left: 5px; }
  .image-input-container .code {
    width: 25px;
    margin-top: 21px;
    height: 25px; }
  .image-input-container .address, .image-input-container .city, .image-input-container .state, .image-input-container .zipCode {
    width: 20px;
    height: 27px;
    margin-top: 20px;
    margin-left: 5px; }
  .image-input-container input {
    border: none;
    height: 50px;
    margin-top: 6px;
    width: 100%;
    text-indent: 10px;
    font: 18px Poppins, serif, sans-serif; }
  .image-input-container input:focus {
    outline: none; }

@-webkit-keyframes modalFadeIn {
  from {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes modalFadeIn {
  from {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.roofDocsRed {
  color: #b70808; }

.animatedModal {
  -webkit-animation-name: modalFadeIn;
          animation-name: modalFadeIn;
  -webkit-animation-duration: .5s;
          animation-duration: .5s; }

.virus-disclaimer {
  width: 80%;
  margin: 50px auto 0 auto;
  padding-left: 15px;
  border-left: 4px solid #b70808;
  font: 28px PoppinsSemiBold, serif, sans-serif; }

.inspection-banner {
  background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px; }
  .inspection-banner img {
    width: 100%;
    height: 100%; }
  .inspection-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px); }

.inspection-report-container {
  width: 80%;
  margin: 100px auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly; }
  .inspection-report-container .header-text {
    font: 54px PoppinsSemiBold, serif, sans-serif;
    color: #b70808;
    margin-bottom: 0; }
  .inspection-report-container .header-info-text {
    font: 20px Poppins, serif, sans-serif;
    margin: 20px auto;
    color: #414040; }
  .inspection-report-container .inspection-container {
    width: 40%;
    float: left;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
  .inspection-report-container .report-container {
    width: 40%;
    float: right;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
  .inspection-report-container .schedule-inspection-container {
    width: 60%;
    min-width: 600px;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }

.confirm-close-button {
  background-color: red;
  color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  font: 20px Poppins, serif;
  width: 150px;
  margin-left: 75px;
  height: 50px;
  word-spacing: 5px;
  border-radius: 12px; }

.confirm-accept-button {
  background-color: #b70808;
  color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  font: 20px Poppins, serif;
  width: 150px;
  margin-right: 75px;
  height: 50px;
  word-spacing: 5px;
  border-radius: 12px; }

.inspection-banner-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .inspection-banner-text-container .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000; }
  .inspection-banner-text-container .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000; }

.report-submit-button {
  margin-top: auto;
  margin-left: calc(100% - 440px);
  width: 440px;
  height: 65px;
  background-color: #b70808;
  box-shadow: 0 0 10px #00000076;
  border-radius: 9px;
  border: 2px solid rgba(183, 8, 8, 0); }
  .report-submit-button:hover {
    cursor: pointer;
    border: 2px solid white; }
  .report-submit-button .report-submit-label {
    text-transform: uppercase;
    color: white;
    font: 22px PoppinsSemiBold, Serif, sans-serif;
    display: inline-block;
    line-height: 63px;
    margin-left: 15px; }
  .report-submit-button .report-submit-img {
    float: right;
    margin-right: 2px;
    margin-top: 1px;
    display: inline-block; }

/**** Process ****/
.inspection-work-item-container {
  width: 100%;
  padding-top: 10px;
  display: -ms-flexbox;
  display: flex; }

.inspection-work-item-icon-container {
  width: 100px; }
  .inspection-work-item-icon-container img {
    width: 90px;
    height: auto; }

.inspection-work-item-info-container {
  -ms-flex-positive: 1;
      flex-grow: 1; }
  .inspection-work-item-info-container .inspection-step-header {
    width: 100%;
    font: 36px PoppinsSemiBold, serif, sans-serif;
    color: #b70808;
    border-bottom: 1px solid red;
    padding-left: 10px; }
  .inspection-work-item-info-container .inspection-step-info {
    width: 100%;
    font: 20px Poppins, serif, sans-serif;
    padding-left: 10px; }

/****

 SMALL LAPTOPS

 */
@media all and (max-width: 1500px) and (min-width: 850px) {
  .inspection-report-container {
    width: 95%;
    margin: 50px auto; }
    .inspection-report-container .header-text {
      font: 42px PoppinsSemiBold, serif, sans-serif; }
    .inspection-report-container .header-info-text {
      font: 17px Poppins, serif, sans-serif;
      margin: 10px auto 20px auto; }
    .inspection-report-container .report-submit-button {
      margin-top: auto;
      margin-left: calc(100% - 340px);
      width: 340px;
      height: 65px;
      background-color: #b70808;
      box-shadow: 0 0 10px #00000076;
      border-radius: 9px;
      border: 2px solid rgba(183, 8, 8, 0); }
      .inspection-report-container .report-submit-button .report-submit-label {
        text-transform: uppercase;
        color: white;
        font: 16px PoppinsSemiBold, Serif, sans-serif;
        display: inline-block;
        line-height: 63px;
        margin-left: 15px; }
      .inspection-report-container .report-submit-button .report-submit-img {
        float: right;
        margin-right: 2px;
        margin-top: 1px;
        display: inline-block; }
  .inspection-banner-text-container {
    width: 100%; }
    .inspection-banner-text-container .banner-text-large {
      font: 42px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 849px) {
  .virus-disclaimer {
    font: 18px PoppinsSemiBold, serif, sans-serif; }
  .inspection-report-container {
    width: 80%;
    height: auto;
    margin: 50px auto;
    display: block; }
    .inspection-report-container .schedule-inspection-container {
      min-width: 350px; }
    .inspection-report-container .header-text {
      font: 42px PoppinsSemiBold, serif, sans-serif;
      color: #b70808;
      margin-bottom: 0; }
    .inspection-report-container .header-info-text {
      font: 20px Poppins, serif, sans-serif;
      margin: 10px auto;
      color: #414040; }
    .inspection-report-container .inspection-container {
      width: 100%;
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
    .inspection-report-container .report-container {
      float: inherit;
      padding-top: 50px;
      width: 100%; }
  .inspection-banner-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .inspection-banner-text-container .banner-text-small {
      text-transform: uppercase;
      color: #fff;
      font: 24px PoppinsBold, serif, sans-serif;
      text-align: center;
      margin-bottom: 0;
      text-shadow: 0 0 2px #000000; }
    .inspection-banner-text-container .banner-text-large {
      color: #fff;
      font: 42px PoppinsSemiBold, serif, sans-serif;
      text-align: center;
      text-shadow: 0 0 2px #000000; } }

@media all and (max-width: 550px) {
  .inspection-report-container {
    width: 95%; }
  .report-submit-button {
    margin-top: auto;
    margin-left: calc(50%  - 180px);
    width: 360px;
    height: 55px;
    background-color: #b70808;
    box-shadow: 0 0 10px #00000076;
    border-radius: 9px;
    border: 2px solid rgba(183, 8, 8, 0); }
    .report-submit-button .report-submit-label {
      text-transform: uppercase;
      color: white;
      font-size: 18px;
      display: inline-block;
      line-height: 53px;
      margin-left: 15px; }
    .report-submit-button .report-submit-img {
      height: 49px;
      width: 49px;
      float: right;
      margin-right: 2px;
      margin-top: 1px;
      display: inline-block; } }

.inspection-page-container { }

#estimateFormContainer {
    width: 70%;
    margin: 0 auto;
    height: calc(100% - 125px);
}

.estimateControlLabel {
    font-family: Bebas, serif;
    font-size: 15px;
    margin-bottom: 0 !important;
    word-spacing: 5px;
    width: 100%;
}

.estimateFooter {
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;

}

label.container {
    font-size: 15px;
}

.estimateFooterMainText {
    float: right;
    font-family: FranklinGothicHeavy, serif;
    font-size: 64px;
    color: rgb(182,8,7);
    text-transform: uppercase;
    margin-right: 30px;
    word-spacing: 10px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: right;
    width: 100%;

}

.estimateFooterSmallText {
    float: right;
    font-family: Bebas, serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
    margin-right: 30px;
    word-spacing: 5px;
    margin-top: -15px;
    text-align: right;
    margin-bottom: 5px;
}

.estimateImportant {
    color: rgb(182,8,7);
    font-size: 24px;
    margin-left: 5px;
    margin-right: 5px;
}

.formGroupText {
    width: 80%;
    display: inline-block;
    margin-left: 10%;
}

.formGroupCustom {
    width: 80%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 0 !important;
}

.descriptionTextArea {
    height: 125px;
}

.checkbox label {
    font-family: AgencyFB, serif;
    font-size: 18px;
}

.submitButton {
    background-color: rgb(182, 8, 7);
    color: white;
    font-family: Bebas, serif;
    font-size: 12px;
    width: 200px;
    margin-top: 20px;
    margin-left: calc(50% - 100px);
    word-spacing: 5px;
}

.formGroupInput {
    width: 95%;
}

/* Submitted Estimate */
.submittedEstimateContainer {
    width: 60%;
    margin: 20px auto;
    display: block;
    padding-bottom: 50px;
}

.submittedHeader {
    font-family: Bebas, serif;
    font-size: 24px;
    color: rgb(182, 8, 7);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.submittedInfo {
    font-size: 20px;
    text-align: center;
    color: black;
}

.hideEstimate {
    display: none;
}

.showEstimate {
    display: block;
}

.thankYouMessage {
    width: 90%;
    margin: 50px auto 50px;
    padding-top: 25px;
    text-align: center;
    font: 30px PoppinsMedium, serif, sans-serif;
    color: rgb(183, 8, 8);
}

.thankYouRepImage {
    width: 50%;
    display: block;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    -ms-flex-align: center;
        align-items: center;
    max-width: 600px;
    min-width: 250px;
    height: auto;

    margin: 20px auto;
}

.free-estimate-recapatcha-container {
    margin: 5px 10px 20px 10px;
    width: 50%;
}


@media screen and (max-width: 1000px){

    .freeEstimate {
       height: auto;
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    #estimateFormContainer {
        width: 80%;
        margin: 0 auto;
    }

    .estimateControlLabel {
        font-family: Bebas, serif;
        font-size: 18px;
        word-spacing: 5px;
        width: 100%;
    }

    .estimateFooter {
        display: none;

    }

    .formGroupText {
        width: 100%;
        display: inline-block;
    }

    .formGroupCustom {
        width: 80%;
        display: inline-block;
    }

    .descriptionTextArea {
        height: 125px;
    }

    .checkbox label {
        font-family: AgencyFB, serif;
        font-size: 18px;
    }

    .submitButton {
        background-color: rgb(182, 8, 7);
        color: white;
        font-family: Bebas, serif;
        font-size: 12px;
        width: 200px;
        margin-top: 20px;
        margin-left: calc(50% - 100px);
        word-spacing: 5px;
    }

    .formGroupInput {
        width: 95%
    }

    /* Submitted Estimate */
    .submittedEstimateContainer {
        width: 60%;
        margin: 20px auto;
        display: block;
        padding-bottom: 50px;
    }

    .submittedHeader {
        font-family: Bebas, serif;
        font-size: 24px;
        color: rgb(182, 8, 7);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .submittedInfo {
        font-size: 20px;
        text-align: center;
        color: black;
    }

    .hideEstimate {
        display: none;
    }

    .showEstimate {
        display: block;
    }

    .thankYouMessage {
        width: 90%;
        margin: 50px auto 50px;
        padding-top: 25px;
        text-align: center;
        font: 22px PoppinsMedium, serif, sans-serif;
        color: rgb(183, 8, 8);
    }

}
.image-text-area-container {
  width: 100%;
  border-bottom: 3px solid #707070;
  display: -ms-flexbox;
  display: flex;
  height: 153px;
  margin-bottom: 25px; }
  .image-text-area-container textarea {
    border: none;
    width: 100%;
    height: 150px;
    text-indent: 10px;
    font: 16px Poppins, serif, sans-serif; }
  .image-text-area-container textarea:focus {
    outline: none; }
  .image-text-area-container img {
    margin-top: 6px;
    width: 22px;
    height: 18px; }

/* Customize the label (the container) */
.container {
    display: inline-block;
    position: relative;
    padding-left: 25px !important;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 50%;
    /*padding-right: 0 !important;*/
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #DDDDDD;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    margin-top: 1px;
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    margin-top: 1px;
    background-color: rgb(182, 8, 7);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 7px;
    top: 1px;
    width: 5px;
    height: 15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

label.container {
    display: inline-block;
    width: auto;
    font: 14px Poppins, sans-serif, serif;
}
.image-multi-select-container {
  width: 100%;
  height: 90px;
  border-bottom: 3px solid #707070;
  margin-bottom: 30px; }

.image-multi-select-info {
  display: -ms-flexbox;
  display: flex; }
  .image-multi-select-info img {
    width: 22px;
    height: 18px;
    margin-top: 6px; }
  .image-multi-select-info p {
    color: #6a6a6a;
    width: 100%;
    margin-left: 13px;
    font: 16px Poppins, serif, sans-serif; }

.image-multi-select-selection {
  display: -ms-flexbox;
  display: flex; }

.date-input-container {
  width: 100%;
  border-bottom: 3px solid #707070;
  margin-bottom: 30px; }

.no-appointments-available-text {
  margin: 10px auto;
  font: 22px PoppinsSemiBold, serif, sans-serif;
  color: red; }

.schedule-date-help-text {
  margin-top: 15px;
  font: 20px PoppinsSemiBold, serif, sans-serif; }

.schedule-time-container {
  width: 405px;
  margin: 20px 0;
  height: 250px;
  overflow-y: auto;
  border: 2px solid #b70808;
  border-radius: 5px; }
  .schedule-time-container p {
    text-align: center;
    line-height: 40px !important;
    height: 40px;
    width: 100px;
    display: inline-block;
    float: left;
    font: 18px Poppins, serif, sans-serif;
    margin-bottom: 0; }
  .schedule-time-container .sel-time {
    background-color: #b70808;
    color: white; }
  .schedule-time-container p:hover {
    cursor: pointer;
    background-color: #b70808;
    color: white; }

.schedule-date-picker {
  width: 400px; }

.select-input-container {
  width: 100%;
  border-bottom: 3px solid #707070;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: 25px; }
  .select-input-container select {
    width: 100%;
    height: 50px;
    border: none;
    font: 18px Poppins, serif, sans-serif;
    text-indent: 10px; }
    .select-input-container select:focus {
      border: none;
      outline: none; }
  .select-input-container input {
    border: none;
    height: 50px;
    margin-top: 6px;
    width: 100%;
    text-indent: 10px;
    font: 18px Poppins, serif, sans-serif; }
    .select-input-container input:focus {
      border: none;
      outline: none; }

.drop-down-select-info {
  color: #6a6a6a;
  width: 100%;
  margin-left: 13px;
  font: 16px Poppins, serif, sans-serif; }
  .drop-down-select-info p {
    margin-bottom: 0; }

.footer-banner {
  background-color: black;
  position: relative;
  text-align: center;
  color: white;
  height: 550px;
  z-index: 9998; }
  .footer-banner img {
    width: 100%;
    height: 100%; }
  .footer-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    top: 0;
    left: calc(50% - 50px); }
  .footer-banner .footer-license-text {
    text-align: left;
    display: inline-block;
    color: white;
    font: 18px Poppins, sans-serif, Serif;
    float: left;
    margin-bottom: 0;
    margin-left: 25px;
    margin-top: 25px; }
    .footer-banner .footer-license-text.bottom {
      margin-top: 10px; }

.footer-copyright {
  width: 100%;
  height: 60px;
  background-color: black;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .footer-copyright p {
    color: white;
    line-height: 60px;
    margin-right: 15px;
    margin-bottom: 0;
    font: 14px Poppins, serif, sans-serif; }
  .footer-copyright .policy-text {
    color: white;
    font: 14px Poppins, serif, sans-serif; }
  .footer-copyright .policy-text:hover {
    text-decoration: underline !important;
    color: white; }

.footer-content {
  height: 65%;
  min-height: 200px;
  position: absolute;
  width: 80%;
  top: 20%;
  left: 10%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  /* SOCIAL */ }
  .footer-content .footer-contact {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -ms-flex-direction: column;
        flex-direction: column; }
    .footer-content .footer-contact .footer-logo-image {
      width: 60%;
      height: auto;
      margin-bottom: 0; }
    .footer-content .footer-contact .footer-contact-icon {
      width: 100%;
      height: 40px;
      float: left;
      margin-top: 10px; }
      .footer-content .footer-contact .footer-contact-icon.phone img {
        height: 20px;
        width: 20px; }
      .footer-content .footer-contact .footer-contact-icon.email img {
        height: 15px;
        width: 20px; }
      .footer-content .footer-contact .footer-contact-icon.map img {
        height: 20px;
        width: 15px; }
      .footer-content .footer-contact .footer-contact-icon img {
        float: left;
        margin-top: 5px;
        display: inline-block; }
      .footer-content .footer-contact .footer-contact-icon label {
        text-align: left;
        margin-left: 10px;
        display: inline-block;
        color: white;
        font: 18px Poppins, sans-serif, Serif;
        float: left;
        margin-bottom: 0; }
      .footer-content .footer-contact .footer-contact-icon label:hover {
        text-decoration: underline; }
  .footer-content .footer-services {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .footer-content .footer-services a {
      color: white; }
    .footer-content .footer-services h3 {
      font: 20px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 0; }
    .footer-content .footer-services p {
      float: left;
      font: 18px Poppins, serif, sans-serif;
      margin-bottom: 0;
      text-align: left; }
      .footer-content .footer-services p:hover {
        text-decoration: underline; }
    .footer-content .footer-services .service-icon {
      font: 18px PoppinsBold, serif, sans-serif;
      color: white;
      font-weight: bolder; }
  .footer-content .footer-social {
    max-width: 300px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
    .footer-content .footer-social .social-links {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
      height: 50px;
      width: 100%;
      margin-bottom: 50px; }
      .footer-content .footer-social .social-links img {
        height: 100%;
        width: auto; }
      .footer-content .footer-social .social-links img:hover {
        cursor: pointer; }
    .footer-content .footer-social .certs {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .footer-content .footer-social .certs img {
        max-width: 200px;
        height: auto; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1150px) and (min-width: 850px) {
  .footer-content {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
    .footer-content .footer-services {
      display: none; } }

/****************** TABLETS & MOBILE ****************/
@media all and (max-width: 849px) {
  .footer-banner {
    height: 650px; }
  .footer-content {
    -ms-flex-pack: center;
        justify-content: center; }
    .footer-content .footer-services {
      display: none; }
    .footer-content .footer-social {
      display: none; } }

.mobileAppsContainer {
    margin-top: 50px;
    width: 100%;
}

.appTitle {
    text-align: center;
    font-family: Bebas, serif;
    font-size: 24px;
    margin-top: 20px;
    word-spacing: 3px;
}

.mobileAppsContainer a {
    color: black;
}

.mobileAppsContainer a:hover {
    color: rgb(182, 8, 7);
}

.apps-password-input {
    width: 300px;
    height: 35px;
    margin-left: calc(50% - 150px);
    display: block;
}

.apps-submit-button {
    width: 120px;
    height: 35px;
    margin-top: 20px;
    margin-left: calc(50% - 60px);
    color: white;
    background-color: rgb(182,8, 7);
    border-radius: 5px;
}


@media screen and (max-width: 600px) {
    .mobileAppsContainer {
        margin-top: 300px;
    }
}


.notFoundContainer {
  width: 100%;
  height: calc(100vh - 280px);
  margin-top: 50px; }
  .notFoundContainer a {
    display: block;
    text-align: center;
    font: 18px Poppins, serif, sans-serif;
    margin: 5px auto;
    color: #b70808; }

.smallText-notFound {
  font: 22px PoppinsBold, serif, sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 100px; }

.largeText-notFound {
  font: 44px PoppinsSemiBold, serif, sans-serif;
  color: #b70808;
  text-align: center; }

.privacyPolicyContainer {
    margin-top: 50px;
}

.privacyPolicy {
    width: 80%;
    margin: 20px auto;
    overflow-y: scroll;
}

.privacyPolicyEffective {
    font-style: italic;
}

.privacyPolicyHeader {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.privacyPolicyHeaderSmall {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.privacyPolicyText {
    font-size: 16px;
}
.faq-container {
  padding-bottom: 20px; }

.black-text {
  color: black; }

.logo-text {
  color: #b70808; }

.faq-banner {
  background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px; }
  .faq-banner img {
    width: 100%;
    height: 100%; }
  .faq-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px); }

.faq-questions-content {
  width: 75%;
  margin: 50px auto; }

.question-container {
  border-bottom: 1px solid #5d5d5d;
  margin: 10px auto; }

.question-text {
  color: #b70808;
  display: inline-block;
  width: 90%;
  margin-bottom: 0;
  line-height: 100px !important;
  font: 32px PoppinsSemiBold, serif, sans-serif; }

.question-answer-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height .25s ease-in-out; }
  .question-answer-container.expanded {
    max-height: 1800px; }
  .question-answer-container img {
    max-height: 75%;
    max-width: 75%;
    margin: 0 12.5% 20px; }

.question-answer-text {
  margin: 18px auto 40px auto;
  font: 20px Poppins, serif, sans-serif;
  color: #414040; }

.faq-action-icon-image {
  width: 90px;
  height: 90px;
  display: inline-block;
  float: right; }

.faq-banner-text-container {
  position: absolute;
  top: 50%;
  width: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .faq-banner-text-container .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000; }
  .faq-banner-text-container .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000; }

.faq-question-header {
  width: 80%;
  margin: 20px auto;
  border-radius: 10px; }
  .faq-question-header :hover {
    cursor: pointer; }

.faq-question-title {
  font: 22px RobotoMedium, serif, sans-seif;
  width: 100%;
  line-height: 60px;
  padding-left: 15px;
  background-color: rgba(182, 8, 7, 0.75);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
  margin-bottom: 0; }

.faq-question-answer-container {
  width: 100%;
  background-color: #eee;
  border-radius: 10px;
  height: 0;
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
  overflow: hidden; }
  .faq-question-answer-container p {
    padding: 20px 0;
    margin-left: 10px; }

.faq-image {
  max-height: 75%;
  max-width: 75%;
  margin: 0 12.5% 20px 12.5%; }

.active {
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  height: auto;
  -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: top;
      -ms-transform-origin: top;
          transform-origin: top; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .faq-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; }
  .faq-questions-content {
    width: 95%; }
  .question-text {
    line-height: 100px !important;
    width: calc(100% - 100px);
    font: 24px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 849px) and (min-width: 620px) {
  .faq-banner-text-container {
    width: 100%; }
    .faq-banner-text-container .banner-text-small {
      font-size: 20px; }
    .faq-banner-text-container .banner-text-large {
      font: 34px PoppinsSemiBold, serif, sans-serif; }
  .faq-questions-content {
    width: 95%; }
  .question-text {
    line-height: 60px !important;
    width: calc(100% - 60px);
    font: 18px PoppinsSemiBold, serif, sans-serif; }
  .faq-action-icon-image {
    width: 60px;
    height: 60px;
    display: inline-block;
    float: right; }
  .question-answer-text {
    margin: 10px auto 20px auto;
    font: 16px Poppins, serif, sans-serif;
    color: #414040; } }

@media all and (max-width: 619px) {
  .faq-questions-content {
    margin: 25px auto; }
  .faq-banner-text-container {
    width: 100%; }
    .faq-banner-text-container .banner-text-small {
      font-size: 20px; }
    .faq-banner-text-container .banner-text-large {
      font: 34px PoppinsSemiBold, serif, sans-serif; }
  .question-text {
    line-height: 26px !important;
    font: 20px PoppinsSemiBold, serif, sans-serif; }
  .faq-action-icon-image {
    display: none; }
  .question-answer-text {
    margin: 5px auto 10px auto;
    font-size: 15px; } }

.mission-container {
  padding-bottom: 20px; }

.black-text {
  color: black; }

.logo-text {
  color: #b70808; }

.mission-banner {
  background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px; }
  .mission-banner img {
    width: 100%;
    height: 100%; }
  .mission-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px); }

.mission-banner-text-container {
  position: absolute;
  top: 50%;
  width: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .mission-banner-text-container .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000; }
  .mission-banner-text-container .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .mission-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 849px) and (min-width: 620px) {
  .mission-banner-text-container {
    width: 100%; }
    .mission-banner-text-container .banner-text-small {
      font-size: 20px; }
    .mission-banner-text-container .banner-text-large {
      font: 34px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 619px) {
  .mission-banner-text-container {
    width: 100%; }
    .mission-banner-text-container .banner-text-small {
      font-size: 20px; }
    .mission-banner-text-container .banner-text-large {
      font: 34px PoppinsSemiBold, serif, sans-serif; } }

.values-container {
  padding-bottom: 20px; }

.black-text {
  color: black; }

.logo-text {
  color: #b70808; }

.values-banner {
  background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px; }
  .values-banner img {
    width: 100%;
    height: 100%; }
  .values-banner .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px); }

.values-banner-text-container {
  position: absolute;
  top: 50%;
  width: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .values-banner-text-container .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 0 2px #000000; }
  .values-banner-text-container .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .values-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 849px) and (min-width: 620px) {
  .values-banner-text-container {
    width: 100%; }
    .values-banner-text-container .banner-text-small {
      font-size: 20px; }
    .values-banner-text-container .banner-text-large {
      font: 34px PoppinsSemiBold, serif, sans-serif; } }

@media all and (max-width: 619px) {
  .values-banner-text-container {
    width: 100%; }
    .values-banner-text-container .banner-text-small {
      font-size: 20px; }
    .values-banner-text-container .banner-text-large {
      font: 34px PoppinsSemiBold, serif, sans-serif; } }

.services-container {
  padding-bottom: 20px; }
  .services-container .services-banner {
    background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px; }
    .services-container .services-banner .services-banner-text-container {
      position: absolute;
      top: 50%;
      width: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .services-container .services-banner .services-banner-text-container .banner-text-small {
        text-transform: uppercase;
        color: white;
        font: 24px PoppinsBold, serif, sans-serif;
        text-align: center;
        margin-bottom: 0;
        text-shadow: 0 0 10px #000000; }
      .services-container .services-banner .services-banner-text-container .banner-text-large {
        color: white;
        font: 56px PoppinsSemiBold, serif, sans-serif;
        text-align: center;
        text-shadow: 0 0 10px #000000; }
    .services-container .services-banner .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px); }

.services-template-content {
  width: 80%;
  margin: 50px auto; }
  .services-template-content h1 {
    color: #b70808;
    font: 48px PoppinsSemiBold, serif, sans-serif; }
  .services-template-content p.main-text {
    width: 100%;
    font: 20px Poppins, serif, sans-serif; }
  .services-template-content h2 {
    color: #b70808;
    font: 36px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px; }
  .services-template-content p {
    width: 100%;
    font: 18px Poppins, serif, sans-serif; }
  .services-template-content h3 {
    color: #b70808;
    font: 30px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px; }

.services-template-container-inline .services-template-content {
  width: 60%;
  margin: 50px; }
  .services-template-container-inline .services-template-content h1 {
    color: #b70808;
    font: 48px PoppinsSemiBold, serif, sans-serif; }
  .services-template-container-inline .services-template-content p.main-text {
    width: 100%;
    font: 20px Poppins, serif, sans-serif; }
  .services-template-container-inline .services-template-content h2 {
    color: #b70808;
    font: 36px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px; }
  .services-template-container-inline .services-template-content p {
    width: 100%;
    font: 18px Poppins, serif, sans-serif; }
  .services-template-container-inline .services-template-content h3 {
    color: #b70808;
    font: 30px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px; }

.services-template-container-inline .services-template-form-container {
  margin: 50px auto;
  width: 30%;
  position: fixed;
  right: 0;
  top: calc(50% - 300px);
  border: 2px solid #b70808;
  border-right: none;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px; }

/****************** SMALL COMPUTERS & TABLETS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .services-container .services-banner .services-banner-text-container .banner-text-large {
    color: white;
    font: 42px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 10px #000000; }
  .services-template-content h1 {
    font: 48px PoppinsSemiBold, serif, sans-serif; }
  .services-template-content p.main-text {
    width: 100%;
    font: 22px Poppins, serif, sans-serif; }
  .services-template-content h2 {
    color: #b70808;
    font: 36px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px; }
  .services-template-content p {
    width: 100%;
    font: 18px Poppins, serif, sans-serif; }
  .services-template-content li {
    width: 100%;
    font: 16px Poppins, serif, sans-serif; } }

/***************** TABLETS AND SMALLER **********/
@media all and (max-width: 849px) {
  .services-container .services-banner .services-banner-text-container {
    width: 100%; }
    .services-container .services-banner .services-banner-text-container .banner-text-small {
      font-size: 20px; }
    .services-container .services-banner .services-banner-text-container .banner-text-large {
      font-size: 34px; }
  .services-template-content h1 {
    font: 28px PoppinsSemiBold, serif, sans-serif; }
  .services-template-content p.main-text {
    width: 100%;
    font: 19px Poppins, serif, sans-serif; }
  .services-template-content h2 {
    color: #b70808;
    font: 26px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px; }
  .services-template-content p {
    width: 100%;
    font: 16px Poppins, serif, sans-serif; }
  .services-template-content li {
    width: 100%;
    font: 14px Poppins, serif, sans-serif; } }

.blog-container {
  padding-bottom: 20px; }
  .blog-container .blog-banner {
    background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px; }
    .blog-container .blog-banner .blog-banner-text-container {
      position: absolute;
      top: 50%;
      width: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .blog-container .blog-banner .blog-banner-text-container .banner-text-small {
        text-transform: uppercase;
        color: white;
        font: 24px PoppinsBold, serif, sans-serif;
        text-align: center;
        margin-bottom: 0;
        text-shadow: 0 0 10px #000000; }
      .blog-container .blog-banner .blog-banner-text-container .banner-text-large {
        color: white;
        font: 56px PoppinsSemiBold, serif, sans-serif;
        text-align: center;
        text-shadow: 0 0 10px #000000; }
    .blog-container .blog-banner .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px); }

.blog-content {
  width: 80%;
  margin: 50px auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center; }

.blog-post-card {
  width: 275px;
  height: 400px;
  border-radius: 8px;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 20px auto; }
  .blog-post-card:hover {
    background-color: #D3D3D3;
    cursor: pointer; }
  .blog-post-card .title {
    font: 28px PoppinsMedium, serif, sans-serif;
    color: #b70808;
    text-align: center;
    width: 80%; }

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .blog-post-card {
    width: 355px;
    height: 250px; }
    .blog-post-card:hover {
      background-color: #D3D3D3;
      cursor: pointer; }
    .blog-post-card .title {
      font: 28px PoppinsMedium, serif, sans-serif;
      color: #b70808;
      text-align: center;
      width: 80%; }
  .blog-container .blog-banner .blog-banner-text-container .banner-text-large {
    font: 42px PoppinsSemiBold, serif, sans-serif; } }

/***************** TABLETS AND SMALLER **********/
@media all and (max-width: 849px) {
  .blog-container .blog-banner .blog-banner-text-container {
    width: 100%; }
    .blog-container .blog-banner .blog-banner-text-container .banner-text-small {
      font-size: 20px; }
    .blog-container .blog-banner .blog-banner-text-container .banner-text-large {
      font-size: 34px; } }

.blog-template-container {
  padding-bottom: 20px; }
  .blog-template-container .blog-template-banner {
    background: transparent url(/static/media/background_banner_red.66b94145.png) center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px; }
    .blog-template-container .blog-template-banner .blog-template-text-container {
      position: absolute;
      top: 50%;
      width: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .blog-template-container .blog-template-banner .blog-template-text-container .banner-text-small {
        text-transform: uppercase;
        color: white;
        font: 24px PoppinsBold, serif, sans-serif;
        text-align: center;
        margin-bottom: 0;
        text-shadow: 0 0 10px #000000; }
      .blog-template-container .blog-template-banner .blog-template-text-container .banner-text-large {
        color: white;
        font: 56px PoppinsSemiBold, serif, sans-serif;
        text-align: center;
        text-shadow: 0 0 10px #000000; }
    .blog-template-container .blog-template-banner .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px); }
  .blog-template-container .blog-template-content-container .blog-template-content {
    width: 80%;
    margin: 50px auto; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container {
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      margin-top: 50px;
      -ms-flex-direction: column;
          flex-direction: column; }
      .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .long-form-image {
        margin: 10px auto;
        width: 100%;
        height: auto; }
      .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .long-form-image-clickable {
        margin: 10px auto;
        width: 100%;
        height: auto; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .long-form-image-clickable:hover {
          cursor: pointer; }
      .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container {
        -ms-flex-positive: 1;
            flex-grow: 1;
        width: 100%;
        margin: 10px auto; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-item-info-container-controls {
          -ms-flex-positive: 1;
              flex-grow: 1;
          width: 100%;
          margin: 20px auto;
          height: 15px; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .arrow-left:before {
          content: '\25C4'; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .arrow-right:after {
          content: '\25BA'; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-home-button {
          text-align: center;
          cursor: pointer;
          background-color: white;
          border: none;
          display: block;
          color: #b70808;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid #b70808; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-home-button:focus {
            outline: none; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-previous-button {
          text-align: center;
          cursor: pointer;
          float: left;
          background-color: white;
          border: none;
          display: block;
          color: #b70808;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid #b70808; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-previous-button:focus {
            outline: none; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-next-button {
          text-align: center;
          cursor: pointer;
          float: right;
          background-color: white;
          border: none;
          display: block;
          color: #b70808;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid #b70808; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .blog-next-button:focus {
            outline: none; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container h1 {
          color: #b70808;
          font: 48px PoppinsSemiBold, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container p.main-text {
          width: 100%;
          font: 22px Poppins, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container h2 {
          color: #b70808;
          font: 36px PoppinsSemiBold, serif, sans-serif;
          margin-bottom: 5px; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container p {
          width: 100%;
          font: 18px Poppins, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .iws-image {
          width: 600px;
          max-width: 90%;
          margin: 10px auto 20px auto;
          display: block; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container li {
          width: 100%;
          font: 16px Poppins, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .iws-calculator-container {
          width: 100%;
          margin: 10px auto 20px auto; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .iws-calculator-container .iws-calculator-field-container {
            width: 100%;
            margin: 5px auto;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
                flex-direction: column; }
            .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .iws-calculator-container .iws-calculator-field-container label {
              font: 15px PoppinsSemiBold, serif, sans-serif;
              margin-bottom: 0;
              text-align: center; }
            .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .iws-calculator-container .iws-calculator-field-container input {
              width: 90%;
              height: 35px;
              font: 14px Poppins, serif, sans-serif;
              max-width: 300px;
              border: 1px solid lightgray;
              border-radius: 5px;
              text-indent: 5px;
              margin: 0 auto; }
            .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container .iws-calculator-container .iws-calculator-field-container select {
              width: 90%;
              height: 35px;
              font: 14px Poppins, serif, sans-serif;
              max-width: 300px;
              border: 1px solid lightgray;
              border-radius: 5px;
              margin: 0 auto; }
      .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full {
        -ms-flex-positive: 1;
            flex-grow: 1;
        width: 80%;
        margin: 20px auto; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .arrow-left:before {
          content: '\25C4'; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .arrow-right:after {
          content: '\25BA'; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .blog-home-button {
          text-align: center;
          cursor: pointer;
          background-color: white;
          border: none;
          display: block;
          color: #b70808;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid #b70808;
          float: left; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .blog-home-button:focus {
            outline: none; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .blog-next-button {
          text-align: center;
          cursor: pointer;
          background-color: white;
          border: none;
          display: block;
          color: #b70808;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid #b70808;
          float: right; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .blog-next-button:focus {
            outline: none; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .blog-previous-button {
          text-align: center;
          cursor: pointer;
          background-color: white;
          border: none;
          float: left;
          display: block;
          color: #b70808;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid #b70808; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .blog-previous-button:focus {
            outline: none; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full h1 {
          color: #b70808;
          font: 48px PoppinsSemiBold, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full p.main-text {
          width: 100%;
          font: 22px Poppins, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full h2 {
          color: #b70808;
          font: 36px PoppinsSemiBold, serif, sans-serif;
          margin-bottom: 5px; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full p {
          width: 100%;
          font: 18px Poppins, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .iws-image {
          width: 600px;
          max-width: 90%;
          margin: 10px auto 20px auto;
          display: block; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full li {
          width: 100%;
          font: 16px Poppins, serif, sans-serif; }
        .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .iws-calculator-container {
          width: 100%;
          margin: 10px auto 20px auto; }
          .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .iws-calculator-container .iws-calculator-field-container {
            width: 100%;
            margin: 5px auto;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
                flex-direction: column; }
            .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .iws-calculator-container .iws-calculator-field-container label {
              font: 15px PoppinsSemiBold, serif, sans-serif;
              margin-bottom: 0;
              text-align: center; }
            .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .iws-calculator-container .iws-calculator-field-container input {
              width: 90%;
              height: 35px;
              font: 14px Poppins, serif, sans-serif;
              max-width: 300px;
              border: 1px solid lightgray;
              border-radius: 5px;
              text-indent: 5px;
              margin: 0 auto; }
            .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full .iws-calculator-container .iws-calculator-field-container select {
              width: 90%;
              height: 35px;
              font: 14px Poppins, serif, sans-serif;
              max-width: 300px;
              border: 1px solid lightgray;
              border-radius: 5px;
              margin: 0 auto; }

/****************** SMALL COMPUTERS & TABLETS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .blog-template-container .blog-template-banner .blog-template-text-container .banner-text-large {
    color: white;
    font: 42px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 10px #000000; }
  .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container {
    -ms-flex-positive: 1;
        flex-grow: 1; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container h1 {
      font: 48px PoppinsSemiBold, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container p.main-text {
      width: 100%;
      font: 22px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container h2 {
      color: #b70808;
      font: 36px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container p {
      width: 100%;
      font: 18px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container li {
      width: 100%;
      font: 16px Poppins, serif, sans-serif; }
  .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full {
    -ms-flex-positive: 1;
        flex-grow: 1; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full h1 {
      font: 48px PoppinsSemiBold, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full p.main-text {
      width: 100%;
      font: 22px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full h2 {
      color: #b70808;
      font: 36px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full p {
      width: 100%;
      font: 18px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full li {
      width: 100%;
      font: 16px Poppins, serif, sans-serif; } }

/***************** TABLETS AND SMALLER **********/
@media all and (max-width: 849px) {
  .blog-template-container .blog-template-banner .blog-template-text-container {
    width: 100%; }
    .blog-template-container .blog-template-banner .blog-template-text-container .banner-text-small {
      font-size: 20px; }
    .blog-template-container .blog-template-banner .blog-template-text-container .banner-text-large {
      font-size: 34px; }
  .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container {
    -ms-flex-positive: 1;
        flex-grow: 1; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container h1 {
      font: 28px PoppinsSemiBold, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container p.main-text {
      width: 100%;
      font: 19px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container h2 {
      color: #b70808;
      font: 26px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container p {
      width: 100%;
      font: 16px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container li {
      width: 100%;
      font: 14px Poppins, serif, sans-serif; }
  .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full {
    -ms-flex-positive: 1;
        flex-grow: 1; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full h1 {
      font: 28px PoppinsSemiBold, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full p.main-text {
      width: 100%;
      font: 19px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full h2 {
      color: #b70808;
      font: 26px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full p {
      width: 100%;
      font: 16px Poppins, serif, sans-serif; }
    .blog-template-container .blog-template-content-container .blog-template-content .blog-item-container .blog-item-info-container-full li {
      width: 100%;
      font: 14px Poppins, serif, sans-serif; } }

.defective-shingles-container {
  padding-bottom: 20px; }
  .defective-shingles-container .logo-home-link {
    width: 100px;
    height: auto;
    position: absolute;
    top: 5px;
    left: calc(50% - 50px); }
    .defective-shingles-container .logo-home-link:hover {
      cursor: pointer; }
  .defective-shingles-container .program-gray {
    color: #5c5c5c; }
  .defective-shingles-container .program-name-banner {
    width: 100%;
    height: 500px; }
    .defective-shingles-container .program-name-banner .banner-text-content {
      position: absolute;
      top: 10%;
      width: 60%;
      left: 20%; }
      .defective-shingles-container .program-name-banner .banner-text-content p {
        font: 72px ReneBiederExtraBold, serif, sans-serif;
        text-align: center;
        text-transform: uppercase;
        color: white;
        box-shadow: 2px 2px 2px #00000076; }
    .defective-shingles-container .program-name-banner .banner-left-content {
      height: 100%;
      width: 50%;
      float: left; }
      .defective-shingles-container .program-name-banner .banner-left-content img {
        width: 100%;
        height: 100%; }
    .defective-shingles-container .program-name-banner .banner-right-content {
      height: 100%;
      width: 50%;
      float: left; }
      .defective-shingles-container .program-name-banner .banner-right-content img {
        width: 100%;
        height: 100%; }
    .defective-shingles-container .program-name-banner button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      position: relative;
      bottom: 30px;
      left: calc(50% - 175px);
      background-color: #b70808;
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076; }
      .defective-shingles-container .program-name-banner button:hover {
        cursor: pointer; }
      .defective-shingles-container .program-name-banner button:focus {
        outline: none;
        border: none; }
  .defective-shingles-container .defective-shingles-content {
    width: 100%; }
    .defective-shingles-container .defective-shingles-content .learn-more {
      width: 100%; }
      .defective-shingles-container .defective-shingles-content .learn-more p.main-text {
        width: 40%;
        margin: 0 auto;
        padding-top: 100px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif; }
      .defective-shingles-container .defective-shingles-content .learn-more p.sub-text {
        width: 60%;
        margin: 40px auto 90px;
        text-align: center;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .storm-activity {
      background-color: #e2e2e2;
      width: 100%; }
      .defective-shingles-container .defective-shingles-content .storm-activity p.start-text {
        width: 60%;
        margin: 0 auto;
        padding-top: 50px;
        text-align: center;
        font: 17px Lato,  serif, sans-serif;
        line-height: 24px !important; }
      .defective-shingles-container .defective-shingles-content .storm-activity p.main-text {
        width: 55%;
        margin: 0 auto;
        padding-top: 50px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif; }
      .defective-shingles-container .defective-shingles-content .storm-activity p.sub-text {
        width: 60%;
        margin: 0 auto;
        padding: 40px 0 250px 0;
        text-align: center;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .perform-inspection {
      margin-top: -200px;
      padding-bottom: 100px; }
      .defective-shingles-container .defective-shingles-content .perform-inspection img {
        width: 70%;
        display: block;
        -ms-flex-line-pack: center;
            align-content: center;
        margin: 0 auto; }
      .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container {
        width: 70%;
        margin: 50px auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-pack: justify;
            justify-content: space-between; }
        .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .left {
          background-color: #b70808;
          color: white;
          max-width: 45%;
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1 1;
              flex: 1 1; }
          .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .left p {
            width: 90%;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: auto;
            text-align: center;
            font: 16px Lato, serif, sans-serif;
            line-height: 24px !important; }
        .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .right {
          background-color: black;
          color: white;
          max-width: 45%;
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1 1;
              flex: 1 1; }
          .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .right p {
            width: 90%;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: auto;
            text-align: center;
            font: 16px Lato, serif, sans-serif;
            line-height: 24px !important; }
      .defective-shingles-container .defective-shingles-content .perform-inspection p.main-text {
        width: 55%;
        margin: 0 auto;
        padding-top: 50px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif; }
      .defective-shingles-container .defective-shingles-content .perform-inspection p.sub-text {
        width: 60%;
        margin: 0 auto;
        padding: 50px 0 50px 0;
        text-align: center;
        line-height: 1.2em;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important; }
      .defective-shingles-container .defective-shingles-content .perform-inspection button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        display: block;
        margin: 30px auto;
        background-color: #b70808;
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076; }
        .defective-shingles-container .defective-shingles-content .perform-inspection button:hover {
          cursor: pointer; }
        .defective-shingles-container .defective-shingles-content .perform-inspection button:focus {
          outline: none;
          border: none; }
    .defective-shingles-container .defective-shingles-content .opportunity-content {
      background-color: #e2e2e2; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .check-image {
        max-height: 300px;
        display: block;
        -ms-flex-line-pack: center;
            align-content: center;
        margin: 50px auto;
        position: relative;
        top: -140px;
        vertical-align: middle;
        -webkit-transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
                transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
        box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
        border-radius: 2px; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-line-pack: justify;
            align-content: space-between;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        padding-bottom: 40px;
        margin-top: -130px; }
        .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content {
          max-width: 65%;
          -ms-flex: 1 1;
              flex: 1 1;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content p.main-text {
            width: 80%;
            margin: 0 auto;
            padding-top: 0;
            text-align: right;
            font: 30px ReneBiederExtraBold,  serif, sans-serif; }
          .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content p.sub-text {
            width: 80%;
            margin: 0 auto;
            padding-top: 30px;
            text-align: right;
            font: 16px Lato,  serif, sans-serif;
            line-height: 24px !important; }
        .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .rep {
          max-width: 30%;
          -ms-flex: 1 1;
              flex: 1 1; }
          .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .rep img {
            width: 90%;
            display: block;
            -ms-flex-line-pack: center;
                align-content: center; }
    .defective-shingles-container .defective-shingles-content .inspection-request .call-text-button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      position: relative;
      bottom: 30px;
      left: 10%;
      background-color: #b70808;
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076; }
      .defective-shingles-container .defective-shingles-content .inspection-request .call-text-button:focus {
        outline: none;
        border: none; }
    .defective-shingles-container .defective-shingles-content .inspection-request p.sub-text {
      width: 70%;
      margin: 0 auto;
      padding: 10px 0 0 0;
      text-align: center;
      font: 16px Lato,  serif, sans-serif;
      line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .inspection-request p.main-text {
      width: 70%;
      margin: 0 auto;
      padding-top: 30px;
      text-align: center;
      font: 30px ReneBiederExtraBold,  serif, sans-serif; }
    .defective-shingles-container .defective-shingles-content .inspection-request .request-form {
      width: 80%;
      margin: 20px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
    .defective-shingles-container .defective-shingles-content .inspection-request .schedule-button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      margin-left: calc(50% - 175px);
      margin-top: 30px;
      position: revert;
      background-color: black;
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076; }
      .defective-shingles-container .defective-shingles-content .inspection-request .schedule-button:hover {
        cursor: pointer; }
    .defective-shingles-container .defective-shingles-content .inspection-request .required-text {
      width: 70%;
      margin: 0 auto;
      padding: 30px 0;
      text-align: center;
      font: 15px Lato,  serif, sans-serif; }

.bold {
  font-weight: bolder; }

@media all and (max-width: 750px) {
  .defective-shingles-container .program-name-banner .banner-text-content {
    position: absolute;
    top: 10%;
    width: 90%;
    left: 5%; }
    .defective-shingles-container .program-name-banner .banner-text-content p {
      font: 48px ReneBiederExtraBold, serif, sans-serif; }
  .defective-shingles-container .program-name-banner .banner-left-content {
    display: none;
    height: 100%;
    width: 50%;
    float: left;
    background: transparent url(/static/media/Horizon1.94623bea.png) center center repeat padding-box; }
  .defective-shingles-container .program-name-banner .banner-right-content {
    height: 100%;
    width: 100%;
    float: left;
    background: transparent url(/static/media/Horizong.3a07c933.png) center center repeat padding-box; }
  .defective-shingles-container .program-name-banner button {
    width: 300px;
    left: calc(50% - 150px);
    font: 14px ReneBiederExtraBold, serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .learn-more p.main-text {
    width: 80%;
    margin: 0 auto;
    padding-top: 75px;
    text-align: center;
    font: 22px ReneBiederExtraBold,  serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .learn-more p.sub-text {
    width: 80%;
    margin: 20px auto 50px;
    text-align: center;
    font: 16px Lato,  serif, sans-serif;
    line-height: 24px !important; }
  .defective-shingles-container .defective-shingles-content .storm-activity p.start-text {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    text-align: center;
    font: 17px Lato,  serif, sans-serif;
    line-height: 24px !important; }
  .defective-shingles-container .defective-shingles-content .storm-activity p.main-text {
    width: 80%;
    padding-top: 40px;
    font: 22px ReneBiederExtraBold,  serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .storm-activity p.sub-text {
    width: 80%;
    padding-bottom: 150px; }
  .defective-shingles-container .defective-shingles-content .perform-inspection {
    margin-top: -100px;
    padding-bottom: 20px; }
    .defective-shingles-container .defective-shingles-content .perform-inspection img {
      width: 90%;
      display: block;
      -ms-flex-line-pack: center;
          align-content: center;
      margin: 0 auto; }
    .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container {
      width: 90%;
      margin: 50px auto 10px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .left {
        background-color: #b70808;
        color: white;
        max-width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1;
        margin-bottom: 20px; }
        .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .left p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font: 16px Lato, serif, sans-serif;
          line-height: 24px !important; }
      .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .right {
        background-color: black;
        color: white;
        max-width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1; }
        .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .right p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font: 16px Lato, serif, sans-serif;
          line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .perform-inspection p.main-text {
      width: 80%;
      margin: 0 auto;
      padding-top: 20px;
      text-align: center;
      font: 22px ReneBiederExtraBold,  serif, sans-serif; }
    .defective-shingles-container .defective-shingles-content .perform-inspection p.sub-text {
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 20px 0;
      text-align: center;
      line-height: 1.2em;
      font: 16px Lato,  serif, sans-serif;
      line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .perform-inspection button {
      width: 300px;
      text-transform: uppercase;
      font: 14px ReneBiederExtraBold, serif, sans-serif;
      margin: 10px auto; }
      .defective-shingles-container .defective-shingles-content .perform-inspection button:hover {
        cursor: pointer; }
      .defective-shingles-container .defective-shingles-content .perform-inspection button:focus {
        outline: none;
        border: none; }
  .defective-shingles-container .defective-shingles-content .opportunity-content .check-image {
    width: 90%;
    max-width: 800px;
    display: block;
    -ms-flex-line-pack: center;
        align-content: center;
    margin: 50px auto;
    position: relative;
    top: -50px;
    left: -10px;
    -webkit-transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
            transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
    box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
    border-radius: 2px; }
  .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-line-pack: justify;
        align-content: space-between;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding-bottom: 40px; }
    .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content {
      max-width: 80%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content p.main-text {
        width: 90%;
        margin: 0 auto;
        padding-top: 70px;
        text-align: center;
        font: 22px ReneBiederExtraBold,  serif, sans-serif; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content p.sub-text {
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
        text-align: center; }
    .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .rep {
      max-width: 90%;
      -ms-flex: 1 1;
          flex: 1 1; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .rep img {
        margin: 0 auto;
        width: 90%;
        display: block;
        -ms-flex-line-pack: center;
            align-content: center; }
  .defective-shingles-container .defective-shingles-content .inspection-request .call-text-button {
    width: 300px;
    font: 14px ReneBiederExtraBold, serif, sans-serif;
    height: 60px;
    position: relative;
    bottom: 30px;
    left: calc(50% - 150px); }
  .defective-shingles-container .defective-shingles-content .inspection-request p.sub-text {
    width: 80%;
    margin: 0 auto;
    padding: 0 0 0 0;
    text-align: center;
    font: 16px Lato,  serif, sans-serif;
    line-height: 24px !important; }
  .defective-shingles-container .defective-shingles-content .inspection-request p.main-text {
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
    font: 30px ReneBiederExtraBold,  serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .inspection-request .request-form {
    width: 90%;
    margin: 20px auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
  .defective-shingles-container .defective-shingles-content .inspection-request .schedule-button {
    width: 350px;
    text-transform: uppercase;
    font: 16px ReneBiederExtraBold, serif, sans-serif;
    height: 60px;
    margin-left: calc(50% - 175px);
    margin-top: 30px;
    position: revert;
    background-color: black;
    color: white;
    border: none;
    box-shadow: 2px 2px 2px #00000076; }
    .defective-shingles-container .defective-shingles-content .inspection-request .schedule-button:hover {
      cursor: pointer; }
  .defective-shingles-container .defective-shingles-content .inspection-request .required-text {
    width: 70%;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    font: 15px Lato, serif, sans-serif; } }

.program-input-container {
  border-bottom: 2px solid #dbdbdb;
  height: 50px; }
  .program-input-container label {
    text-indent: 5px;
    font: 18px LatoBold, serif, sans-serif;
    text-transform: uppercase;
    margin-bottom: 0;
    height: 48px;
    position: absolute;
    -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(0, 16px) scale(1);
        -ms-transform: translate(0, 16px) scale(1);
            transform: translate(0, 16px) scale(1);
    transition: all .3s ease-in-out;
    overflow: hidden; }
    .program-input-container label:hover {
      cursor: pointer; }
  .program-input-container input {
    height: 48px;
    width: 100%;
    border: none; }
    .program-input-container input:focus {
      border: none;
      outline: none; }
  .program-input-container.firstName {
    width: 20%; }
  .program-input-container.lastName {
    width: 20%; }
  .program-input-container.address {
    width: 50%;
    z-index: 999; }
  .program-input-container.email {
    width: 30%; }
  .program-input-container.phone {
    width: 30%; }

@media all and (max-width: 750px) {
  .program-input-container.firstName {
    width: 100%; }
  .program-input-container.lastName {
    width: 100%; }
  .program-input-container.address {
    width: 100%; }
  .program-input-container.email {
    width: 100%; }
  .program-input-container.phone {
    width: 100%; } }

.program-date-time-input-container {
  border-bottom: 2px solid #dbdbdb;
  height: 50px;
  width: 30%; }
  .program-date-time-input-container label {
    text-indent: 5px;
    font: 18px LatoBold, serif, sans-serif;
    text-transform: uppercase;
    margin-bottom: 0;
    height: 48px;
    position: absolute;
    -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(0, 16px) scale(1);
        -ms-transform: translate(0, 16px) scale(1);
            transform: translate(0, 16px) scale(1);
    transition: all .1s ease-in-out; }
    .program-date-time-input-container label:hover {
      cursor: pointer; }
  .program-date-time-input-container .program-date-picker-container {
    width: 100%;
    height: 48px; }
    .program-date-time-input-container .program-date-picker-container .program-date-picker {
      width: 100%;
      height: 100%;
      border: none;
      outline: none; }
      .program-date-time-input-container .program-date-picker-container .program-date-picker .react-date-picker__wrapper {
        border: none; }
        .program-date-time-input-container .program-date-picker-container .program-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup__input {
          font: 16px Lato, serif, sans-serif; }
    .program-date-time-input-container .program-date-picker-container .selected-time-label {
      margin-bottom: 0;
      position: relative;
      left: 100px;
      top: -35px;
      max-width: 100px;
      font: 16px Lato, serif, sans-serif; }

.program-schedule-time-container {
  width: 405px;
  margin: 20px auto;
  height: 250px;
  overflow-y: auto;
  border: 2px solid #b70808;
  border-radius: 5px; }
  .program-schedule-time-container p {
    text-align: center;
    line-height: 40px !important;
    height: 40px;
    width: 100px;
    display: inline-block;
    float: left;
    font: 18px Poppins, serif, sans-serif;
    margin-bottom: 0; }
  .program-schedule-time-container .sel-time {
    background-color: #b70808;
    color: white; }
  .program-schedule-time-container p:hover {
    cursor: pointer;
    background-color: #b70808;
    color: white; }

@media all and (max-width: 750px) {
  .program-date-time-input-container {
    width: 100%; } }

@media all and (max-width: 600px) {
  .program-schedule-time-container {
    width: 90%;
    margin: 20px auto;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    height: auto; }
    .program-schedule-time-container p {
      text-align: center;
      line-height: 40px !important;
      height: 40px;
      width: 25%;
      min-width: 90px;
      font: 16px Poppins, serif, sans-serif;
      margin-bottom: 0; }
    .program-schedule-time-container .sel-time {
      background-color: #b70808;
      color: white; }
    .program-schedule-time-container p:hover {
      cursor: pointer;
      background-color: #b70808;
      color: white; } }

.loader-container {
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  z-index: 99999;
  background-color: black;
  padding: 0; }
  .loader-container .loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #b70808;
    /* Red */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-top: calc(50vh - 60px);
    margin-left: calc(50% - 60px);
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.doc-landing-container {
  padding-bottom: 20px; }
  .doc-landing-container .logo-home-link {
    width: 100px;
    height: auto;
    position: absolute;
    top: 5px;
    left: calc(50% - 50px); }
    .doc-landing-container .logo-home-link:hover {
      cursor: pointer; }
  .doc-landing-container .program-gray {
    color: #5c5c5c; }
  .doc-landing-container .doc-landing-banner {
    width: 100%;
    height: 500px; }
    .doc-landing-container .doc-landing-banner .banner-text-content {
      position: absolute;
      top: 10%;
      width: 60%;
      left: 20%; }
      .doc-landing-container .doc-landing-banner .banner-text-content p {
        font: 72px ReneBiederExtraBold, serif, sans-serif;
        text-align: center;
        text-transform: uppercase;
        color: white;
        box-shadow: 2px 2px 2px #00000076; }
    .doc-landing-container .doc-landing-banner .banner-left-content {
      height: 100%;
      width: 50%;
      float: left; }
      .doc-landing-container .doc-landing-banner .banner-left-content img {
        width: 100%;
        height: 100%; }
    .doc-landing-container .doc-landing-banner .banner-right-content {
      height: 100%;
      width: 50%;
      float: left; }
      .doc-landing-container .doc-landing-banner .banner-right-content img {
        width: 100%;
        height: 100%; }
    .doc-landing-container .doc-landing-banner button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      position: relative;
      bottom: 30px;
      left: calc(50% - 175px);
      background-color: #b70808;
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076; }
      .doc-landing-container .doc-landing-banner button:hover {
        cursor: pointer; }
      .doc-landing-container .doc-landing-banner button:focus {
        outline: none;
        border: none; }
  .doc-landing-container .doc-landing-content {
    width: 100%; }
    .doc-landing-container .doc-landing-content .learn-more {
      width: 100%; }
      .doc-landing-container .doc-landing-content .learn-more p.main-text {
        width: 40%;
        margin: 0 auto;
        padding-top: 100px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif; }
      .doc-landing-container .doc-landing-content .learn-more p.sub-text {
        width: 60%;
        margin: 40px auto;
        text-align: center;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important; }
      .doc-landing-container .doc-landing-content .learn-more .storm-damage-image {
        width: 75%;
        height: auto;
        margin: 15px auto;
        display: block; }
    .doc-landing-container .doc-landing-content .doc-content-blocks {
      width: 70%;
      margin: 50px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: justify;
          justify-content: space-between;
      padding-bottom: 200px; }
      .doc-landing-container .doc-landing-content .doc-content-blocks .left {
        background-color: #b70808;
        color: white;
        max-width: 45%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1; }
        .doc-landing-container .doc-landing-content .doc-content-blocks .left p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font: 18px Lato, serif, sans-serif;
          line-height: 24px !important; }
      .doc-landing-container .doc-landing-content .doc-content-blocks .right {
        background-color: black;
        color: white;
        max-width: 45%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1; }
        .doc-landing-container .doc-landing-content .doc-content-blocks .right p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font: 18px Lato, serif, sans-serif;
          line-height: 24px !important; }
    .doc-landing-container .doc-landing-content .help-text {
      margin: 20px auto 100px auto;
      padding: 20px auto;
      width: 50%;
      text-align: center;
      font: 30px ReneBiederExtraBold,  serif, sans-serif; }
    .doc-landing-container .doc-landing-content .perform-inspection {
      margin-top: -200px;
      padding-bottom: 100px; }
      .doc-landing-container .doc-landing-content .perform-inspection img {
        width: 70%;
        display: block;
        -ms-flex-line-pack: center;
            align-content: center;
        margin: 0 auto; }
      .doc-landing-container .doc-landing-content .perform-inspection p.main-text {
        width: 55%;
        margin: 0 auto;
        padding-top: 50px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif; }
      .doc-landing-container .doc-landing-content .perform-inspection p.sub-text {
        width: 60%;
        margin: 0 auto;
        padding: 50px 0 50px 0;
        text-align: center;
        line-height: 1.2em;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important; }
      .doc-landing-container .doc-landing-content .perform-inspection button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        display: block;
        margin: 30px auto;
        background-color: #b70808;
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076; }
        .doc-landing-container .doc-landing-content .perform-inspection button:hover {
          cursor: pointer; }
        .doc-landing-container .doc-landing-content .perform-inspection button:focus {
          outline: none;
          border: none; }
    .doc-landing-container .doc-landing-content .opportunity-content {
      background-color: rgba(226, 226, 226, 0.45); }
      .doc-landing-container .doc-landing-content .opportunity-content .check-image {
        max-height: 300px;
        display: block;
        -ms-flex-line-pack: center;
            align-content: center;
        margin: 0 auto;
        position: relative;
        vertical-align: middle;
        -webkit-transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
                transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
        box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
        border-radius: 2px; }
      .doc-landing-container .doc-landing-content .opportunity-content button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        position: relative;
        top: 60px;
        left: calc(50% - 175px);
        background-color: #b70808;
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076; }
        .doc-landing-container .doc-landing-content .opportunity-content button:hover {
          cursor: pointer; }
        .doc-landing-container .doc-landing-content .opportunity-content button:focus {
          outline: none;
          border: none; }
      .doc-landing-container .doc-landing-content .opportunity-content .opportunity-savings {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-line-pack: justify;
            align-content: space-between;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center; }
        .doc-landing-container .doc-landing-content .opportunity-content .opportunity-savings .content {
          max-width: 65%;
          -ms-flex: 1 1;
              flex: 1 1;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          .doc-landing-container .doc-landing-content .opportunity-content .opportunity-savings .content p.main-text {
            width: 80%;
            margin: 0 auto;
            padding-top: 0;
            text-align: right;
            font: 30px ReneBiederExtraBold,  serif, sans-serif; }
          .doc-landing-container .doc-landing-content .opportunity-content .opportunity-savings .content p.sub-text {
            width: 80%;
            margin: 0 auto;
            padding-top: 30px;
            text-align: right;
            font: 16px Lato,  serif, sans-serif;
            line-height: 24px !important; }
        .doc-landing-container .doc-landing-content .opportunity-content .opportunity-savings .rep {
          max-width: 30%;
          -ms-flex: 1 1;
              flex: 1 1; }
          .doc-landing-container .doc-landing-content .opportunity-content .opportunity-savings .rep img {
            width: 90%;
            display: block;
            -ms-flex-line-pack: center;
                align-content: center; }
    .doc-landing-container .doc-landing-content .inspection-request .call-text-button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      position: relative;
      bottom: 30px;
      left: 10%;
      background-color: #b70808;
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076; }
      .doc-landing-container .doc-landing-content .inspection-request .call-text-button:focus {
        outline: none;
        border: none; }
    .doc-landing-container .doc-landing-content .inspection-request p.sub-text {
      width: 70%;
      margin: 0 auto;
      padding: 10px 0 0 0;
      text-align: center;
      font: 16px Lato,  serif, sans-serif;
      line-height: 24px !important; }
    .doc-landing-container .doc-landing-content .inspection-request p.main-text {
      width: 70%;
      margin: 0 auto;
      padding-top: 30px;
      text-align: center;
      font: 30px ReneBiederExtraBold,  serif, sans-serif; }
    .doc-landing-container .doc-landing-content .inspection-request .request-form {
      width: 80%;
      margin: 20px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
    .doc-landing-container .doc-landing-content .inspection-request .schedule-button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      margin-left: calc(50% - 175px);
      margin-top: 30px;
      position: revert;
      background-color: black;
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076; }
      .doc-landing-container .doc-landing-content .inspection-request .schedule-button:hover {
        cursor: pointer; }
    .doc-landing-container .doc-landing-content .inspection-request .required-text {
      width: 70%;
      margin: 0 auto;
      padding: 30px 0;
      text-align: center;
      font: 15px Lato,  serif, sans-serif; }

.bold {
  font-weight: bolder; }

@media all and (max-width: 750px) {
  .defective-shingles-container .program-name-banner .banner-text-content {
    position: absolute;
    top: 10%;
    width: 90%;
    left: 5%; }
    .defective-shingles-container .program-name-banner .banner-text-content p {
      font: 48px ReneBiederExtraBold, serif, sans-serif; }
  .defective-shingles-container .program-name-banner .banner-left-content {
    display: none;
    height: 100%;
    width: 50%;
    float: left;
    background: transparent url(/static/media/Horizon1.94623bea.png) center center repeat padding-box; }
  .defective-shingles-container .program-name-banner .banner-right-content {
    height: 100%;
    width: 100%;
    float: left;
    background: transparent url(/static/media/Horizong.3a07c933.png) center center repeat padding-box; }
  .defective-shingles-container .program-name-banner button {
    width: 300px;
    left: calc(50% - 150px);
    font: 14px ReneBiederExtraBold, serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .learn-more p.main-text {
    width: 80%;
    margin: 0 auto;
    padding-top: 75px;
    text-align: center;
    font: 22px ReneBiederExtraBold,  serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .learn-more p.sub-text {
    width: 80%;
    margin: 20px auto 50px;
    text-align: center;
    font: 16px Lato,  serif, sans-serif;
    line-height: 24px !important; }
  .defective-shingles-container .defective-shingles-content .storm-activity p.start-text {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    text-align: center;
    font: 17px Lato,  serif, sans-serif;
    line-height: 24px !important; }
  .defective-shingles-container .defective-shingles-content .storm-activity p.main-text {
    width: 80%;
    padding-top: 40px;
    font: 22px ReneBiederExtraBold,  serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .storm-activity p.sub-text {
    width: 80%;
    padding-bottom: 150px; }
  .defective-shingles-container .defective-shingles-content .perform-inspection {
    margin-top: -100px;
    padding-bottom: 20px; }
    .defective-shingles-container .defective-shingles-content .perform-inspection img {
      width: 90%;
      display: block;
      -ms-flex-line-pack: center;
          align-content: center;
      margin: 0 auto; }
    .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container {
      width: 90%;
      margin: 50px auto 10px auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .left {
        background-color: #b70808;
        color: white;
        max-width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1;
        margin-bottom: 20px; }
        .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .left p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font: 16px Lato, serif, sans-serif;
          line-height: 24px !important; }
      .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .right {
        background-color: black;
        color: white;
        max-width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1; }
        .defective-shingles-container .defective-shingles-content .perform-inspection .content-blocks-container .right p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font: 16px Lato, serif, sans-serif;
          line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .perform-inspection p.main-text {
      width: 80%;
      margin: 0 auto;
      padding-top: 20px;
      text-align: center;
      font: 22px ReneBiederExtraBold,  serif, sans-serif; }
    .defective-shingles-container .defective-shingles-content .perform-inspection p.sub-text {
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 20px 0;
      text-align: center;
      line-height: 1.2em;
      font: 16px Lato,  serif, sans-serif;
      line-height: 24px !important; }
    .defective-shingles-container .defective-shingles-content .perform-inspection button {
      width: 300px;
      text-transform: uppercase;
      font: 14px ReneBiederExtraBold, serif, sans-serif;
      margin: 10px auto; }
      .defective-shingles-container .defective-shingles-content .perform-inspection button:hover {
        cursor: pointer; }
      .defective-shingles-container .defective-shingles-content .perform-inspection button:focus {
        outline: none;
        border: none; }
  .defective-shingles-container .defective-shingles-content .opportunity-content .check-image {
    width: 90%;
    max-width: 800px;
    display: block;
    -ms-flex-line-pack: center;
        align-content: center;
    margin: 50px auto;
    position: relative;
    top: -50px;
    left: -10px;
    -webkit-transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
            transform: perspective(1000px) rotateX(4deg) rotateY(-12deg) rotateZ(2deg);
    box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
    border-radius: 2px; }
  .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-line-pack: justify;
        align-content: space-between;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding-bottom: 40px; }
    .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content {
      max-width: 80%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content p.main-text {
        width: 90%;
        margin: 0 auto;
        padding-top: 70px;
        text-align: center;
        font: 22px ReneBiederExtraBold,  serif, sans-serif; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .content p.sub-text {
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
        text-align: center; }
    .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .rep {
      max-width: 90%;
      -ms-flex: 1 1;
          flex: 1 1; }
      .defective-shingles-container .defective-shingles-content .opportunity-content .opportunity-savings .rep img {
        margin: 0 auto;
        width: 90%;
        display: block;
        -ms-flex-line-pack: center;
            align-content: center; }
  .defective-shingles-container .defective-shingles-content .inspection-request .call-text-button {
    width: 300px;
    font: 14px ReneBiederExtraBold, serif, sans-serif;
    height: 60px;
    position: relative;
    bottom: 30px;
    left: calc(50% - 150px); }
  .defective-shingles-container .defective-shingles-content .inspection-request p.sub-text {
    width: 80%;
    margin: 0 auto;
    padding: 0 0 0 0;
    text-align: center;
    font: 16px Lato,  serif, sans-serif;
    line-height: 24px !important; }
  .defective-shingles-container .defective-shingles-content .inspection-request p.main-text {
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
    font: 30px ReneBiederExtraBold,  serif, sans-serif; }
  .defective-shingles-container .defective-shingles-content .inspection-request .request-form {
    width: 90%;
    margin: 20px auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
  .defective-shingles-container .defective-shingles-content .inspection-request .schedule-button {
    width: 350px;
    text-transform: uppercase;
    font: 16px ReneBiederExtraBold, serif, sans-serif;
    height: 60px;
    margin-left: calc(50% - 175px);
    margin-top: 30px;
    position: revert;
    background-color: black;
    color: white;
    border: none;
    box-shadow: 2px 2px 2px #00000076; }
    .defective-shingles-container .defective-shingles-content .inspection-request .schedule-button:hover {
      cursor: pointer; }
  .defective-shingles-container .defective-shingles-content .inspection-request .required-text {
    width: 70%;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    font: 15px Lato, serif, sans-serif; } }

