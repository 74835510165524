.services-container {
  padding-bottom: 20px;

  .services-banner {
    background: transparent url('../../../resources/images/misc/background_banner_red.png') center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px;

    .services-banner-text-container {
      position: absolute;
      top: 50%;
      width: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      .banner-text-small {
        text-transform: uppercase;
        color: white;
        font: 24px PoppinsBold, serif, sans-serif;
        text-align: center;
        margin-bottom: 0;
        text-shadow: 0 0 10px #000000;
      }

      .banner-text-large {
        color: white;
        font: 56px PoppinsSemiBold, serif, sans-serif;
        text-align: center;
        text-shadow: 0 0 10px #000000;
      }
    }
    .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px);
    }
  }
}


.services-template-content {
  width: 80%;
  margin: 50px auto;


  h1 {
    color: rgb(183, 8, 8);
    font: 48px PoppinsSemiBold, serif, sans-serif;
  }

  p.main-text {
    width: 100%;
    font: 20px Poppins, serif, sans-serif;
  }

  h2 {
    color: rgb(183, 8, 8);
    font: 36px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px;
  }

  p {
    width: 100%;
    font: 18px Poppins, serif, sans-serif;
  }

  h3 {
    color: rgb(183, 8, 8);
    font: 30px PoppinsSemiBold, serif, sans-serif;
    margin-bottom: 5px;
  }

}

.services-template-container-inline {


  .services-template-content {

    width: 60%;
    margin: 50px;


    h1 {
      color: rgb(183, 8, 8);
      font: 48px PoppinsSemiBold, serif, sans-serif;
    }

    p.main-text {
      width: 100%;
      font: 20px Poppins, serif, sans-serif;
    }

    h2 {
      color: rgb(183, 8, 8);
      font: 36px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px;
    }

    p {
      width: 100%;
      font: 18px Poppins, serif, sans-serif;
    }

    h3 {
      color: rgb(183, 8, 8);
      font: 30px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px;
    }

  }

  .services-template-form-container {
    margin: 50px auto;
    width: 30%;
    position: fixed;
    right: 0;
    top: calc(50% - 300px);
    border: 2px solid rgb(183, 8, 8);
    border-right: none;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
  }
}



/****************** SMALL COMPUTERS & TABLETS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .services-container {
    .services-banner {
      .services-banner-text-container {

        .banner-text-large {
          color: white;
          font: 42px PoppinsSemiBold, serif, sans-serif;
          text-align: center;
          text-shadow: 0 0 10px #000000;
        }
      }
    }
  }
  .services-template-container { }
  .services-template-content {

    h1 {
      font: 48px PoppinsSemiBold, serif, sans-serif;
    }
    p.main-text {
      width: 100%;
      font: 22px Poppins, serif, sans-serif;
    }
    h2 {
      color: #b70808;
      font: 36px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px;
    }
    p {
      width: 100%;
      font: 18px Poppins, serif, sans-serif;
    }
    li {
      width: 100%;
      font: 16px Poppins, serif, sans-serif;
    }
  }

}



/***************** TABLETS AND SMALLER **********/
@media all and (max-width: 849px) {
  .services-container {
    .services-banner {
      .services-banner-text-container {
        width: 100%;
        .banner-text-small {
          font-size: 20px;
        }

        .banner-text-large {
          font-size: 34px;
        }
      }
    }
  }

  .services-template-container { }
  .services-template-content {

    h1 {
      font: 28px PoppinsSemiBold, serif, sans-serif;
    }
    p.main-text {
      width: 100%;
      font: 19px Poppins, serif, sans-serif;
    }
    h2 {
      color: #b70808;
      font: 26px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 5px;
    }
    p {
      width: 100%;
      font: 16px Poppins, serif, sans-serif;
    }
    li {
      width: 100%;
      font: 14px Poppins, serif, sans-serif;
    }
  }
}
