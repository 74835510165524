.select-input-container {
  width: 100%;
  border-bottom: 3px solid #707070;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  select {
    width: 100%;
    height: 50px;
    border: none;
    font: 18px Poppins, serif, sans-serif;
    text-indent: 10px;

    &:focus {
      border: none;
      outline: none;
    }
  }

  input {
    border: none;
    height: 50px;
    margin-top: 6px;
    width: 100%;
    text-indent: 10px;
    font: 18px Poppins, serif, sans-serif;

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.drop-down-select-info {
  color: #6a6a6a;
  width: 100%;
  margin-left: 13px;
  font: 16px Poppins, serif, sans-serif;

  p {
    margin-bottom: 0;
  }
}