.meet-the-docs-container {

  .docs-banner {
    background: transparent url('../../resources/images/misc/background_banner_red.png') center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px;

    img {
      width: 100%;
      height: 100%;
    }

    .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px);
    }
  }
  .docs-banner-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);

    .banner-text-small {
      text-transform: uppercase;
      color: #fff;
      font: 24px PoppinsBold, serif, sans-serif;
      text-align: center;
      margin-bottom: 0;
      text-shadow: 0 0 2px #000000;
    }
    .banner-text-large {
      color: #fff;
      font: 56px PoppinsSemiBold, serif, sans-serif;
      text-align: center;
      text-shadow: 0 0 2px #000000;
    }
  }

  .docs-oath {
    width: 80%;
    margin: 50px auto 0 auto;
    padding-left: 15px;
    border-left: 4px solid #b70808;
    font: 28px PoppinsSemiBold, serif, sans-serif;
  }

  .docs-list-container {
    width: 80%;
    margin: 100px auto 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
}

/******* DOCS CONTAINER */

.doc-container {
  width: 30%;
  min-width: 350px;
  height: 450px;
  background-color: white;
  position: relative;

  .doc-image-container {
    width: 350px;
    height: 350px;
    background-color: rgba(0, 0, 0, 0.9);
    margin: 0 auto;
    border-radius: 50%;
    position: relative;
    overflow: hidden;

    img {
      width: 350px;
      height: auto;
    }
  }
  .doc-icon-container {
      position: absolute;
      top: 0;
      right: 10%;
      width: 100px;
      height: 100px;

      img {
        width: 100px;
        height: 100px;
      }
  }

  .doc-name {
    text-transform: uppercase;
    text-align: center;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    color: rgb(183, 8, 8);
    margin-bottom: 0;
    margin-top: 10px;
  }

  .doc-position {
    text-transform: uppercase;
    margin: 15px auto;
    text-align: center;
    font: 20px PoppinsMedium, serif, sans-serif;
  }
}

/* VIEW DOC MODAL */
.view-doc-modal-content {
  height: 800px;
  width: 600px;
  margin-top: calc(50vh - 400px);
  margin-left: calc(50vw - 300px);
  background-color: white;
  border-radius: 23px;

  .doc-container-modal {
    border-radius: 23px;
    padding-top: 40px;
    height: 500px;
    background-color: white;
    position: relative;

    .doc-image-container-modal {
      width: 300px;
      height: 300px;
      background-color: rgba(0, 0, 0, 0.9);
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 300px;
        height: auto;
      }
    }
    .doc-icon-container-modal {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .doc-name {
      text-transform: uppercase;
      text-align: center;
      font: 28px PoppinsSemiBold, serif, sans-serif;
      color: rgb(183, 8, 8);
      margin-bottom: 0;
      padding-top: 20px;
    }
    .doc-position {
      text-transform: uppercase;
      margin: 15px auto;
      text-align: center;
      font: 20px PoppinsMedium, serif, sans-serif;
    }
    .doc-bio-modal-content {
      background-color: #E6E6E6;
      height: 335px;
      border-radius: 15px;
      margin-top: 50px;
      width: 96%;
      margin-left: 2%;
      overflow: auto;

      p {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 95%;
        margin: auto;
        font: 17px Poppins, sans-serif, serif;
      }
    }
  }
}




/*************** SMALL LAPTOPS ***************/

@media all and (max-width: 1500px) and (min-width: 850px) {

  .meet-the-docs-container {
    .docs-banner-text-container {

      .banner-text-large {
        font: 35px PoppinsSemiBold, serif, sans-serif;
      }
    }

    .docs-list-container {
      width: 90%;
      justify-content: space-around;
    }
  }
}


/*************** TABLETS ***************/
@media all and (max-width: 849px) and (min-width: 700px) {



  .meet-the-docs-container {

    .docs-oath {
      font: 20px PoppinsSemiBold, serif, sans-serif;
    }

    .docs-banner-text-container {
      width: 100%;

      .banner-text-large {
        font-size: 36px;
      }
    }

    .docs-list-container {
      width: 90%;
      margin: 50px auto 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }
  }

  .doc-container {
    width: 40%;
    min-width: 250px;
    height: 350px;
    background-color: white;
    position: relative;

    .doc-image-container {
      width: 250px;
      height: 250px;
      background-color: rgba(0, 0, 0, 0.9);
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 250px;
        height: auto;
      }
    }
    .doc-icon-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      height: 70px;

      img {
        width: 70px;
        height: 70px;
      }
    }

    .doc-name {
      text-transform: uppercase;
      text-align: center;
      font: 28px PoppinsSemiBold, serif, sans-serif;
      color: rgb(183, 8, 8);
      margin-bottom: 0;
      margin-top: 10px;
    }

    .doc-position {
      text-transform: uppercase;
      margin: 15px auto;
      text-align: center;
      font: 20px PoppinsMedium, serif, sans-serif;
    }
  }

}


/*************** MOBILE ***************/
@media all and (max-width: 699px) {



  .meet-the-docs-container {

    .docs-oath {
      font: 17px PoppinsSemiBold, serif, sans-serif;
    }

    .docs-banner-text-container {
      width: 100%;

      .banner-text-small {
        font-size: 20px;
      }

      .banner-text-large {
        font-size: 30px;
      }
    }

    .docs-list-container {
      width: 90%;
      margin: 50px auto 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }
  }

  .doc-container {
    width: 100%;
    height: 375px;

    .doc-image-container {
      width: 300px;
      height: 300px;

      img {
        width: 300px;
        height: auto;
      }
    }
    .doc-icon-container {
      display: none;
    }

    .doc-name {
      text-transform: uppercase;
      text-align: center;
      font: 28px PoppinsSemiBold, serif, sans-serif;
      color: rgb(183, 8, 8);
      margin-bottom: 0;
      margin-top: 10px;
    }

    .doc-position {
      text-transform: uppercase;
      margin: 15px auto;
      text-align: center;
      font: 20px PoppinsMedium, serif, sans-serif;
    }
  }
}