.arrow-button-container {
  width: 75%;
  height: 65px;
  background-color: white;
  box-shadow: 0 0 10px #00000076;
  border-radius: 9px;
  border: 2px solid rgba(183, 8, 8, 0);

  &:hover {
    border: 2px solid rgba(183, 8, 8, 1);
  }

  .arrow-button-label {
    text-transform: uppercase;
    color: #b70808;
    font: 22px PoppinsSemiBold, Serif, sans-serif;
    display: inline-block;
    line-height: 63px;
    margin-left: 15px;
  }

  .arrow-button-img {
    float: right;
    margin-right: 2px;
    margin-top: 1px;
    display: inline-block;
  }

}