$redLogoColor: rgb(182, 8, 7);


.footer-banner {

  background-color: black;
  position: relative;
  text-align: center;
  color: white;
  height: 550px;
  z-index: 9998;

  img {
    width: 100%;
    height: 100%;
  }
  .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    top: 0;
    left: calc(50% - 50px);
  }

  .footer-license-text {
    text-align: left;
    display: inline-block;
    color: white;
    font: 18px Poppins, sans-serif, Serif;
    float: left;
    margin-bottom: 0;
    margin-left: 25px;
    margin-top: 25px;

    &.bottom {
      margin-top: 10px;
    }


  }

}

.footer-copyright {
  width: 100%;
  height: 60px;
  //border-top: 1px solid $redLogoColor;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: white;
    line-height: 60px;
    margin-right: 15px;
    margin-bottom: 0;
    font: 14px Poppins, serif, sans-serif;
  }

  .policy-text {
    color: white;
    font: 14px Poppins, serif, sans-serif;
  }

  .policy-text:hover {
    text-decoration: underline !important;
    color: white;
  }
}

.footer-content {
  height: 65%;
  min-height: 200px;
  position: absolute;
  width: 80%;
  top: 20%;
  left: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer-contact {
    display: flex;
    height: 100%;
    flex-direction: column;

    .footer-logo-image {
      width: 60%;
      height: auto;
      margin-bottom: 0;
    }

    .footer-contact-icon {
      width: 100%;
      height: 40px;
      float: left;
      margin-top: 10px;

      &.phone {
        img {
          height: 20px;
          width: 20px;
        }
      }

      &.email {
        img {
          height: 15px;
          width: 20px;
        }
      }

      &.map {
        img {
          height: 20px;
          width: 15px;
        }
      }

      img {
        float: left;
        margin-top: 5px;
        display: inline-block;
      }

      label {
        text-align: left;
        margin-left: 10px;
        display: inline-block;
        color: white;
        font: 18px Poppins, sans-serif, Serif;
        float: left;
        margin-bottom: 0;
      }

      label:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-services {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    a {
      color: white;
    }

    h3 {
      font: 20px PoppinsSemiBold, serif, sans-serif;
      margin-bottom: 0;
    }

    p {
      float: left;
      font: 18px Poppins, serif, sans-serif;
      margin-bottom: 0;
      text-align: left;

      &:hover {
        text-decoration: underline;
      }
    }

    .service-icon {
      font: 18px PoppinsBold, serif, sans-serif;
      color: white;
      font-weight: bolder;
    }


  }

  /* SOCIAL */
  .footer-social {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .social-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      height: 50px;
      width: 100%;
      margin-bottom: 50px;

      img {
        height: 100%;
        width: auto;
      }

      img:hover {
        cursor: pointer
      }
    }

    .certs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      img {
        max-width: 200px;
        height: auto;
      }
    }


  }
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1150px) and (min-width: 850px) {

  .footer-content {
    justify-content: space-around;

    .footer-services {
      display: none;
    }
  }

}

/****************** TABLETS & MOBILE ****************/
@media all and (max-width: 849px) {

  .footer-banner {
    height: 650px;
  }

  .footer-content {
    justify-content: center;

    .footer-services {
      display: none;
    }
    .footer-social {
      display: none;
    }
  }

}