.image-multi-select-container {
  width: 100%;
  height: 90px;
  border-bottom: 3px solid #707070;
  margin-bottom: 30px;
}

.image-multi-select-info {

  display: flex;

  img {
    width: 22px;
    height: 18px;
    margin-top: 6px;
  }

  p {
    color: rgb(106, 106, 106);
    width: 100%;
    margin-left: 13px;
    font: 16px Poppins, serif, sans-serif;
  }

}

.image-multi-select-selection {
  display: flex;
}