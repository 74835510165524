.notFoundContainer {
    width: 100%;
    height: calc(100vh - 280px);
    margin-top: 50px;

    a {
        display: block;
        text-align: center;
        font: 18px Poppins, serif, sans-serif;
        margin: 5px auto;
        color: rgb(183, 8, 8);
    }
}

.smallText-notFound {
    font: 22px PoppinsBold, serif, sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 100px;
}

.largeText-notFound {
    font: 44px PoppinsSemiBold, serif, sans-serif;
    color: rgb(183, 8, 8);
    text-align: center;
}