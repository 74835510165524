.loader-container {
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  z-index: 99999;
  background-color: black;
  padding: 0;

  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid rgb(183, 8, 8); /* Red */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-top: calc(50vh - 60px);
    margin-left: calc(50% - 60px);
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}



.doc-landing-container {
  padding-bottom: 20px;

  .logo-home-link {
    width: 100px;
    height:  auto;
    position: absolute;
    top: 5px;
    left: calc(50% - 50px);

    &:hover  {
      cursor: pointer;
    }
  }

  // Gray Color
  .program-gray {
    color: rgb(92, 92, 92)
  }

  .doc-landing-banner {

    width: 100%;
    height: 500px;

    .banner-text-content {
      position: absolute;
      top: 10%;
      width: 60%;
      left: 20%;

      p {
        font: 72px ReneBiederExtraBold, serif, sans-serif;
        text-align: center;
        text-transform: uppercase;
        color: white;
        box-shadow: 2px 2px 2px #00000076;

      }
    }

    .banner-left-content {
      height: 100%;
      width: 50%;
      float: left;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner-right-content {

      img {
        width: 100%;
        height: 100%;
      }

      height: 100%;
      width: 50%;
      float: left;
      //background: transparent url(../../resources/images/defectiveShingles/Horizong.png) center center repeat padding-box;
    }

    button {
      width: 350px;
      text-transform: uppercase;
      font: 16px ReneBiederExtraBold, serif, sans-serif;
      height: 60px;
      position: relative;
      bottom: 30px;
      left: calc(50% - 175px);
      background-color: rgb(183, 8, 8);
      color: white;
      border: none;
      box-shadow: 2px 2px 2px #00000076;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .doc-landing-content {
    width: 100%;

    .learn-more {
      width: 100%;

      p.main-text {
        width: 40%;
        margin: 0 auto;
        padding-top: 100px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif;
      }

      p.sub-text {
        width: 60%;
        margin: 40px auto;
        text-align: center;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important;
      }

      .storm-damage-image {
        width: 75%;
        height: auto;
        margin: 15px auto;
        display: block;
      }
    }

    .doc-content-blocks {
      width: 70%;
      margin: 50px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 200px;


      .left {
        background-color: rgb(183, 8, 8);
        color: white;
        max-width: 45%;
        display: flex;
        flex: 1;

        p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font:  18px Lato, serif, sans-serif;
          line-height: 24px !important;
        }
      }

      .right {
        background-color: black;
        color: white;
        max-width: 45%;
        display: flex;
        flex: 1;

        p {
          width: 90%;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: auto;
          text-align: center;
          font:  18px Lato, serif, sans-serif;
          line-height: 24px !important;

        }
      }
    }

    //.storm-activity {
    //  background-color: rgb(226, 226, 226);
    //  width: 100%;
    //
    //  p.start-text {
    //    width: 60%;
    //    margin: 0 auto;
    //    padding-top: 50px;
    //    text-align: center;
    //    font: 17px Lato,  serif, sans-serif;
    //    line-height: 24px !important;
    //  }
    //
    //  p.main-text {
    //    width: 55%;
    //    margin: 0 auto;
    //    padding-top: 50px;
    //    text-align: center;
    //    font: 30px ReneBiederExtraBold,  serif, sans-serif;
    //  }
    //
    //  p.sub-text {
    //    width: 60%;
    //    margin: 0 auto;
    //    padding: 40px 0 250px 0;
    //    text-align: center;
    //    font: 16px Lato,  serif, sans-serif;
    //    line-height: 24px !important;
    //  }
    //
    //
    //}

    .help-text {
      margin: 20px auto 100px auto;
      padding: 20px auto;
      width: 50%;
      text-align: center;
      font: 30px ReneBiederExtraBold,  serif, sans-serif;
    }

    .perform-inspection {

      margin-top: -200px;
      padding-bottom: 100px;

      img {
        width: 70%;
        display: block;
        align-content: center;
        margin: 0 auto;
      }



      p.main-text {
        width: 55%;
        margin: 0 auto;
        padding-top: 50px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif;
      }

      p.sub-text {
        width: 60%;
        margin: 0 auto;
        padding: 50px 0 50px 0;
        text-align: center;
        line-height: 1.2em;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important;
      }

      button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        display: block;
        margin: 30px auto;
        background-color: rgb(183, 8, 8);
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
          border: none;
        }
      }
    }

    .opportunity-content {

      background-color: rgba(226, 226, 226, 0.45);



      .check-image {
        max-height: 300px;
        display: block;
        align-content: center;
        margin: 0 auto;
        position: relative;
        vertical-align: middle;
        //transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(1px);

        transform:
                perspective(1000px)
                rotateX(4deg)
                rotateY(-12deg)
                rotateZ(2deg);
        box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
        border-radius: 2px;
      }

      button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        position: relative;
        top: 60px;
        left: calc(50% - 175px);
        background-color: rgb(183, 8, 8);
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
          border: none;
        }
      }

      .opportunity-savings {

        display: flex;
        flex-direction: row;
        align-content: space-between;
        justify-content: center;
        align-items: center;

        .content {
          max-width: 65%;
          flex: 1;
          display: flex;
          flex-direction: column;

          p.main-text {
            width: 80%;
            margin: 0 auto;
            padding-top: 0;
            text-align: right;
            font: 30px ReneBiederExtraBold,  serif, sans-serif;
          }

          p.sub-text {
            width: 80%;
            margin: 0 auto;
            padding-top: 30px;
            text-align: right;
            font: 16px Lato,  serif, sans-serif;
            line-height: 24px !important;
          }
        }

        .rep {
          max-width: 30%;
          flex: 1;

          img  {
            width: 90%;
            display: block;
            align-content: center;
          }
        }


      }
    }


    .inspection-request {

      .call-text-button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        position: relative;
        bottom: 30px;
        left: 10%;
        background-color: rgb(183, 8, 8);
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076;

        &:focus {
          outline: none;
          border: none;
        }
      }

      p.sub-text {
        width: 70%;
        margin: 0 auto;
        padding: 10px 0 0 0;
        text-align: center;
        font: 16px Lato,  serif, sans-serif;
        line-height: 24px !important;
      }

      p.main-text {
        width: 70%;
        margin: 0 auto;
        padding-top: 30px;
        text-align: center;
        font: 30px ReneBiederExtraBold,  serif, sans-serif;
      }

      .request-form {
        width: 80%;
        margin: 20px auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;


      }

      .schedule-button {
        width: 350px;
        text-transform: uppercase;
        font: 16px ReneBiederExtraBold, serif, sans-serif;
        height: 60px;
        margin-left: calc(50% - 175px);
        margin-top: 30px;
        position: revert;
        background-color: black;
        color: white;
        border: none;
        box-shadow: 2px 2px 2px #00000076;

        &:hover {
          cursor: pointer;
        }
      }

      .required-text {
        width: 70%;
        margin: 0 auto;
        padding: 30px 0;
        text-align: center;
        font: 15px Lato,  serif, sans-serif;
      }

    }
  }
}

.bold {
  font-weight: bolder;
}


@media all and (max-width: 750px) {

  .defective-shingles-container {

    .program-name-banner {

      .banner-text-content {
        position: absolute;
        top: 10%;
        width: 90%;
        left: 5%;

        p {
          font: 48px ReneBiederExtraBold, serif, sans-serif;
        }
      }

      .banner-left-content {
        display: none;
        height: 100%;
        width: 50%;
        float: left;
        background: transparent url(../../resources/images/defectiveShingles/Horizon1.png) center center repeat padding-box;
      }

      .banner-right-content {
        height: 100%;
        width: 100%;
        float: left;
        background: transparent url(../../resources/images/defectiveShingles/Horizong.png) center center repeat padding-box;
      }

      button {
        width: 300px;
        left: calc(50% - 150px);
        font: 14px ReneBiederExtraBold, serif, sans-serif;
      }
    }

    .defective-shingles-content {

      .learn-more {
        p.main-text {
          width: 80%;
          margin: 0 auto;
          padding-top: 75px;
          text-align: center;
          font: 22px ReneBiederExtraBold,  serif, sans-serif;
        }

        p.sub-text {
          width: 80%;
          margin: 20px auto 50px;
          text-align: center;
          font: 16px Lato,  serif, sans-serif;
          line-height: 24px !important;
        }
      }

      .storm-activity {

        p.start-text {
          width: 80%;
          margin: 0 auto;
          padding-top: 30px;
          text-align: center;
          font: 17px Lato,  serif, sans-serif;
          line-height: 24px !important;
        }

        p.main-text {
          width: 80%;
          padding-top: 40px;
          font: 22px ReneBiederExtraBold,  serif, sans-serif;
        }

        p.sub-text {
          width: 80%;
          padding-bottom: 150px;

        }
      }

      .perform-inspection {

        margin-top: -100px;
        padding-bottom: 20px;

        img {
          width: 90%;
          display: block;
          align-content: center;
          margin: 0 auto;
        }

        .content-blocks-container {
          width: 90%;
          margin: 50px auto 10px auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;


          .left {
            background-color: rgb(183, 8, 8);
            color: white;
            max-width: 100%;
            display: flex;
            flex: 1;
            margin-bottom: 20px;

            p {
              width: 90%;
              padding-top: 20px;
              padding-bottom: 20px;
              margin: auto;
              text-align: center;
              font:  16px Lato, serif, sans-serif;
              line-height: 24px !important;
            }
          }

          .right {
            background-color: black;
            color: white;
            max-width: 100%;
            display: flex;
            flex: 1;

            p {
              width: 90%;
              padding-top: 20px;
              padding-bottom: 20px;
              margin: auto;
              text-align: center;
              font:  16px Lato, serif, sans-serif;
              line-height: 24px !important;

            }
          }
        }

        p.main-text {
          width: 80%;
          margin: 0 auto;
          padding-top: 20px;
          text-align: center;
          font: 22px ReneBiederExtraBold,  serif, sans-serif;
        }

        p.sub-text {
          width: 80%;
          margin: 0 auto;
          padding: 20px 0 20px 0;
          text-align: center;
          line-height: 1.2em;
          font: 16px Lato,  serif, sans-serif;
          line-height: 24px !important;
        }

        button {
          width: 300px;
          text-transform: uppercase;
          font: 14px ReneBiederExtraBold, serif, sans-serif;
          margin: 10px auto;


          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .opportunity-content {


        .check-image {
          width: 90%;
          max-width: 800px;
          display: block;
          align-content: center;
          margin: 50px auto;
          position: relative;
          top: -50px;
          left: -10px;
          //transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(1px);

          transform:
                  perspective(1000px)
                  rotateX(4deg)
                  rotateY(-12deg)
                  rotateZ(2deg);
          box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
          border-radius: 2px;
        }

        .opportunity-savings {

          display: flex;
          flex-direction: column;
          align-content: space-between;
          justify-content: center;
          align-items: center;
          padding-bottom: 40px;

          .content {
            max-width: 80%;
            display: flex;
            flex-direction: column;

            p.main-text {
              width: 90%;
              margin: 0 auto;
              padding-top: 70px;
              text-align: center;
              font: 22px ReneBiederExtraBold,  serif, sans-serif;
            }

            p.sub-text {
              width: 90%;
              margin: 0 auto;
              padding-top: 30px;
              text-align: center;

            }
          }

          .rep {
            max-width: 90%;
            flex: 1;


            img  {
              margin: 0 auto;
              width: 90%;
              display: block;
              align-content: center;
            }
          }


        }
      }

      .inspection-request {

        .call-text-button {
          width: 300px;
          font: 14px ReneBiederExtraBold, serif, sans-serif;
          height: 60px;
          position: relative;
          bottom: 30px;
          left: calc(50% - 150px);
        }

        p.sub-text {
          width: 80%;
          margin: 0 auto;
          padding: 0 0 0 0;
          text-align: center;
          font: 16px Lato,  serif, sans-serif;
          line-height: 24px !important;
        }

        p.main-text {
          width: 90%;
          margin: 0 auto;
          padding-top: 20px;
          text-align: center;
          font: 30px ReneBiederExtraBold,  serif, sans-serif;
        }

        .request-form {
          width: 90%;
          margin: 20px auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
        }

        .schedule-button {
          width: 350px;
          text-transform: uppercase;
          font: 16px ReneBiederExtraBold, serif, sans-serif;
          height: 60px;
          margin-left: calc(50% - 175px);
          margin-top: 30px;
          position: revert;
          background-color: black;
          color: white;
          border: none;
          box-shadow: 2px 2px 2px #00000076;

          &:hover {
            cursor: pointer;
          }
        }

        .required-text {
          width: 70%;
          margin: 0 auto;
          padding: 30px 0;
          text-align: center;
          font: 15px Lato, serif, sans-serif;
        }

      }
    }


  }


}