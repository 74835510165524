.image-text-area-container {

  width: 100%;
  border-bottom: 3px solid #707070;
  display: flex;
  height: 153px;
  margin-bottom: 25px;

  textarea {
    border: none;
    width: 100%;
    height: 150px;
    text-indent: 10px;
    font: 16px Poppins, serif, sans-serif;
  }
  textarea:focus {
    outline: none;
  }

  img {
    margin-top: 6px;
    width: 22px;
    height: 18px;
  }

}