.program-date-time-input-container {

  border-bottom: 2px solid rgb(219, 219, 219);
  height: 50px;
  width: 30%;


  label {
    text-indent: 5px;
    font: 18px LatoBold, serif, sans-serif;
    text-transform: uppercase;
    margin-bottom: 0;
    height: 48px;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 16px) scale(1);
    transition: all .1s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  .program-date-picker-container {
    width: 100%;
    height: 48px;

    .program-date-picker {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;

      .react-date-picker__wrapper {
        border: none;

        .react-date-picker__inputGroup__input {
          font: 16px Lato, serif, sans-serif;
        }
      }

    }

    .selected-time-label {
      margin-bottom: 0;
      position: relative;
      left: 100px;
      top: -35px;
      max-width: 100px;
      font: 16px Lato, serif, sans-serif;
    }
  }
}

.program-schedule-time-container {
  width: 405px;
  margin: 20px auto;
  height: 250px;
  overflow-y: auto;
  border: 2px solid #b70808;
  border-radius: 5px;

  p {
    text-align: center;
    line-height: 40px !important;
    height: 40px;
    width: 100px;
    display: inline-block;
    float: left;
    font: 18px Poppins, serif, sans-serif;
    margin-bottom: 0;
  }

  .sel-time {
    background-color: rgb(183, 8, 8);
    color: white;
  }

  p:hover {
    cursor: pointer;
    background-color: rgb(183, 8, 8);
    color: white
  }
}

@media all and (max-width: 750px) {

  .program-date-time-input-container {
    width: 100%

  }

}

@media all and (max-width: 600px){
  .program-schedule-time-container {
    width: 90%;
    margin: 20px auto;
    display: flex !important;;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: auto;

    p {
      text-align: center;
      line-height: 40px !important;
      height: 40px;
      width: 25%;
      min-width: 90px;
      font: 16px Poppins, serif, sans-serif;
      margin-bottom: 0;
    }

    .sel-time {
      background-color: rgb(183, 8, 8);
      color: white;
    }

    p:hover {
      cursor: pointer;
      background-color: rgb(183, 8, 8);
      color: white
    }
  }
}