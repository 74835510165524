.blog-template-container {

  padding-bottom: 20px;

  .blog-template-banner {
    background: transparent url('../../../resources/images/misc/background_banner_red.png') center center repeat padding-box;
    position: relative;
    text-align: center;
    color: white;
    height: 290px;

    .blog-template-text-container {
      position: absolute;
      top: 50%;
      width: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      .banner-text-small {
        text-transform: uppercase;
        color: white;
        font: 24px PoppinsBold, serif, sans-serif;
        text-align: center;
        margin-bottom: 0;
        text-shadow: 0 0 10px #000000;
      }

      .banner-text-large {
        color: white;
        font: 56px PoppinsSemiBold, serif, sans-serif;
        text-align: center;
        text-shadow: 0 0 10px #000000;
      }
    }
    .banner-icon {
      width: 100px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px);
    }
  }

  .blog-template-content-container {

    .blog-template-content {
      width: 80%;
      margin: 50px auto;

      .blog-item-container {
        width: 100%;
        display: flex;
        margin-top: 50px;
        flex-direction: column;

        .long-form-image  {
          margin: 10px auto;
          width: 100%;
          height: auto;
        }

        .long-form-image-clickable {
          margin: 10px auto;
          width: 100%;
          height: auto;

          &:hover {
            cursor: pointer;
          }
        }

        .blog-item-info-container {
          flex-grow: 1;
          width:  100%;
          margin: 10px auto;

          .blog-item-info-container-controls {
            flex-grow: 1;
            width: 100%;
            margin: 20px auto;
            height: 15px;
          }

          .arrow-left {
            &:before {
              content: '\25c4';
            }
          }

          .arrow-right {
            &:after {
              content: '\25BA';
            }
          }



          .blog-home-button {
            //font: 18px PoppinsSemiBold, serif, sans-serif;
            text-align: center;
            cursor: pointer;
            background-color: white;
            border: none;
            display: block;
            color: rgb(183, 8, 8);
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            border-bottom: 1px solid rgb(183, 8, 8);

            &:focus {
              outline: none;
            }

          }

          .blog-previous-button {
            //font: 18px PoppinsSemiBold, serif, sans-serif;
            text-align: center;
            cursor: pointer;
            float: left;
            background-color: white;
            border: none;
            display: block;
            color: rgb(183, 8, 8);
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            border-bottom: 1px solid rgb(183, 8, 8);

            &:focus {
              outline: none;
            }

          }

          .blog-next-button {
            //font: 18px PoppinsSemiBold, serif, sans-serif;
            text-align: center;
            cursor: pointer;
            float: right;
            background-color: white;
            border: none;
            display: block;
            color: rgb(183, 8, 8);
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            border-bottom: 1px solid rgb(183, 8, 8);

            &:focus {
              outline: none;
            }

          }

          h1 {
            color: #b70808;
            font: 48px PoppinsSemiBold, serif, sans-serif;
          }

          p.main-text {
            width: 100%;
            font: 22px Poppins, serif, sans-serif;
          }

          h2 {
            color: #b70808;
            font: 36px PoppinsSemiBold, serif, sans-serif;
            margin-bottom: 5px;
          }

          p {
            width: 100%;
            font: 18px Poppins, serif, sans-serif;
          }

          .iws-image  {
            width: 600px;
            max-width: 90%;
            margin: 10px auto 20px auto;
            display: block;
          }

          li {
            width: 100%;
            font: 16px Poppins, serif, sans-serif;
          }

          .iws-calculator-container {
            width: 100%;
            margin: 10px auto 20px auto;

            .iws-calculator-field-container {
              width: 100%;
              margin: 5px auto;
              display: flex;
              flex-direction: column;

              label {
                font: 15px PoppinsSemiBold, serif, sans-serif;
                margin-bottom: 0;
                text-align: center;
              }

              input {
                width: 90%;
                height: 35px;
                font: 14px Poppins, serif, sans-serif;
                max-width: 300px;
                border: 1px solid lightgray;
                border-radius: 5px;
                text-indent: 5px;
                margin: 0 auto;
              }

              select {
                width: 90%;
                height: 35px;
                font: 14px Poppins, serif, sans-serif;
                max-width: 300px;
                border: 1px solid lightgray;
                border-radius: 5px;
                margin: 0 auto;
              }

            }

          }
        }

        .blog-item-info-container-full {
          flex-grow: 1;
          width:  80%;
          margin: 20px auto;

          .arrow-left {

            &:before {
              content: '\25c4';
            }
          }

          .arrow-right {
            &:after {
              content: '\25BA';
            }
          }



          .blog-home-button {
            //font: 18px PoppinsSemiBold, serif, sans-serif;
            text-align: center;
            cursor: pointer;
            background-color: white;
            border: none;
            display: block;
            color: rgb(183, 8, 8);
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            border-bottom: 1px solid rgb(183, 8, 8);
            float: left;

            &:focus {
              outline: none;
            }

          }

          .blog-next-button {
            //font: 18px PoppinsSemiBold, serif, sans-serif;
            text-align: center;
            cursor: pointer;
            background-color: white;
            border: none;
            display: block;
            color: rgb(183, 8, 8);
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            border-bottom: 1px solid rgb(183, 8, 8);
            float: right;

            &:focus {
              outline: none;
            }

          }

          .blog-previous-button {
            //font: 18px PoppinsSemiBold, serif, sans-serif;
            text-align: center;
            cursor: pointer;
            background-color: white;
            border: none;
            float: left;
            display: block;
            color: rgb(183, 8, 8);
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            border-bottom: 1px solid rgb(183, 8, 8);

            &:focus {
              outline: none;
            }

          }

          h1 {
            color: #b70808;
            font: 48px PoppinsSemiBold, serif, sans-serif;
          }

          p.main-text {
            width: 100%;
            font: 22px Poppins, serif, sans-serif;
          }

          h2 {
            color: #b70808;
            font: 36px PoppinsSemiBold, serif, sans-serif;
            margin-bottom: 5px;
          }

          p {
            width: 100%;
            font: 18px Poppins, serif, sans-serif;
          }

          .iws-image  {
            width: 600px;
            max-width: 90%;
            margin: 10px auto 20px auto;
            display: block;
          }

          li {
            width: 100%;
            font: 16px Poppins, serif, sans-serif;
          }

          .iws-calculator-container {
            width: 100%;
            margin: 10px auto 20px auto;

            .iws-calculator-field-container {
              width: 100%;
              margin: 5px auto;
              display: flex;
              flex-direction: column;

              label {
                font: 15px PoppinsSemiBold, serif, sans-serif;
                margin-bottom: 0;
                text-align: center;
              }

              input {
                width: 90%;
                height: 35px;
                font: 14px Poppins, serif, sans-serif;
                max-width: 300px;
                border: 1px solid lightgray;
                border-radius: 5px;
                text-indent: 5px;
                margin: 0 auto;
              }

              select {
                width: 90%;
                height: 35px;
                font: 14px Poppins, serif, sans-serif;
                max-width: 300px;
                border: 1px solid lightgray;
                border-radius: 5px;
                margin: 0 auto;
              }

            }

          }
        }
      }
    }
  }
}


/****************** SMALL COMPUTERS & TABLETS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .blog-template-container {
    .blog-template-banner {
      .blog-template-text-container {
        .banner-text-small {

        }

        .banner-text-large {
          color: white;
          font: 42px PoppinsSemiBold, serif, sans-serif;
          text-align: center;
          text-shadow: 0 0 10px #000000;
        }
      }
    }
    .blog-template-content-container {

      .blog-template-content {
        .blog-item-container {
          .blog-item-info-container {
            flex-grow: 1;

            h1 {
              font: 48px PoppinsSemiBold, serif, sans-serif;
            }
            p.main-text {
              width: 100%;
              font: 22px Poppins, serif, sans-serif;
            }
            h2 {
              color: #b70808;
              font: 36px PoppinsSemiBold, serif, sans-serif;
              margin-bottom: 5px;
            }
            p {
              width: 100%;
              font: 18px Poppins, serif, sans-serif;
            }
            li {
              width: 100%;
              font: 16px Poppins, serif, sans-serif;
            }
          }

          .blog-item-info-container-full {
            flex-grow: 1;

            h1 {
              font: 48px PoppinsSemiBold, serif, sans-serif;
            }
            p.main-text {
              width: 100%;
              font: 22px Poppins, serif, sans-serif;
            }
            h2 {
              color: #b70808;
              font: 36px PoppinsSemiBold, serif, sans-serif;
              margin-bottom: 5px;
            }
            p {
              width: 100%;
              font: 18px Poppins, serif, sans-serif;
            }
            li {
              width: 100%;
              font: 16px Poppins, serif, sans-serif;
            }
          }
        }
      }
    }
  }
}


/***************** TABLETS AND SMALLER **********/
@media all and (max-width: 849px) {
  .blog-template-container {
    .blog-template-banner {
      .blog-template-text-container {
        width: 100%;
        .banner-text-small {
          font-size: 20px;
        }

        .banner-text-large {
          font-size: 34px;
        }
      }
    }
    .blog-template-content-container {

      .blog-template-content {
        .blog-item-container {
          .blog-item-info-container {
            flex-grow: 1;

            h1 {
              font: 28px PoppinsSemiBold, serif, sans-serif;
            }
            p.main-text {
              width: 100%;
              font: 19px Poppins, serif, sans-serif;
            }
            h2 {
              color: #b70808;
              font: 26px PoppinsSemiBold, serif, sans-serif;
              margin-bottom: 5px;
            }
            p {
              width: 100%;
              font: 16px Poppins, serif, sans-serif;
            }
            li {
              width: 100%;
              font: 14px Poppins, serif, sans-serif;
            }


          }

          .blog-item-info-container-full {
            flex-grow: 1;

            h1 {
              font: 28px PoppinsSemiBold, serif, sans-serif;
            }
            p.main-text {
              width: 100%;
              font: 19px Poppins, serif, sans-serif;
            }
            h2 {
              color: #b70808;
              font: 26px PoppinsSemiBold, serif, sans-serif;
              margin-bottom: 5px;
            }
            p {
              width: 100%;
              font: 16px Poppins, serif, sans-serif;
            }
            li {
              width: 100%;
              font: 14px Poppins, serif, sans-serif;
            }


          }
        }
      }
    }
  }
}


