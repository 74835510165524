.mobileAppsContainer {
    margin-top: 50px;
    width: 100%;
}

.appTitle {
    text-align: center;
    font-family: Bebas, serif;
    font-size: 24px;
    margin-top: 20px;
    word-spacing: 3px;
}

.mobileAppsContainer a {
    color: black;
}

.mobileAppsContainer a:hover {
    color: rgb(182, 8, 7);
}

.apps-password-input {
    width: 300px;
    height: 35px;
    margin-left: calc(50% - 150px);
    display: block;
}

.apps-submit-button {
    width: 120px;
    height: 35px;
    margin-top: 20px;
    margin-left: calc(50% - 60px);
    color: white;
    background-color: rgb(182,8, 7);
    border-radius: 5px;
}


@media screen and (max-width: 600px) {
    .mobileAppsContainer {
        margin-top: 300px;
    }
}

