@font-face {
  font-family: 'LatoBold';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: 'ReneBiederExtraBold';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Rene Bieder  Milliard ExtraBold.otf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  x-height: 1.3;
  font-weight: 100;
  src: url('../resources/fonts/Lato-Regular.ttf');
}



@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/bebas.ttf');
}

@font-face {
  font-family: 'AgencyFB';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/AgencyFB.ttf');
}

@font-face {
  font-family: 'FranklinGothicHeavy';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/FranklinGothicHeavy.ttf');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Oswald.ttf');
}

@font-face {
  font-family: 'OswaldBold';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/OswaldBold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Roboto.ttf');
}

@font-face {
  font-family: 'RobotoMedium';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/RobotoMedium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Poppins-Regular.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsMedium';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Poppins-Medium.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsBlack';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Poppins-Black.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsSemiBold';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Poppins-SemiBold.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsBold';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Poppins-Bold.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'PoppinsLight';
  font-style: normal;
  font-weight: 100;
  src: url('../resources/fonts/Poppins-Light.otf'); /* IE9 Compat Modes */
}

.nameEnding {
  color: rgb(182, 8, 7);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pageHeader {
  font-family: Bebas, serif;
  font-size: 30px;
  color: rgb(182, 8, 7);
  text-align: center;
  word-spacing: 5px;
}

a:hover  {
  text-decoration: none !important;
}
