.privacyPolicyContainer {
    margin-top: 50px;
}

.privacyPolicy {
    width: 80%;
    margin: 20px auto;
    overflow-y: scroll;
}

.privacyPolicyEffective {
    font-style: italic;
}

.privacyPolicyHeader {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.privacyPolicyHeaderSmall {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.privacyPolicyText {
    font-size: 16px;
}