$redLogoColor: #B70808;

.free-quote-container {
  width: 100%;
  height: 130px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}



.free-quote-button {
  background-color: #b70808;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;
  font: 20px PoppinsSemiBold,serif,sans-serif;
  height: auto;
  min-height: 52px;
  max-width: 90vw;
  width: 350px;


  &:hover {
    background-color: #B70808;
    color: white;
    border: 2px solid #302F2F;
    cursor: pointer;

  }
}

.free-quote-text {
  color: #B70808;
  font: 28px PoppinsSemiBold, serif, sans-serif;
  margin-bottom: 0;
  margin-right: 20px
}

/******************* TABLETS & SMALLER **************************/
@media all and (max-width: 849px) {


  .free-quote-container {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .free-quote-button {
    background-color: rgb(183, 8, 8);
    color: white;
    text-transform: uppercase;
    border-radius: 12px;
    font: 20px PoppinsSemiBold, serif, sans-serif;
    height: auto;
    min-height: 52px;
    max-width: 90vw;
    width: 350px;
    margin-bottom: 20px;

    &:hover {
      color: rgb(183, 8, 8);
      background-color: white;
      border: 3px solid rgb(183, 8, 8);
      cursor: pointer;
    }
  }

  .free-quote-text {
    color: #B70808;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    margin: 20px auto 10px auto;
  }

}

/**
RED BACKGROUND
 */

.white-padding {
  width: 100%;
  height: 50px;
}

.free-quote-container-inverse {
  width: 100%;
  height: 150px;
  background-color: $redLogoColor;
  display: flex;
  align-items: center;
  justify-content: center;
}



.free-quote-button-inverse {
  background-color: white;
  color: $redLogoColor;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;
  font: 20px PoppinsSemiBold,serif,sans-serif;
  height: auto;
  min-height: 52px;
  max-width: 90vw;
  width: 350px;


  &:hover {
    background-color: #B70808;
    color: white;
    border: 2px solid #302F2F;
    cursor: pointer;

  }
}

.free-quote-text-inverse {
  color: white;
  font: 28px PoppinsSemiBold, serif, sans-serif;
  margin-bottom: 0;
  margin-right: 20px
}

/******************* TABLETS & SMALLER **************************/
@media all and (max-width: 849px) {


  .free-quote-container {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .free-quote-button {
    background-color: rgb(183, 8, 8);
    color: white;
    text-transform: uppercase;
    border-radius: 12px;
    font: 20px PoppinsSemiBold, serif, sans-serif;
    height: auto;
    min-height: 52px;
    max-width: 90vw;
    width: 350px;
    margin-bottom: 20px;

    &:hover {
      color: rgb(183, 8, 8);
      background-color: white;
      border: 3px solid rgb(183, 8, 8);
      cursor: pointer;
    }
  }

  .free-quote-text {
    color: #B70808;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    margin: 20px auto 10px auto;
  }

  .free-quote-container-inverse {
    height: auto;
    background-color: $redLogoColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .free-quote-button-inverse {
    background-color: white;
    color: $redLogoColor;
    text-transform: uppercase;
    border-radius: 12px;
    font: 20px PoppinsSemiBold, serif, sans-serif;
    height: auto;
    min-height: 52px;
    max-width: 90vw;
    width: 350px;
    margin-bottom: 20px;

    &:hover {
      color: rgb(183, 8, 8);
      background-color: white;
      border: 3px solid rgb(183, 8, 8);
      cursor: pointer;
    }
  }

  .free-quote-text-inverse {
    color: white;
    font: 28px PoppinsSemiBold, serif, sans-serif;
    margin: 20px auto 10px auto;
  }

}