.home-process {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .process-arrow-container {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    img {
      width: 50%;
      margin: 0 auto;
      height: auto;
    }
  }

  .process-image-container {
    height: 70%;
    display: flex;
    align-items: center;
    margin-right: 200px;

    img {
      height: 90%;
    }
  }

  .process-info-container {
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .process-header {
    text-transform: uppercase;
    font: 22px PoppinsBold, serif, sans-serif;
  }

  .process-step-name {
    color: #B70808;
    font: 42px PoppinsSemiBold, sans-serif, serif;
  }

  .process-description {
    font: 20px Poppins, sans-serif, serif;
    color: #302F2F;
  }

  .process-slides-container {
    margin-top: auto;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
      width: 40px;
      height: auto;

      &:hover {
        cursor: pointer;
      }

    }

  }
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {
  .home-process {

    .process-arrow-container {
      width: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.left {
        float: left;
      }

      &.right {
        float: right;
      }

      img {
        width: 80%;
        margin: 0 auto;
        height: auto;
      }
    }

    .process-image-container {
      margin-right: 100px;
      img {
        height: 75%;
      }
    }
  }
}

/************************ TABLETS ***********************/
@media all and (max-width: 849px)  {


  .home-process {
    display: block;
    height: inherit !important;

    .process-arrow-container {
      display: none;
    }

    .process-image-container {

      width: 80%;
      padding-top: 20px;
      margin: 0 auto;
      img {
        /* width: auto; */
        margin: 0 auto 10px;
        height: auto;
        max-width: 250px;
      }
    }

    .process-info-container {
      width: 90%;
      height: inherit;
      margin: 0 auto;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .process-header {
      margin-bottom: 5px;
    }

    .process-step-name {
      color: #B70808;
      margin-bottom: 5px;
      font: 36px PoppinsSemiBold, sans-serif, serif;
    }

    .process-description {
      text-align: center;
      font: 18px Poppins, sans-serif, serif;
      color: #302F2F;
    }

    .process-slides-container {
      height: 40px;
      width: 300px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        width: 40px;
        height: auto;

        &:hover {
          cursor: pointer;
        }

      }

    }
  }


}

