.work-banner-container {
  width: 100%;
  margin-bottom: 20px;
  //height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .who-we-are {
    //height: 500px;
    width: 50%;
    display: inline-block;
    //overflow: hidden;
    position: relative;

    .banner-display-image {
      min-width: 300px;
      width: 100%;
      height: auto;
    }

    //.banner-image {
    //  width: 100%;
    //  min-height: 500px;
    //  position: absolute;
    //}

    .work-info-container {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 80%;

      .info-title {
        font: 36px PoppinsSemiBold, serif, sans-serif;
        color: #b70808;
      }
      //position: absolute;
      //left: 20%;
      //top: 10%;
      margin: 50px auto;
      display: flex;
      flex-direction: column;

      //.small-header {
      //  color: white;
      //  font: 22px PoppinsBold, sans-serif, serif;
      //}
      .small-header {
        font: 19px PoppinsSemiBold, serif, sans-serif;
        color: white;
        //color: #b70808;
        margin-top: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .large-header {
        color: white;
        font: 36px PoppinsSemiBold, sans-serif, serif;
      }

      .work-banner-description {
        color: white;
        font: 20px Poppins, sans-serif, serif;
        margin-bottom: 25px;
      }
    }

  }

  .how-we-do-it {
    width: 50%;
    //height: 500px;
    display: inline-block;
    overflow: hidden;
    position: relative;

    .banner-display-image {
      min-width: 300px;
      width: 100%;
      height: auto;
    }


    //.banner-image {
    //  width: 100%;
    //  height: 100%;
    //  position: absolute;
    //}

    .work-info-container {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 80%;
      margin: 50px auto;
      display: flex;
      flex-direction: column;


      .info-title {
        font: 36px PoppinsSemiBold, serif, sans-serif;
        color: white;
      }

      .small-header {
        font: 19px PoppinsSemiBold, serif, sans-serif;
        color: white;
        margin-top: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .large-header {
        color: white;
        font: 36px PoppinsSemiBold, sans-serif, serif;
      }

      //.small-header {
      //  color: white;
      //  font: 22px PoppinsBold, sans-serif, serif;
      //}
      //
      //.large-header {
      //  color: white;
      //  font: 36px PoppinsSemiBold, sans-serif, serif;
      //}

      .work-banner-description {
        color: white;
        font: 20px Poppins, sans-serif, serif;
        margin-bottom: 25px;
      }
    }
  }
}

/****************** SMALL LAPTOPS ****************/
@media all and (max-width: 1500px) and (min-width: 850px) {

  .arrow-button-container {
    width: 100%
  }

  .work-banner-container {

    .who-we-are {

      //.banner-image {
      //  width: 100%;
      //  height: 100%;
      //  position: absolute;
      //}

      //.work-info-container {
      //  position: absolute;
      //  left: 5%;
      //  top: 15%;
      //  width: 90%;
      //
      //  .small-header {
      //    color: white;
      //    font: 22px PoppinsBold, sans-serif, serif;
      //  }
      //
      //  .large-header {
      //    color: white;
      //    font: 30px PoppinsSemiBold, sans-serif, serif;
      //  }
      //
      //  .work-banner-description {
      //    color: white;
      //    font: 20px Poppins, sans-serif, serif;
      //  }
      //}

    }

    .how-we-do-it {
    //  width: 50%;
    //  display: inline-block;
    //  overflow: hidden;
    //  height: 100%;
    //  position: relative;
    //
    //  .banner-image {
    //    width: 100%;
    //    height: 100%;
    //    position: absolute;
    //  }
    //
    //  .work-info-container {
    //    position: absolute;
    //    left: 5%;
    //    top: 15%;
    //    width: 90%;
    //
    //    .small-header {
    //      color: white;
    //      font: 22px PoppinsBold, sans-serif, serif;
    //    }
    //
    //    .large-header {
    //      color: white;
    //      font: 30px PoppinsSemiBold, sans-serif, serif;
    //    }
    //
    //    .work-banner-description {
    //      color: white;
    //      font: 20px Poppins, sans-serif, serif;
    //    }
    //  }
    }
  }

}

/************************ TABLETS ***********************/
@media all and (max-width: 849px) {

  .work-banner-container {
    width: 100%;
    flex-direction: column;
    .who-we-are {

      width: 100%;
      display: block;

      .work-info-container {
        left: 5%;
        width: 90%;

        .small-header {
          //color: #b70808;
          margin: 10px 0;
          font: 28px PoppinsBold, sans-serif, serif;
        }

        .large-header {
          //color: #302F2F;
          font: 22px PoppinsSemiBold, sans-serif, serif;
        }

        .work-banner-description {
          color: white;
          font: 20px Poppins, sans-serif, serif;
          margin-bottom: 50px;
        }
      }

    }

    .how-we-do-it {
      width: 100%;
      display: block;

      .work-info-container {
        left: 5%;
        width: 90%;


        .small-header {
          //color: #b70808;
          margin: 10px 0;
          font: 28px PoppinsBold, sans-serif, serif;
        }

        .large-header {
          //color: #302F2F;
          font: 22px PoppinsSemiBold, sans-serif, serif;
        }

        .work-banner-description {
          color: white;
          font: 20px Poppins, sans-serif, serif;
          margin-bottom: 50px;
        }
      }
    }
  }

}

@media all and (max-width: 500px) {

  .work-banner-container {
    width: 100%;
    flex-direction: column;

    .who-we-are {

      width: 100%;
      display: block;

      .work-info-container {
        left: 5%;
        width: 90%;

        .small-header {
          //color: #b70808;
          font: 28px PoppinsBold, sans-serif, serif;
        }

        .large-header {
          //color: #302F2F;
          font: 22px PoppinsSemiBold, sans-serif, serif;
        }

        .work-banner-description {
          color: white;
          font: 18px Poppins, sans-serif, serif;
          margin-bottom: 50px;
        }
      }

    }

    .how-we-do-it {
      width: 100%;
      display: block;

      .work-info-container {
        left: 5%;
        width: 90%;

        .small-header {
          //color: #b70808;
          font: 28px PoppinsBold, sans-serif, serif;
        }

        .large-header {
          //color: #302F2F;
          font: 22px PoppinsSemiBold, sans-serif, serif;
        }

        .work-banner-description {
          color: white;
          font: 18px Poppins, sans-serif, serif;
          margin-bottom: 50px;
        }
      }
    }
  }

  .arrow-button-container {
    min-width: 275px;
  }


}