$redLogoColor: rgb(183, 8, 8);
@keyframes modalFadeIn {
  from {transform: translateY(-50%);opacity: 0;}
  to {transform: translateY(0);opacity: 1;}
}

.roofDocsRed {
  color: rgb(183, 8, 8);
}

.animatedModal {
  animation-name: modalFadeIn;
  animation-duration: .5s;
}

.virus-disclaimer {
  width: 80%;
  margin: 50px auto 0 auto;
  padding-left: 15px;
  border-left: 4px solid rgb(183, 8, 8);
  font: 28px PoppinsSemiBold, serif, sans-serif;


}


.inspection-banner {
  background: transparent url('../../resources/images/misc/background_banner_red.png') center center repeat padding-box;
  position: relative;
  text-align: center;
  color: white;
  height: 290px;

  img {
    width: 100%;
    height: 100%;
  }
  .banner-icon {
    width: 100px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
  }
}

.inspection-report-container {
  width: 80%;
  margin: 100px auto;
  display: flex;
  //align-items: center;
  justify-content: space-evenly;

  .header-text {
    font: 54px PoppinsSemiBold, serif, sans-serif;
    color: $redLogoColor;
    margin-bottom: 0;
  }

  .header-info-text {
    font: 20px Poppins, serif, sans-serif;
    margin: 20px auto;
    color: #414040;
  }

  .inspection-container {
    width: 40%;
    float: left;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .report-container {
    width: 40%;
    float: right;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .schedule-inspection-container {
    width: 60%;
    min-width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

}

.confirm-close-button {
  background-color: red;
  color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  font: 20px Poppins, serif;
  width: 150px;
  margin-left: 75px;
  height: 50px;
  word-spacing: 5px;
  border-radius: 12px;
}

.confirm-accept-button {
  background-color: rgb(183, 8, 8);
  color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  font: 20px Poppins, serif;
  width: 150px;
  margin-right: 75px;
  height: 50px;
  word-spacing: 5px;
  border-radius: 12px;
}


.inspection-banner-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);

  .banner-text-small {
    text-transform: uppercase;
    color: #fff;
    font: 24px PoppinsBold, serif, sans-serif;
    text-align: center;
    margin-bottom: 0;
     text-shadow: 0 0 2px #000000;
  }
  .banner-text-large {
    color: #fff;
    font: 56px PoppinsSemiBold, serif, sans-serif;
    text-align: center;
    text-shadow: 0 0 2px #000000;
  }
}

.report-submit-button {
  margin-top: auto;
  margin-left: calc(100% - 440px);
  width: 440px;
  height: 65px;
  background-color: $redLogoColor;
  box-shadow: 0 0 10px #00000076;
  border-radius: 9px;
  border: 2px solid rgba(183, 8, 8, 0);

  &:hover {
    cursor: pointer;
    border: 2px solid white;
  }

  .report-submit-label {
    text-transform: uppercase;
    color: white;
    font: 22px PoppinsSemiBold, Serif, sans-serif;
    display: inline-block;
    line-height: 63px;
    margin-left: 15px;
  }

  .report-submit-img {
    float: right;
    margin-right: 2px;
    margin-top: 1px;
    display: inline-block;
  }
}

/**** Process ****/

.inspection-work-item-container {
  width: 100%;
  padding-top: 10px;
  display: flex;
}

.inspection-work-item-icon-container {
  width: 100px;

  img {
    width: 90px;
    height: auto;
  }
}

.inspection-work-item-info-container {
  flex-grow: 1;

  .inspection-step-header {
    width: 100%;
    font: 36px PoppinsSemiBold, serif, sans-serif;
    color: #b70808;
    border-bottom: 1px solid red;
    padding-left: 10px;
  }

  .inspection-step-info {
    width: 100%;
    font: 20px Poppins, serif, sans-serif;
    padding-left: 10px;
  }
}

/****

 SMALL LAPTOPS

 */

@media all and (max-width: 1500px) and (min-width: 850px) {

  .inspection-report-container {
    width: 95%;
    margin: 50px auto;

    .header-text {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }

    .header-info-text {
      font: 17px Poppins, serif, sans-serif;
      margin: 10px auto 20px auto;
    }

    .report-submit-button {
      margin-top: auto;
      margin-left: calc(100% - 340px);
      width: 340px;
      height: 65px;
      background-color: $redLogoColor;
      box-shadow: 0 0 10px #00000076;
      border-radius: 9px;
      border: 2px solid rgba(183, 8, 8, 0);


      .report-submit-label {
        text-transform: uppercase;
        color: white;
        font: 16px PoppinsSemiBold, Serif, sans-serif;
        display: inline-block;
        line-height: 63px;
        margin-left: 15px;
      }

      .report-submit-img {
        float: right;
        margin-right: 2px;
        margin-top: 1px;
        display: inline-block;
      }
    }
  }
  .inspection-banner-text-container {
    width: 100%;

    .banner-text-large {
      font: 42px PoppinsSemiBold, serif, sans-serif;
    }
  }
}

@media all and (max-width: 849px) {

  .virus-disclaimer {
    font: 18px PoppinsSemiBold, serif, sans-serif;
  }

  .inspection-report-container {
    width: 80%;
    height: auto;
    margin: 50px auto;
    display: block;

    .schedule-inspection-container {
      min-width: 350px;
    }

    .header-text {
      font: 42px PoppinsSemiBold, serif, sans-serif;
      color: $redLogoColor;
      margin-bottom: 0;
    }

    .header-info-text {
      font: 20px Poppins, serif, sans-serif;
      margin: 10px auto;
      color: #414040;
    }

    .inspection-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .report-container {
      //display: none;
      //clear: both;
      float: inherit;
      padding-top: 50px;
      width: 100%;
      //height: 100%;
      //display: flex;
      //flex-direction: column;
    }

  }
  .inspection-banner-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    .banner-text-small {
      text-transform: uppercase;
      color: #fff;
      font: 24px PoppinsBold, serif, sans-serif;
      text-align: center;
      margin-bottom: 0;
      text-shadow: 0 0 2px #000000;
    }
    .banner-text-large {
      color: #fff;
      font: 42px PoppinsSemiBold, serif, sans-serif;
      text-align: center;
      text-shadow: 0 0 2px #000000;
    }
  }
}

@media all  and (max-width: 550px) {

  .inspection-report-container {
    width: 95%;
  }

  .report-submit-button {
    margin-top: auto;
    margin-left: calc(50%  - 180px);
    width: 360px;
    height: 55px;
    background-color: $redLogoColor;
    box-shadow: 0 0 10px #00000076;
    border-radius: 9px;
    border: 2px solid rgba(183, 8, 8, 0);

    .report-submit-label {
      text-transform: uppercase;
      color: white;
      font-size: 18px;
      display: inline-block;
      line-height: 53px;
      margin-left: 15px;
    }

    .report-submit-img {
      height: 49px;
      width: 49px;
      float: right;
      margin-right: 2px;
      margin-top: 1px;
      display: inline-block;
    }
  }

}