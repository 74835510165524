.program-input-container {

  border-bottom: 2px solid rgb(219, 219, 219);
  height: 50px;


  label {
    text-indent: 5px;
    font: 18px LatoBold, serif, sans-serif;
    text-transform: uppercase;
    margin-bottom: 0;
    height: 48px;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 16px) scale(1);
    transition: all .3s ease-in-out;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    height: 48px;
    width: 100%;
    border: none;


    &:focus {
      border: none;
      outline: none;
    }
  }

  &.firstName {
    width: 20%
  }
  &.lastName {
    width: 20%
  }
  &.address {
    width: 50%;
    z-index: 999;
    //.places-search-container {
    //  z-index: 9999;
    //}
    //
    //.autocomplete-dropdown-container {
    //  z-index: 9999;
    //}
  }
  &.email {
    width: 30%
  }
  &.phone {
    width: 30%
  }



}

@media all and (max-width: 750px) {

  .program-input-container {

    &.firstName {
      width: 100%
    }
    &.lastName {
      width: 100%
    }
    &.address {
      width: 100%;
    }
    &.email {
      width: 100%
    }
    &.phone {
      width: 100%
    }

  }

}